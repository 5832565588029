import PropTypes from 'prop-types';

import { SprintBadge } from 'features/sprint/components/SprintBadge';

export const SprintSourceTypeBadge = ({ label, dataTestid = null }) => {
  return <SprintBadge dataTestid={dataTestid}>{label}</SprintBadge>;
};

SprintSourceTypeBadge.propTypes = {
  label: PropTypes.string.isRequired,
  dataTestid: PropTypes.string,
};
