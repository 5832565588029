import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { useEditGoalFocusArea } from 'features/goal';

import { Typography } from 'shared/components/Typography';

type TGoalFocusAreaMenuEditButton = {
  focusAreaId: string;
  title: string;
};

export const GoalFocusAreaMenuEditButton = ({
  focusAreaId,
  title,
}: TGoalFocusAreaMenuEditButton) => {
  const { onEditFocusAreaClick } = useEditGoalFocusArea({
    focusAreaId,
    title,
  });

  return (
    <OptionsMenuButton onClick={onEditFocusAreaClick}>
      <Typography as="span">Edit focus area</Typography>
    </OptionsMenuButton>
  );
};
