import { Fragment } from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { colors as TypographyColors } from 'shared_DEPRECATED/components/Typography/Typography';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { DAILY_STATUS } from 'features/challenge/config';
import { MomentumFeedItem } from 'features/momentum/components/Feed/Item';
import { MomentumFeedItemMaximum } from 'features/momentum/components/Feed/ItemMaximum';
import { MomentumFeedItemMinimumGroup } from 'features/momentum/components/Feed/ItemMinimumGroup';
import { MomentumScore } from 'features/momentum/components/Score';
import { useMomentumFeedQuery } from 'features/momentum/hooks/query/useMomentumFeed';
import { useMomentumFeedSprintScores } from 'features/momentum/hooks/useFeedSprintScores';
import { getMomentumConfig } from 'features/momentum/utils';

import { SidebarSection } from 'shared/components/Sidebar';
import { Typography, TYPOGRAPHY_COLORS } from 'shared/components/Typography';

export const MomentumFeed = () => {
  const sprintMomentumData = useMomentumFeedSprintScores();
  const { data: momentumFeed, isLoading } = useMomentumFeedQuery();

  if (!momentumFeed || isLoading) {
    return <Loader />;
  }

  return sprintMomentumData
    .filter(
      ({ date }) => momentumFeed[date] && momentumFeed[date].items.length > 0
    )
    .map(({ date, score }) => {
      const momentumScoreDiff = momentumFeed[date].momentumScoreDiff;
      const config = getMomentumConfig(momentumScoreDiff);
      const haveZeroMomentumItemsChanged =
        momentumFeed[date].zeroMomentumItems.accumulatedChangeValue !== 0;
      const isZeroMomentumFeedItemShown =
        haveZeroMomentumItemsChanged &&
        momentumFeed[date].zeroMomentumItems.challenges.length === 1;

      return (
        <Fragment key={date}>
          <Spacer size="lg">
            <Flex flexDirection="column">
              <MomentumScore score={score} size="sm" />
              <Typography type="small">
                <Typography as="span" color="gray" fontWeight="regular">
                  {dateUtils.relativeDateFormat(date)}
                  {momentumScoreDiff !== 0 && ', '}
                </Typography>
                <Typography
                  as="span"
                  color={config.textColor as TYPOGRAPHY_COLORS}
                >
                  {momentumScoreDiff !== 0
                    ? `${config.sign}${Math.abs(momentumScoreDiff).toFixed(1)}`
                    : ''}
                </Typography>
              </Typography>
            </Flex>
          </Spacer>
          {isZeroMomentumFeedItemShown &&
            momentumFeed[date].zeroMomentumItems.challenges.map(
              (
                {
                  status,
                  title,
                  frequency,
                  targetAmount,
                }: {
                  status: DAILY_STATUS;
                  title: string;
                  frequency: string;
                  targetAmount?: string;
                },
                index
              ) => (
                <SidebarSection key={`${title}${index}`}>
                  <MomentumFeedItem
                    challengeFrequency={frequency}
                    challengeStatus={status}
                    challengeTargetAmount={targetAmount}
                    challengeTitle={title}
                    textColor="red"
                    trendSign="-"
                    trend={Number(
                      momentumFeed[
                        date
                      ].zeroMomentumItems.accumulatedChangeValue.toFixed(1)
                    )}
                  />
                </SidebarSection>
              )
            )}
          {haveZeroMomentumItemsChanged &&
            momentumFeed[date].zeroMomentumItems.challenges.length > 1 && (
              <SidebarSection>
                <MomentumFeedItemMinimumGroup
                  challenges={momentumFeed[date].zeroMomentumItems.challenges}
                  trend={Number(
                    momentumFeed[
                      date
                    ].zeroMomentumItems.accumulatedChangeValue.toFixed(1)
                  )}
                  trendSign="-"
                />
              </SidebarSection>
            )}
          {momentumFeed[date].items.map(
            (
              {
                challengeStatus,
                challengeTitle,
                changeValue,
                challengeFrequency,
                challengeTargetAmount,
                currentValue,
              }: {
                challengeStatus: DAILY_STATUS;
                challengeTitle: string;
                changeValue: number;
                challengeFrequency: string;
                challengeTargetAmount?: string;
                currentValue: number;
              },
              index
            ) => {
              const config = getMomentumConfig(changeValue);

              if (currentValue === 100) {
                return (
                  <SidebarSection key={`${challengeTitle}${index}`}>
                    <MomentumFeedItemMaximum
                      challengeFrequency={challengeFrequency}
                      challengeStatus={challengeStatus}
                      challengeTargetAmount={challengeTargetAmount}
                      challengeTitle={challengeTitle}
                    />
                  </SidebarSection>
                );
              }

              return (
                <SidebarSection key={`${challengeTitle}${index}`}>
                  <MomentumFeedItem
                    challengeFrequency={challengeFrequency}
                    challengeStatus={challengeStatus}
                    challengeTargetAmount={challengeTargetAmount}
                    challengeTitle={challengeTitle}
                    textColor={
                      config.textColor as keyof typeof TypographyColors
                    }
                    trend={Number(changeValue.toFixed(1))}
                    trendSign={config.sign}
                  />
                </SidebarSection>
              );
            }
          )}
        </Fragment>
      );
    });
};
