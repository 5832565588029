import { useMemo } from 'react';

import { PARTICIPANT_NOTES_COUNT_FIELDS_BY_TYPE } from 'features/challengeNotes/config';
import { useParticipantQuery } from 'features/participants/hooks';
import { SPRINT_STATES } from 'features/sprint/config';

export const useUnreadNotes = ({ participantId, sprintState }) => {
  const { participant } = useParticipantQuery(participantId);

  const unreadNotesCounterByType = useMemo(
    () =>
      sprintState !== SPRINT_STATES.ACTIVE
        ? {}
        : Object.entries(PARTICIPANT_NOTES_COUNT_FIELDS_BY_TYPE).reduce(
            (acc, [noteType, noteCountField]) => ({
              ...acc,
              [noteType]: participant?.[noteCountField] || 0,
            }),
            {}
          ),
    [participant, sprintState]
  );

  const unreadNotesCounter = Object.values(unreadNotesCounterByType).reduce(
    (acc, noteCount) => acc + noteCount,
    0
  );

  return {
    unreadNotesCounter,
    unreadNotesCounterByType,
  };
};
