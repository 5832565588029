import { useFormContext } from 'react-hook-form';
import { For } from 'react-loops';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import {
  SelectButton,
  SELECT_BUTTON_TYPES,
  SELECT_BUTTON_SIZES,
} from 'shared_DEPRECATED/components/Form/SelectButton';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';
import { firstLetterUpperCase } from 'shared_DEPRECATED/utils';

import { TARGET_PERIODS } from 'features/challenge/config';

import { Typography } from 'shared/components/Typography';

export const ChallengeTargetAmountDialogPeriodField = () => {
  const { setValue, watch } = useFormContext();
  const period = watch('period');

  return (
    <>
      <Spacers sizes={['lg zr', 'sm zr']}>
        <Typography type="small">per</Typography>
      </Spacers>
      <Spacer size="zr xsm">
        <Flex gap="1rem" flexWrap="wrap" justifyContent="flex-start">
          <For
            in={TARGET_PERIODS}
            as={(value, { key }) => {
              const lowerCaseKey = key.toLowerCase();

              return (
                <SelectButton
                  checked={period === lowerCaseKey}
                  value={lowerCaseKey}
                  onChange={() => setValue('period', lowerCaseKey)}
                  type={SELECT_BUTTON_TYPES.SINGLE}
                  size={SELECT_BUTTON_SIZES.SM}
                >
                  {firstLetterUpperCase(value)}
                </SelectButton>
              );
            }}
          />
        </Flex>
      </Spacer>
    </>
  );
};

export default ChallengeTargetAmountDialogPeriodField;
