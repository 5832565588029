import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Radio } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils, range } from 'shared_DEPRECATED/utils';

import { ChallengeFormReminderSelector } from 'features/challenge/components/Form/fields/ReminderField/';

import { Typography } from 'shared/components/Typography';

const convertTimeTo12HourFormat = (time24: string) => {
  const [hours, minutes, seconds] = time24.split(':');

  return dateUtils()
    .set('hour', parseInt(hours))
    .set('minute', parseInt(minutes))
    .set('second', parseInt(seconds))
    .format('hh:mm:A');
};

const convertTimeTo24HourFormat = (time12: string) => {
  const [hours, minutes, meridian] = time12.split(':');

  let hour = parseInt(hours, 10);

  if (meridian === 'PM') {
    hour = hour !== 12 ? hour + 12 : 12;
  } else {
    hour = hour === 12 ? 0 : hour;
  }

  return dateUtils()
    .set('hour', hour)
    .set('minute', parseInt(minutes, 10))
    .format('HH:mm:00');
};

const hoursSelectOptions = range(0, 12).map((value) =>
  value < 10 ? `0${value}` : `${value}`
);

const minutesSelectOptions = range(0, 59).map((value) =>
  value < 10 ? `0${value}` : `${value}`
);

type TChallengeReminderFieldPicker = {
  initialReminder: string;
};

export const ChallengeReminderFieldPicker = ({
  initialReminder,
}: TChallengeReminderFieldPicker) => {
  const { setValue } = useFormContext();

  const [hour, minutes, meridian] =
    convertTimeTo12HourFormat(initialReminder).split(':');

  const updateTime = ({
    updatedHour = hour,
    updatedMinutes = minutes,
    updatedMeridian = meridian,
  }: {
    updatedHour?: string;
    updatedMinutes?: string;
    updatedMeridian?: string;
  }) =>
    setValue(
      'reminder',
      convertTimeTo24HourFormat(
        `${updatedHour}:${updatedMinutes}:${updatedMeridian}`
      )
    );

  return (
    <Flex width="5rem" dataTestid="challenge-reminder">
      <ChallengeFormReminderSelector
        handleSelect={(updatedHour) => updateTime({ updatedHour })}
        selectedValue={hour}
        selectOptions={hoursSelectOptions}
      />
      <Spacer size="zr md">
        <Typography type="meta" color="gray">
          :
        </Typography>
      </Spacer>
      <ChallengeFormReminderSelector
        handleSelect={(updatedMinutes) => updateTime({ updatedMinutes })}
        selectedValue={minutes}
        selectOptions={minutesSelectOptions}
      />
      <Spacer size="zr md" />

      {['AM', 'PM'].map((updatedMeridian) => (
        <React.Fragment key={updatedMeridian}>
          <Radio
            isChecked={meridian === updatedMeridian}
            onChange={() => updateTime({ updatedMeridian })}
          >
            {updatedMeridian}
          </Radio>
          <Spacer size="zr md" />
        </React.Fragment>
      ))}
    </Flex>
  );
};
