import { Button, ButtonIcon, VARIANTS } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { ChallengeTargetAmountAutomationTooltip } from 'features/challenge/components/TargetAmount/AutomationTooltip/AutomationTooltip';
import {
  ChallengeTargetAmountTypeNullable,
  TargetPeriodNullable,
} from 'features/challenge/config/types';
import { getChallengeTargetText } from 'features/challenge/utils';

type ChallengeTargetFieldProps = {
  challengeTargetAmount: ChallengeTargetAmountTypeNullable;
  challengeTargetPeriod: TargetPeriodNullable;
  onFieldClick: () => void;
  onDeleteButtonClick: () => void;
};

export const ChallengeTargetField = ({
  challengeTargetAmount,
  challengeTargetPeriod,
  onFieldClick,
  onDeleteButtonClick,
}: ChallengeTargetFieldProps) => (
  <Flex>
    {challengeTargetAmount?.automation && (
      <ChallengeTargetAmountAutomationTooltip
        anchor={<Icon name={iconNames.heart} />}
      />
    )}
    <Button
      onClick={onFieldClick}
      variant={VARIANTS.TERTIARY}
      dataTestid="challenge-dialog-target-field"
      size="sm"
    >
      {getChallengeTargetText(challengeTargetAmount, challengeTargetPeriod)}
    </Button>
    {(challengeTargetAmount?.value || challengeTargetAmount?.unit) && (
      <ButtonIcon
        ariaLabel="cross"
        icon={<Icon name={iconNames.cross} width={10} height={10} />}
        onClick={onDeleteButtonClick}
        dataTestid="challenge-dialog-target-field-delete-button"
        variant={VARIANTS.TERTIARY}
        size="sm"
      />
    )}
  </Flex>
);

export default ChallengeTargetField;
