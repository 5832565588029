import { IconButton, HStack } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { For } from 'react-loops';
import ReactPlayer from 'react-player';

import { Box, Flex, Image, VARIANTS } from 'shared';
import { FileDownloadButton } from 'shared_DEPRECATED/components/File';
import { isImage } from 'shared_DEPRECATED/components/File/utils';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Video } from 'shared_DEPRECATED/components/Video/Video';
import { MediaType } from 'types/common';

import { ModalBody, ModalHeader } from 'shared/components/Modal';
import { Typography } from 'shared/components/Typography';

type TMediaGallery = {
  media: MediaType[];
  selectedMediaIndex?: number;
};

export const MediaGallery = ({
  media,
  selectedMediaIndex = 0,
}: TMediaGallery) => {
  const [currentMediaIndex, setCurrentMediaIndex] =
    useState(selectedMediaIndex);
  const videoRef = useRef<ReactPlayer>(null);

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleShowNextMedia = () => {
    if (currentMediaIndex < media.length - 1) {
      setCurrentMediaIndex((prev) => prev + 1);
    }
  };

  const handleShowPrevMedia = () => {
    if (currentMediaIndex > 0) {
      setCurrentMediaIndex((prev) => prev - 1);
    }
  };

  const handleSelectMedia = (index: number) => {
    setCurrentMediaIndex(index);
  };

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        handleShowNextMedia();
      } else if (event.key === 'ArrowLeft') {
        handleShowPrevMedia();
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMediaIndex]);

  useEffect(() => {
    if (scrollRef.current) {
      const selectedImage = scrollRef.current.children[
        currentMediaIndex
      ] as HTMLElement;

      if (selectedImage) {
        selectedImage.scrollIntoView({
          behavior: 'smooth',
          inline: 'center', // Centers the selected image horizontally
        });
      }
    }
  }, [currentMediaIndex]);

  return (
    <>
      <ModalHeader>
        <Box width="100%">
          <Flex justifyContent="space-between" alignItems="center" pr="2rem">
            <Typography as="h3" type="body" fontWeight="semiBold" color="black">
              Preview
              {` ${
                isImage(media[currentMediaIndex].contentType)
                  ? 'Image'
                  : 'Video'
              }`}
            </Typography>
            <FileDownloadButton url={media[currentMediaIndex].url} />
          </Flex>
        </Box>
      </ModalHeader>
      <ModalBody>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          height="392px"
        >
          <IconButton
            aria-label="Previous Media"
            icon={<Icon name={iconNames.arrowLeft} width={6} height={9} />}
            onClick={handleShowPrevMedia}
            isDisabled={currentMediaIndex === 0}
            variant={VARIANTS.TERTIARY}
          />
          <Flex
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
          >
            {isImage(media[currentMediaIndex].contentType) ? (
              <Image
                alt={media[currentMediaIndex].url}
                objectFit="cover"
                src={media[currentMediaIndex].url}
                height="100%"
                width="auto"
                borderRadius="var(--border-radius16)"
              />
            ) : (
              <Video
                ref={videoRef}
                playing={true}
                light={media[currentMediaIndex].thumbnail?.url}
                height="100%"
                src={media[currentMediaIndex].url}
              />
            )}
          </Flex>
          <IconButton
            aria-label="Next Media"
            icon={<Icon name={iconNames.arrowRight} width={6} height={9} />}
            onClick={handleShowNextMedia}
            isDisabled={currentMediaIndex === media.length - 1}
            variant={VARIANTS.TERTIARY}
          />
        </Flex>
        <HStack
          justifyContent="flex-start"
          mt="4"
          spacing="2"
          overflowX="auto"
          display="flex"
          wrap="nowrap"
          width="100%"
          ref={scrollRef}
        >
          <For of={media}>
            {({ url, contentType, thumbnail }, { index }) => {
              const isMediaSelected = index === currentMediaIndex;

              return (
                <Box
                  key={url}
                  borderRadius="var(--border-radius12)"
                  overflow="hidden"
                  border={`2px solid ${
                    isMediaSelected ? 'var(--fgInteractive)' : 'transparent'
                  }`}
                  cursor="pointer"
                  onClick={() => handleSelectMedia(index)}
                  _hover={{ borderColor: 'var(--fgInteractive)' }}
                  w="4.75rem"
                  h="4.75rem"
                  maxWidth="4.75rem"
                  display="inline-block"
                >
                  {isImage(contentType) ? (
                    <Image
                      src={url}
                      alt="image"
                      boxSize="80px"
                      objectFit="cover"
                    />
                  ) : (
                    <Video
                      ref={videoRef}
                      src={url}
                      light={thumbnail?.url}
                      height="100%"
                      width="100%"
                      objectFit="cover"
                      controls={false}
                      config={{
                        file: {
                          attributes: {
                            style: {
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            },
                          },
                        },
                      }}
                    />
                  )}
                </Box>
              );
            }}
          </For>
        </HStack>
      </ModalBody>
    </>
  );
};
