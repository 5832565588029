import { useFormContext, useWatch } from 'react-hook-form';

import { ButtonIcon, VARIANTS } from 'shared';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import FieldLabel from 'shared_DEPRECATED/components/Form/FieldLabel';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

export const PassField = () => {
  const { setValue } = useFormContext();
  const passes = useWatch({ name: 'passes' });

  const handlePassesChange = (value) => {
    setValue('passes', value, { shouldDirty: true });
  };

  return (
    <Flex>
      <FlexItem>
        <FieldLabel>Passes</FieldLabel>
        <Typography color="gray" type="small">
          Passes for this challenge are given for the whole Sprint.
        </Typography>
      </FlexItem>
      <Flex>
        <ButtonIcon
          ariaLabel="pass decrease button"
          icon={<Icon name={iconNames.minus} height={1} />}
          disabled={passes === 0}
          onClick={() => handlePassesChange(passes - 1)}
          variant={VARIANTS.TERTIARY}
          size="xs"
        />
        <Spacer size="zr md">
          <Spacer size="zr sm">
            <Typography type="small" color="gray">
              {passes}
            </Typography>
          </Spacer>
        </Spacer>
        <ButtonIcon
          ariaLabel="pass increase button"
          onClick={() => handlePassesChange(passes + 1)}
          icon={<Icon name={iconNames.plus} height={13} width={13} />}
          variant={VARIANTS.TERTIARY}
          size="xs"
        />
      </Flex>
    </Flex>
  );
};
