import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

import styles from 'shared_DEPRECATED/components/Form/ImageUpload/ImageUpload.module.css';

const ImageUpload = ({ onImageUpload }) => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      height="100%"
    >
      <label className={styles.label} htmlFor="image-upload">
        <Spacer size="md zr">
          <Spacer size="sm zr">
            <Typography color="black" fontWeight="medium" type="small">
              <Flex as="span" alignItems="center" justifyContent="center">
                <Icon name={iconNames.gallery} width={13} height={13} />
                <Spacer as="span" size="zr sm zr zr" />
                Upload an image
              </Flex>
            </Typography>
          </Spacer>
        </Spacer>
      </label>
      <input
        accept=".heic, .png, .jpeg, .tiff, .gif"
        id="image-upload"
        onChange={(e) => onImageUpload(e.target.files[0])}
        type="file"
      />
      <Spacer size="lg zr zr">
        <Typography color="gray" fontWeight="regular" type="small">
          Recommended size is 800x800 px
        </Typography>
      </Spacer>
    </Flex>
  );
};

ImageUpload.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
};

export default ImageUpload;
