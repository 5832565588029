import { Button } from 'shared';

import { useCreateGoalChallenge } from 'features/goal';

import { VARIANTS } from 'shared/components/Button';

export const GoalFocusAreaMenuAddChallengeButton = () => {
  const { onCreateGoalChallengeBtnClick } = useCreateGoalChallenge({});

  return (
    <Button
      variant={VARIANTS.TERTIARY}
      onClick={onCreateGoalChallengeBtnClick}
      width="100%"
    >
      Challenge
    </Button>
  );
};
