import { ReactNode } from 'react';

import Button from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

import styles from 'app/layout/components/Header/Menu/Menu.module.css';

type HeaderMenuOptionButtonPropTypes = {
  onClick: () => void;
  children: ReactNode;
  dataTestid?: string;
};

export const HeaderMenuOptionButton = ({
  onClick,
  children,
  dataTestid = '',
}: HeaderMenuOptionButtonPropTypes) => (
  <div className={styles['option-button']}>
    <Button onClick={onClick} dataTestid={dataTestid}>
      <Flex>
        <Spacers sizes={['md', 'sm']}>
          <Spacer size="zr zr zr sm">
            <Typography as="span" textAlign="start" color="gray">
              {children}
            </Typography>
          </Spacer>
        </Spacers>
      </Flex>
    </Button>
  </div>
);
