import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { Nullable } from 'shared_DEPRECATED/types';
import { Picture } from 'shared_DEPRECATED/types/Picture';
import { getPictureUrl } from 'shared_DEPRECATED/utils';

import { ChallengeInfo } from 'features/challenge/components/Info';
import { ChallengeInfoGoals } from 'features/challenge/components/Info/Goals';
import { ChallengeGoalsType } from 'features/challenge/config/types';
import { LifeDimensions } from 'features/lifeDimensions/components/LifeDimensions';

type TGoalChallengeCard = {
  title: string;
  picture: Nullable<Picture>;
  emoji: Nullable<string>;
  dimensions: string[];
  children: ReactNode;
  goals: ChallengeGoalsType;
};

export const GoalChallengeCard = ({
  title,
  picture,
  emoji,
  dimensions,
  children,
  goals,
}: TGoalChallengeCard) => (
  <Box
    style={{
      width: '100%',
      backgroundColor: 'var(--bgCompPrimary)',
      borderRadius: 'var(--border-radius)',
    }}
  >
    <Spacer size="sm">
      <Flex width="100%">
        <Flex>
          <ChallengeInfo
            emoji={emoji}
            pictureUrl={getPictureUrl(picture)}
            title={title}
            isCropped={false}
          >
            <ChallengeInfoGoals goals={goals} />
            <Spacer size="xsm" />
            <LifeDimensions
              visibleDimensionsCount={2}
              dimensions={dimensions}
            />
          </ChallengeInfo>
        </Flex>
        {children}
      </Flex>
    </Spacer>
  </Box>
);
