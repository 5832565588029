import { ReactNode } from 'react';

import { Flex } from 'shared';

import { ChallengeProofIcon } from 'features/challenge/components/ProofIcon';
import { ChallengeStatusIcon } from 'features/challenge/components/StatusIcon';
import {
  JournalCardBody,
  JournalCardContainer,
  JournalCardFailReasons,
  JournalCardFooter,
  JournalCardHeader,
  JournalCardText,
  JournalCardTitle,
  JournalCardCreatedAtTime,
  JOURNAL_NOTE_TYPES,
  JournalNote,
  JournalCardMediaList,
  JournalCardMenu,
} from 'features/journal';

type TJournalCard = {
  children: ReactNode;
  note: JournalNote;
  setEditedNote: (item: JournalNote) => void;
  isAuthor: boolean;
};

export const JournalCard = ({
  children,
  note,
  setEditedNote,
  isAuthor,
}: TJournalCard) => (
  <JournalCardContainer>
    <JournalCardHeader>
      <Flex justifyContent="flex-start" alignItems="center" gap="0.5rem">
        <JournalCardCreatedAtTime date={note.createdAt} />
        {note.noteType === JOURNAL_NOTE_TYPES.PROOF && <ChallengeProofIcon />}
        {Boolean(note.challenge?.status) && (
          <ChallengeStatusIcon status={note.challenge.status} />
        )}
        <JournalCardTitle>{note.challenge?.title}</JournalCardTitle>
      </Flex>
      <JournalCardMenu
        isAuthor={isAuthor}
        noteId={note.noteId}
        onEdit={() => setEditedNote(note)}
        isNoteRead={note.isRead}
      />
    </JournalCardHeader>
    <JournalCardBody>
      <Flex justifyContent="space-between" width="100%">
        <JournalCardText text={note.text} />
        <JournalCardMediaList mediaList={note.mediaList} />
      </Flex>
      <JournalCardFailReasons reasons={note.challenge?.failReasons} />
    </JournalCardBody>
    <JournalCardFooter>{children}</JournalCardFooter>
  </JournalCardContainer>
);
