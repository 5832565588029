import { TabPanel, TabPanelsProps } from '@chakra-ui/react';

import {
  PlanningBacklogFilterProvider,
  SelectedGoalsProvider,
} from 'features/planning';

import { PlanningBacklogChallenges } from './Challenges';

type TPlanningBacklogChallengesTab = TabPanelsProps;
// TabPanelsProps need to be passed in order to make TabPanel component work

export const PlanningBacklogChallengesTab = (
  props: TPlanningBacklogChallengesTab
) => (
  <TabPanel
    m={0}
    p={0}
    height="100%"
    width="100%"
    overflowY="auto"
    overflowX="hidden"
    {...props}
  >
    <SelectedGoalsProvider>
      <PlanningBacklogFilterProvider>
        <PlanningBacklogChallenges />
      </PlanningBacklogFilterProvider>
    </SelectedGoalsProvider>
  </TabPanel>
);
