import { useFormContext } from 'react-hook-form';

import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { ModalButton } from 'shared/components/Modal/ModalButton';

type TGoalAddDialogFooter = {
  onSubmit: () => void;
};

export const GoalAddDialogFooter = ({ onSubmit }: TGoalAddDialogFooter) => {
  const { formState } = useFormContext();

  return (
    <Flex width="100%">
      <ModalButton variant={VARIANTS.SECONDARY}>Cancel</ModalButton>
      <ModalButton
        onClick={onSubmit}
        shouldClose={formState.isValid}
        variant={VARIANTS.PRIMARY}
        dataTestid="goal-done-btn"
      >
        Save
      </ModalButton>
    </Flex>
  );
};
