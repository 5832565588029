import { QueryClientProvider } from '@tanstack/react-query';
import { initReactQuery } from 'lib/reactQuery/init';
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { RootRouterErrorBoundary } from 'app/error';
import Layout from 'app/layout/components/Layout/Layout';
import NotFoundPage from 'app/pages/NotFound';
import { adminRoutes } from 'app/routes/admin';
import { coachRoutes } from 'app/routes/coach';
import { participantRoutes } from 'app/routes/participant';
import { SeoTitle } from 'app/seo/components/Title';
import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { AuthProvider } from 'features/auth/providers/AuthProvider';
import { UserProvider } from 'features/user/context';

const ForgotPasswordPage = lazy(
  () => lazyRetry(() => import('app/pages/ForgotPassword')),
  'ForgotPasswordPage'
);

const EmailCheckPage = lazy(
  () => lazyRetry(() => import('app/pages/EmailCheck')),
  'EmailCheckPage'
);

const queryClient = initReactQuery();

const AppProviders = () => (
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <Outlet />
      </UserProvider>
    </QueryClientProvider>
  </AuthProvider>
);

const appRoutes = [
  {
    element: <AppProviders />,
    errorElement: <RootRouterErrorBoundary />,
    children: [
      {
        path: '/',
        element: <Layout />,
        children: [
          {
            path: 'reset-password',
            element: (
              <>
                <SeoTitle title="Reset Password" />
                <Suspense fallback={<Loader />}>
                  <ForgotPasswordPage />
                </Suspense>
              </>
            ),
          },
          {
            path: 'reset-password/:email',
            element: (
              <>
                <SeoTitle title="Check Email" />
                <Suspense fallback={<Loader />}>
                  <EmailCheckPage />
                </Suspense>
              </>
            ),
          },
          ...adminRoutes,
          ...coachRoutes,
          ...participantRoutes,
          {
            path: '*',
            element: (
              <>
                <SeoTitle title="Not Found Page" />
                <NotFoundPage />
              </>
            ),
          },
        ],
      },
    ],
  },
];

export default appRoutes;
