import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

import { checkboxAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const rounded = definePartsStyle({
  control: defineStyle({
    rounded: 'full',
  }),
});

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle: {
    control: {
      _checked: {
        bg: 'bgCompAccent',
        borderColor: 'brdAccent',
        _hover: {
          bg: 'bgCompAccent',
          borderColor: 'brdAccent',
        },
      },
      _focusVisible: {
        boxShadow: 'none',
      },
      borderColor: 'brdAccent',
      borderWidth: '1px',
      borderRadius: 'borderRadius01',
    },
  },
  sizes: {
    sm: {
      label: {
        fontSize: 'xs',
      },
      control: {
        width: '1rem',
        height: '1rem',
      },
    },
    md: {
      label: {
        fontSize: 'sm',
      },
      control: {
        width: '1.25rem',
        height: '1.25rem',
      },
    },
  },
  variants: { rounded },
});
