import { Box } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { BasicTextEditor } from 'shared';

import {
  SessionSidebarFieldset,
  SessionSidebarFieldsetLabel,
} from 'features/sessions/components';

import { TextEditorFormats } from 'shared/components/TextEditor/constants';

export const ParticipantProfileNote = () => {
  const { setValue, getValues } = useFormContext();
  const fieldKey = getValues('nextSessionFieldKey');
  const name = `${fieldKey ? fieldKey + '.' : ''}coachingClientNote.text`;
  const clientNotes = useWatch({ name }) || '';

  const handleChange = (value: string) => {
    setValue(name, value, {
      shouldDirty: true,
    });
  };

  return (
    <SessionSidebarFieldset>
      <SessionSidebarFieldsetLabel>
        Participant profile notes
      </SessionSidebarFieldsetLabel>
      <Box minH="12rem">
        <BasicTextEditor
          value={clientNotes}
          onChange={handleChange}
          placeholder="Start writing here"
          format={TextEditorFormats.json}
        />
      </Box>
    </SessionSidebarFieldset>
  );
};
