import { usePinnedColumnsContext } from 'shared';

import { usePlanningDraftSprintsQuery } from 'features/planning';
import { useFilterDraftSprintsByCoachingToolId } from 'features/planning/hooks/useFilterDraftSprintsByCoachingToolId';
import { IPlanningDraftSprint } from 'features/planning/types';

import { PlanningDraftSprint } from './DraftSprint';

export const PlanningDraftSprints = ({ pinned }: { pinned: boolean }) => {
  const { pinnedColumns } = usePinnedColumnsContext();
  const { data: draftSprintsData } = usePlanningDraftSprintsQuery<
    IPlanningDraftSprint[]
  >({
    options: {
      select: (data) => {
        return data.filter((sprint) => {
          return pinned
            ? pinnedColumns.has(sprint.sprintId)
            : !pinnedColumns.has(sprint.sprintId);
        });
      },
    },
  });

  const coachingToolsOnSprintsMap =
    useFilterDraftSprintsByCoachingToolId(draftSprintsData);

  return (
    <>
      {draftSprintsData?.map((sprint) => {
        return (
          <PlanningDraftSprint
            key={`${sprint.sprintId}-${sprint.challenges.length}`}
            sprint={sprint}
            sprintsCount={draftSprintsData.length}
            coachingToolsInSprintsMap={coachingToolsOnSprintsMap}
          />
        );
      })}
    </>
  );
};
