import PropTypes from 'prop-types';

import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';

export const SprintInfoBody = ({ children }) => {
  return (
    <FlexItem flexGrow={1}>
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="var(--spacing01)"
      >
        {children}
      </Flex>
    </FlexItem>
  );
};

SprintInfoBody.propTypes = { children: PropTypes.node.isRequired };
