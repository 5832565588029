import { lazy, Suspense, useCallback } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { EditableUserInfo } from 'features/user/config/types';

import { modal, SIZES } from 'shared/components/Modal';

const EditUserInfoDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/user/components/Edit/Dialog'),
    'EditUserInfoDialogContent'
  )
);

type TUseOpenEditUserInfoModal = {
  userEmail: string;
  prevUserInfo: EditableUserInfo;
};

export const useOpenEditUserInfoModal = ({
  userEmail,
  prevUserInfo,
}: TUseOpenEditUserInfoModal) =>
  useCallback(
    ({ onSubmit }: { onSubmit: (userInfo: EditableUserInfo) => void }) => {
      modal.open({
        content: (
          <Suspense fallback={<Loader size="sm" />}>
            <EditUserInfoDialogContent
              onSubmit={(userInfo: EditableUserInfo) => {
                onSubmit(userInfo);
              }}
              userEmail={userEmail}
              prevUserInfo={prevUserInfo}
            />
          </Suspense>
        ),
        props: {
          size: SIZES.SM,
          title: 'Edit user information',
        },
      });
    },
    [userEmail, prevUserInfo]
  );
