import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { SPRINT_STATES } from 'features/sprint/config';

import { Typography } from 'shared/components/Typography';

const sprintInfoStyleMap = {
  [SPRINT_STATES.ACTIVE]: {
    borderColor: 'var(--brdSuccess)',
    textColor: 'white',
  },
  [SPRINT_STATES.FINISHED]: {
    borderColor: 'var(--border-secondary)',
    textColor: 'black',
  },
  [SPRINT_STATES.DRAFT]: {
    borderColor: 'var(--brdSuccess)',
    textColor: 'white',
  },
};

const SprintInfoStatusPropTypes = {
  status: PropTypes.oneOf(Object.values(SPRINT_STATES)).isRequired,
  successRate: PropTypes.number,
  weekIndex: PropTypes.number,
};

export const SprintInfoStatus = ({ status, successRate, weekIndex }) => {
  const sprintInfoContentMap = {
    [SPRINT_STATES.ACTIVE]: `W${weekIndex}`,
    [SPRINT_STATES.FINISHED]: `${successRate || 0}%`,
    [SPRINT_STATES.DRAFT]: 'D',
  };

  return (
    <Box
      style={{
        width: '2.375rem',
        height: '2.375rem',
        borderRadius: '50%',
        backgroundColor:
          weekIndex === 0
            ? 'transparent'
            : sprintInfoStyleMap[status].borderColor,
        border: sprintInfoStyleMap[status].borderColor,
      }}
    >
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          type="small"
          fontWeight="semiBold"
          color={sprintInfoStyleMap[status].textColor}
        >
          {sprintInfoContentMap[status]}
        </Typography>
      </Flex>
    </Box>
  );
};

SprintInfoStatus.propTypes = SprintInfoStatusPropTypes;
