import PropTypes from 'prop-types';

import { Typography } from 'shared/components/Typography';

const FieldLabel = ({ children }) => (
  <Typography as="label" color="black" fontWeight="semiBold" type="small">
    {children}
  </Typography>
);

FieldLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FieldLabel;
