import { Flex, Typography } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

export const JournalAddNoteDialogHeader = () => (
  <Flex
    flexDirection="column"
    width="100%"
    alignItems="flex-start"
    px="0.75rem"
    gap="0.25rem"
  >
    <Typography as="h3" type="heading" fontWeight="semiBold" color="black">
      Add note
    </Typography>
    <Flex alignItems="center">
      <Icon name={iconNames.eye} />
      <Typography color="gray" fontWeight="medium" type="small">
        Visible to your coach
      </Typography>
    </Flex>
  </Flex>
);
