import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { ParticipantsTableActionDisabled } from 'features/participants/components/Table/Actions/Disabled';
import { ParticipantsTableActionWrapper } from 'features/participants/components/Table/Actions/Wrapper';
import { ParticipantsTableUserInfo } from 'features/participants/config/types';
import { NextSessionSidebar } from 'features/sessions/components/NextSessionForm';

import { ButtonIcon, VARIANTS } from 'shared/components/Button';
import { sidebar } from 'shared/components/Sidebar';

type ParticipantsTableNextSessionHintProps = {
  appointmentId?: string;
  userInfo: ParticipantsTableUserInfo;
};

export const ParticipantsTableNextSessionHint = ({
  appointmentId,
  userInfo,
}: ParticipantsTableNextSessionHintProps) => {
  const handleNextSessionHintClick = () => {
    sidebar.open({
      content: <NextSessionSidebar appointmentId={appointmentId!} />,
      context: { userInfo },
    });
  };

  return appointmentId ? (
    <ButtonIcon
      ariaLabel="open session hint"
      icon={
        <ParticipantsTableActionWrapper>
          <Icon name={iconNames.clinicalNotes} width={18} height={18} />
        </ParticipantsTableActionWrapper>
      }
      onClick={handleNextSessionHintClick}
      size="sm"
      variant={VARIANTS.TERTIARY}
      dataTestid="next-session-hint-button"
    />
  ) : (
    <ParticipantsTableActionDisabled
      label="User doesn't have sessions yet"
      icon={iconNames.clinicalNotes}
    />
  );
};
