import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';

import { Button, VARIANTS } from 'shared/components/Button';

type CoachingToolsCardEditButtonsProps = {
  canBeDeleted: boolean;
  onDelete: () => void;
  onSave: () => void;
  disabled: boolean;
};
export const CoachingToolsCardEditButtons = ({
  canBeDeleted,
  onDelete,
  onSave,
  disabled,
}: CoachingToolsCardEditButtonsProps) => {
  return (
    <Flex gap="var(--spacing04)" width="100%">
      {canBeDeleted && (
        <FlexItem flexGrow={1}>
          <Flex alignItems="stretch" flexDirection="column" width="100%">
            <Button variant={VARIANTS.SECONDARY} onClick={onDelete}>
              Delete
            </Button>
          </Flex>
        </FlexItem>
      )}
      <FlexItem flexGrow={1}>
        <Flex alignItems="stretch" flexDirection="column" width="100%">
          <Button
            variant={VARIANTS.PRIMARY}
            disabled={disabled}
            onClick={onSave}
            dataTestid="rate-session-tool-save-btn"
          >
            Save
          </Button>
        </Flex>
      </FlexItem>
    </Flex>
  );
};
