import { Button, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useAddGoal } from 'features/goal';

export const GoalAddButton = () => {
  const { onAddGoalClick } = useAddGoal();

  return (
    <Button
      onClick={onAddGoalClick}
      variant={VARIANTS.PRIMARY}
      leftIcon={
        <Icon
          width={10}
          height={10}
          name={iconNames.plus}
          stroke="var(--fgPrimaryInverse)"
        />
      }
      dataTestid="add-new-goal-btn"
    >
      Add goal
    </Button>
  );
};
