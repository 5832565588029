import { firstLetterUpperCase } from 'shared_DEPRECATED/utils';

import { partitionItems } from 'shared/utils/array';

export const getDimensionsText = (
  dimensions: string[],
  visibleDimensionsCount: number = dimensions.length
) => {
  dimensions = dimensions.map((dimension) => firstLetterUpperCase(dimension));

  const { firstItems, remainingCount } = partitionItems(
    dimensions,
    visibleDimensionsCount
  );

  let resultString = firstItems.join(' • ');

  if (remainingCount > 0) {
    resultString += ` • ${remainingCount} more`;
  }

  return resultString;
};
