import { Badge } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Box, Typography } from 'shared';

type TChatUnreadIndicator = {
  unreadMessagesCount?: number;
  isChatMarkAsUnread: boolean;
  open?: boolean;
};

export const ChatUnreadIndicator = ({
  unreadMessagesCount,
  isChatMarkAsUnread,
  open = false,
}: TChatUnreadIndicator) => {
  if (!unreadMessagesCount && isChatMarkAsUnread) {
    return <ChatUnreadIndicatorBadge />;
  }

  if (!open && !!unreadMessagesCount) {
    return (
      <ChatUnreadIndicatorBadge unreadMessagesCount={unreadMessagesCount}>
        <Typography
          lineHeight="20px"
          textAlign="center"
          type="meta"
          dataTestid="unread-messages-counter"
          color="whiteTotal"
        >
          {unreadMessagesCount}
        </Typography>
      </ChatUnreadIndicatorBadge>
    );
  }

  return null;
};

type TChatUnreadIndicatorBadge = {
  unreadMessagesCount?: number;
  children?: ReactNode;
};

const ChatUnreadIndicatorBadge = ({
  unreadMessagesCount,
  children = null,
}: TChatUnreadIndicatorBadge) =>
  unreadMessagesCount && unreadMessagesCount < 10 ? (
    <Box
      borderRadius="50%"
      bg="var(--bgCompAccent)"
      minWidth="20px"
      minHeight="20px"
      data-testid="unread-chat-badge"
    >
      {children}
    </Box>
  ) : (
    <Badge
      bg="var(--bgCompAccent)"
      borderRadius="20px"
      p="0px 6px"
      minWidth="20px"
      minHeight="20px"
      data-testid="unread-chat-badge"
    >
      {children}
    </Badge>
  );
