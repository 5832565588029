import { Nullable } from 'shared_DEPRECATED/types';

import { TARGET_PERIOD } from '../config/types';

export const useIsChallengeFrequencyConfigurable = ({
  challengeTargetPeriod,
}: {
  challengeTargetPeriod: Nullable<TARGET_PERIOD>;
}) => {
  return (
    !challengeTargetPeriod || challengeTargetPeriod === TARGET_PERIOD.PER_DAY
  );
};
