import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import { $generateHtmlFromNodes } from '@lexical/html';
import { $convertToMarkdownString } from '@lexical/markdown';
import { $isRootTextContentEmpty } from '@lexical/text';
import { EditorState, LexicalEditor } from 'lexical';
import { logError } from 'lib/sentry/logError';

import { TEXT_EDITOR_TRANSFORMERS } from '../constant';
import { TextEditorFormats } from '../constants';
import { TextEditorFormat } from '../types';

type MarkdownOnChangePluginProps = {
  onChange: (text: string) => void;
  format: TextEditorFormat;
};

export const MarkdownOnChangePlugin = ({
  onChange,
  format,
}: MarkdownOnChangePluginProps) => {
  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    editor.update(() => {
      try {
        const raw =
          format === TextEditorFormats.markdown
            ? $convertToMarkdownString(
                TEXT_EDITOR_TRANSFORMERS,
                undefined,
                true
              )
            : $generateHtmlFromNodes(editor, null);
        const isEmpty = $isRootTextContentEmpty(false);

        onChange(isEmpty ? '' : raw);
      } catch (error) {
        logError(error);
      }
    });
  };

  return <OnChangePlugin onChange={handleChange} ignoreSelectionChange />;
};
