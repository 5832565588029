import { toastConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import {
  JournalNotePOSTType,
  useOpenJournalAddNoteModal,
  useJournalAddNoteMutation,
} from 'features/journal';

import { useJournalAttachmentsUpload } from './useJournalAttachmentsUpload';

export const useAddJournalNote = () => {
  const { mutateAsync: addNote } = useJournalAddNoteMutation();
  const { uploadAttachments } = useJournalAttachmentsUpload();

  const onSubmit = async (note: JournalNotePOSTType) => {
    try {
      let mediaIds: string[] = [];

      if (Boolean(note.media.length)) {
        mediaIds = await uploadAttachments(note.media);
      }

      await addNote({
        ...note,
        mediaIds,
      });
    } catch (err) {
      toast.error(
        "There's an error with creating the note. Please try again later.",
        toastConfig
      );
    }
  };

  const openJournalNoteAddModal = useOpenJournalAddNoteModal();

  return {
    onAddJournalNoteClick: () => openJournalNoteAddModal({ onSubmit }),
  };
};
