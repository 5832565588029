import { useParams } from 'react-router-dom';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';
import { getPictureUrl } from 'shared_DEPRECATED/utils';

import { ParticipantInfoWithCoachingStatus } from 'features/participants/components/ParticipantInfoWithCoachingStatus';
import {
  useEditParticipantInfoMutation,
  useParticipantQuery,
} from 'features/participants/hooks';
import { UserEditButton } from 'features/user/components/Edit/Button';

export const CoachSidebarParticipantInfo = () => {
  const { email } = useParams();
  const { participant, isLoading: isUserDataLoading } =
    useParticipantQuery(email);
  const { mutateAsync } = useEditParticipantInfoMutation({
    userEmail: participant.userEmail,
  });

  if (isUserDataLoading) {
    return <Loader size="sm" />;
  }

  return (
    <Flex justifyContent="space-between" alignItems="center" width="100%">
      <ParticipantInfoWithCoachingStatus
        coachingMode={participant.coachingMode}
        name={participant.userName}
        pictureUrl={getPictureUrl(participant.userPicture)}
        timezone={participant.timezone}
      />
      <UserEditButton
        firstName={participant.firstName}
        lastName={participant.lastName}
        email={participant.userEmail}
        dataTestid={'coach-sidebar-participants-info-edit'}
        mutateFunc={mutateAsync}
      />
    </Flex>
  );
};
