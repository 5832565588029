import { useFormContext } from 'react-hook-form';

import { Switch } from 'shared';
import useToggle from 'shared_DEPRECATED/hooks/useToggle';
import { Nullable } from 'shared_DEPRECATED/types';

import { DEFAULT_REMINDER_VALUE } from 'features/challenge/config';

type TChallengeReminderFieldSwitch = {
  reminder: Nullable<string>;
};

export const ChallengeReminderFieldSwitch = ({
  reminder,
}: TChallengeReminderFieldSwitch) => {
  const [isOpen, setIsOpen] = useToggle({ defaultValue: !!reminder });

  const { setValue } = useFormContext();

  const onChange = () => {
    setValue('reminder', !isOpen ? DEFAULT_REMINDER_VALUE : null);
    setIsOpen();
  };

  return <Switch isChecked={isOpen} onChange={onChange} size="sm" />;
};
