import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useEditGoal, GoalCardType } from 'features/goal';

import { ButtonIcon, VARIANTS } from 'shared/components/Button';

export const GoalEditButton = ({
  goalId,
  columnId,
  name,
  dimensions,
  deadline,
  deepDive,
  columnLabel,
  completedAt,
  note,
}: Omit<GoalCardType, 'prioritizedAt'>) => {
  const { onEditGoalClick } = useEditGoal({
    goalId,
    columnId,
    name,
    dimensions,
    deadline,
    deepDive,
    columnLabel,
    completedAt,
    note,
  });

  return (
    <ButtonIcon
      ariaLabel="edit goal"
      icon={
        <Icon
          name={iconNames.pen}
          width={16}
          height={18}
          fill="var(--fgPrimary)"
        />
      }
      onClick={onEditGoalClick}
      size="md"
      variant={VARIANTS.SECONDARY}
    />
  );
};
