import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useDeleteGoal } from 'features/goal';

import { ButtonIcon, VARIANTS } from 'shared/components/Button';

type TGoalDeleteButton = {
  goalId: string;
  columnId: string;
};

export const GoalDeleteButton = ({ goalId, columnId }: TGoalDeleteButton) => {
  const { onDeleteGoalClick } = useDeleteGoal({ goalId, columnId });

  return (
    <ButtonIcon
      ariaLabel="delete goal"
      icon={
        <Icon
          name={iconNames.binFilled}
          width={16}
          height={18}
          fill="var(--fgPrimary)"
        />
      }
      onClick={onDeleteGoalClick}
      size="md"
      variant={VARIANTS.SECONDARY}
    />
  );
};
