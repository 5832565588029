import { MenuList } from '@chakra-ui/react';
import { When } from 'react-if';

import { MenuItem, Menu, Typography, Divider, Box } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Nullable } from 'shared_DEPRECATED/types';

import { useCoachSettingsEnabled } from 'features/coach/settings/hooks';

import { MenuTriggerButton } from './MenuTriggerButton';

type HeaderMenuContentProps = {
  navigateToSettingsPage: () => void;
  handleLogout: () => void;
  name: string;
  email: Nullable<string>;
  profileUrl: string;
  children: React.ReactNode;
};

export const HeaderMenuContent = ({
  navigateToSettingsPage,
  handleLogout,
  name,
  email,
  profileUrl,
  children,
}: HeaderMenuContentProps) => {
  const isCoachProfileFormPageDisabled = useCoachSettingsEnabled();

  return (
    <Menu offset={[0, 8]} autoSelect={false}>
      <MenuTriggerButton
        name={name}
        pictureUrl={profileUrl}
        dataTestId="UserMenu_popoverButton"
      />
      <MenuList w="220px" data-testid="UserMenu_list">
        <When condition={!!email}>
          <Box p="12px 8px">
            <Typography
              textAlign="center"
              type="meta"
              color="gray"
              fontWeight="medium"
            >
              {email}
            </Typography>
          </Box>
        </When>
        {children}
        <Divider orientation="horizontal" />
        <When condition={!isCoachProfileFormPageDisabled}>
          <MenuItem
            onClick={navigateToSettingsPage}
            gap={2}
            h={12}
            data-testid="UserMenuOptionButton_profileSettings"
          >
            <Icon name={iconNames.settings} width={18} />
            <Typography type="body" fontWeight="medium" color="black">
              Settings
            </Typography>
          </MenuItem>
        </When>
        <MenuItem
          onClick={handleLogout}
          gap={2}
          h={12}
          data-testid="UserMenuOptionButton_logOut"
        >
          <Icon name={iconNames.logout} width={24} />
          <Typography type="body" fontWeight="medium" color="black">
            Log out
          </Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
