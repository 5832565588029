import { useParams } from 'react-router-dom';

import { NavigationLink } from 'app/layout/components/Layout/CoachSidebar/Navigation';
import { CoachSidebarParticipantInfo } from 'app/layout/components/Layout/CoachSidebar/ParticipantInfo';
import { NavigationRoadmapLinks } from 'app/layout/components/Navigation';
import { motion } from 'framer-motion';
import Box from 'shared_DEPRECATED/components/Box';
import { iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { SprintNavigation } from 'features/sprint/components/Navigation';
import { sharedSprintLinks } from 'features/sprint/utils';

export const CoachSidebarOpen = () => {
  const { email } = useParams();
  const getNavigationLinkWithParticipantEmail = getNavigationLink(email!);

  return (
    <motion.div
      key="open"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <CoachSidebarParticipantInfo />
      <Spacers sizes={['zr zr lg', 'zr zr md']} />
      <NavigationLink
        iconConfig={{
          name: iconNames.dashboard,
        }}
        link={getNavigationLinkWithParticipantEmail('dashboard')}
        text="Dashboard"
        dataTestid="dashboard-bnt"
      />
      <Spacer size="xsm zr" />
      <NavigationLink
        iconConfig={{
          name: iconNames.sessions,
        }}
        link={getNavigationLinkWithParticipantEmail('sessions')}
        text="Sessions"
        dataTestid="sessions-bnt"
      />
      <Spacer size="xsm zr" />
      <NavigationRoadmapLinks
        linkCallback={getNavigationLinkWithParticipantEmail}
      />
      <Spacer size="xsm zr" />
      <NavigationLink
        iconConfig={{
          name: iconNames.group,
        }}
        link={getNavigationLinkWithParticipantEmail('groups')}
        text="Groups"
        dataTestid="groups-btn"
      />
      <Spacers sizes={['zr zr lg', 'zr zr md']} />
      <Box style={{ borderTop: 'var(--border-secondary)' }} />
      <Spacers sizes={['lg zr zr', 'md zr zr']} />
      <SprintNavigation />
    </motion.div>
  );
};

const getNavigationLink = (email: string) => (path: string) =>
  `${sharedSprintLinks.participants}/${email}/${path}`;
