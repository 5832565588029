import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  CHALLENGE_TYPE_FIELDS,
  CHALLENGE_TYPES,
  MAX_SPRINT_CHALLENGES_COUNT,
  TARGET_TYPES,
} from 'features/challenge/config';
import { useInitialChallenge } from 'features/challenge/hooks/useInitialChallenge';
import {
  getChallengeTypeByTarget,
  getDefaultChallengeType,
} from 'features/challenge/utils';

import { useModalContext } from 'shared/components/Modal';

export const useRepeatFieldConfig = () => {
  const { getValues, setValue, watch } = useFormContext();
  const { sprint } = useModalContext();
  const { target: initialChallengeFrequency } = useInitialChallenge();

  const initialChallengeField = useRef(
    CHALLENGE_TYPE_FIELDS[getChallengeTypeByTarget(getValues('target'))]
  );

  const challengeType = watch('type');
  const availableChallengeFields = sprint?.availableChallengeFields;

  const isOtherChallengeFieldsAvailable = !sprint
    ? true
    : Object.entries(availableChallengeFields).some(
        ([field, isAvailable]) =>
          initialChallengeField.current !== field && isAvailable
      );

  const handleRepeatChange = () => {
    const type =
      challengeType === CHALLENGE_TYPES.NON_REPEATING
        ? CHALLENGE_TYPES.REPEATING
        : CHALLENGE_TYPES.NON_REPEATING;

    setValue('type', type, { shouldDirty: true });
    type === CHALLENGE_TYPES.REPEATING
      ? setValue('target', initialChallengeFrequency, { shouldDirty: true })
      : setValue(
          'target',
          {
            type: TARGET_TYPES.DUE_DATE,
            dueDate: !sprint
              ? dateUtils().format('YYYY-MM-DD')
              : dateUtils(sprint.endDate).format('YYYY-MM-DD'),
          },
          {
            shouldDirty: true,
          }
        );
  };

  const defaultChallengeType = !sprint
    ? CHALLENGE_TYPES.REPEATING
    : getDefaultChallengeType(availableChallengeFields);

  const isNewChallenge = !getValues('sprintChallengeId');

  const isChallengeFieldFull = !sprint
    ? false
    : !availableChallengeFields[initialChallengeField.current];

  if (isNewChallenge && isChallengeFieldFull) {
    handleRepeatChange(defaultChallengeType === CHALLENGE_TYPES.REPEATING);
    initialChallengeField.current = CHALLENGE_TYPE_FIELDS[defaultChallengeType];
  }

  const validationMessage = `You can only have ${MAX_SPRINT_CHALLENGES_COUNT} ${
    defaultChallengeType === CHALLENGE_TYPES.REPEATING ? 'one-time' : 'repeated'
  } challenges in one sprint.`;

  return {
    onChange: handleRepeatChange,
    disabled: !isOtherChallengeFieldsAvailable,
    validationMessage,
    checked: challengeType === CHALLENGE_TYPES.REPEATING,
  };
};
