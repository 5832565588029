import { useState } from 'react';
import { When } from 'react-if';
import { For } from 'react-loops';

import { useJournalAnalytics } from 'app/analytics/useJournalAnalytics';
import { Box, Flex } from 'shared';
import Loader from 'shared_DEPRECATED/components/Loader';
import { Nullable } from 'shared_DEPRECATED/types';

import {
  JournalReply,
  JournalRepliesCard,
  useJournalNoteQuery,
  JournalNote,
  JournalForm,
  useJournalEditNoteMutation,
  JournalNoteReply,
  JournalReplyCardReactions,
  useJournalCreateReplyMutation,
  useJournalEditReplyMutation,
  JournalFormHeader,
  JournalFormHeaderTitle,
  JournalCardUnreadIndicator,
} from 'features/journal';
import { User } from 'features/user/User';

import { useSidebarContext } from 'shared/components/Sidebar/context';

export const JournalRepliesContent = () => {
  const [editedNote, setEditedNote] = useState<Nullable<JournalNote>>(null);
  const [editedReply, setEditedReply] =
    useState<Nullable<JournalNoteReply>>(null);

  const { noteId, user } = useSidebarContext() as {
    user: User;
    noteId: string;
  };

  const { data: { note, replies } = {}, isLoading } = useJournalNoteQuery({
    noteId,
  });

  const {
    sendNoteReplyAddedAnalyticsEvent,
    sendNoteReplyEditedAnalyticsEvent,
  } = useJournalAnalytics();
  const { mutateAsync: editNote } = useJournalEditNoteMutation();
  const { mutateAsync: editReply } = useJournalEditReplyMutation({ noteId });
  const { mutateAsync: createReply } = useJournalCreateReplyMutation({
    noteId,
  });

  const handleEditNote = async ({ text }: { text: string }) => {
    const noteId = editedNote?.noteId ?? '';
    setEditedNote(null);

    await editNote({ text, noteId });
  };

  const handleEditReply = async ({ text }: { text: string }) => {
    const replyId = editedReply?.replyId ?? '';
    setEditedReply(null);

    await editReply({ text, replyId });

    sendNoteReplyEditedAnalyticsEvent();
  };

  const handleCreateReply = async ({ text }: { text: string }) => {
    await createReply({ text });

    sendNoteReplyAddedAnalyticsEvent();
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Flex
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Box style={{ flexGrow: 1, overflow: 'auto' }}>
        {note && Object.keys(note).length > 0 && (
          <Box w="100%" p="0.5rem 0.5rem 0.5rem 1rem" position="relative">
            <JournalRepliesCard
              note={note}
              setEditedNode={() => setEditedNote(note)}
            />
            {!note.isRead && (
              <JournalCardUnreadIndicator
                options={{
                  left: '0.25rem',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              />
            )}
          </Box>
        )}
        <When condition={replies && Boolean(replies.length)}>
          <For of={replies}>
            {(reply) => {
              const isAuthor = reply.author.userId === user.userId;

              return (
                <JournalReply
                  date={reply.createdAt}
                  pictureUrl={reply.author.picture.url}
                  text={reply.text}
                  userName={reply.author.fullName}
                  replyId={reply.replyId}
                  isAuthor={isAuthor}
                  setEditedReply={() => setEditedReply(reply)}
                  relatedNoteId={note!.noteId}
                >
                  <JournalReplyCardReactions
                    replyId={reply.replyId}
                    noteId={reply.noteId}
                    reactions={reply.reactions}
                  />
                </JournalReply>
              );
            }}
          </For>
        </When>
      </Box>
      <Box
        position="sticky"
        bottom="0"
        p="1rem"
        boxShadow="0 -2px 4px rgba(0, 0, 0, 0.1)"
      >
        {editedReply && (
          <JournalForm
            text={editedReply.text}
            onSave={handleEditReply}
            placeholderText="Edit reply text..."
          >
            <JournalFormHeader onCancel={() => setEditedReply(null)}>
              <JournalFormHeaderTitle>Editing reply</JournalFormHeaderTitle>
            </JournalFormHeader>
          </JournalForm>
        )}
        {editedNote && (
          <JournalForm
            text={editedNote.text}
            onSave={handleEditNote}
            placeholderText="Edit note text..."
          >
            <JournalFormHeader onCancel={() => setEditedNote(null)}>
              <JournalFormHeaderTitle>Editing note</JournalFormHeaderTitle>
            </JournalFormHeader>
          </JournalForm>
        )}
        {!editedReply && !editedNote && (
          <JournalForm
            onSave={handleCreateReply}
            placeholderText="Write a reply..."
          />
        )}
      </Box>
    </Flex>
  );
};
