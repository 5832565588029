import {
  Avatar as ChakraAvatar,
  AvatarBadge as ChakraAvatarBadge,
  AvatarGroup as ChakraAvatarGroup,
  AvatarProps as ChakraAvatarProps,
} from '@chakra-ui/react';

type AvatarProps = ChakraAvatarProps & {
  dataTestid?: string;
  children?: React.ReactNode;
};

export const Avatar = ({
  size = 'xs',
  children,
  name,
  dataTestid,
  ...rest
}: AvatarProps) => {
  return (
    <ChakraAvatar
      size={size}
      name={`${name} avatar`}
      data-testid={dataTestid}
      {...rest}
    >
      {children}
    </ChakraAvatar>
  );
};

export const AvatarBadge = ChakraAvatarBadge;

export const AvatarGroup = ChakraAvatarGroup;
