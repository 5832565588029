import PropTypes from 'prop-types';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import { VARIANTS } from 'shared/components/Button';
import { ModalHeader, ModalBody, ModalFooter } from 'shared/components/Modal';
import { ModalButton } from 'shared/components/Modal/ModalButton';
import { Typography } from 'shared/components/Typography';

const CriticalDialogContentPropTypes = {
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  submitButtonTitle: PropTypes.string,
};

const CriticalDialogContent = ({
  onSubmit,
  title,
  subTitle,
  submitButtonTitle = 'Submit',
}) => {
  return (
    <>
      <ModalHeader>
        <Typography as="h3" type="body" fontWeight="semiBold" color="black">
          {title}
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Typography color="gray" type="small">
          {subTitle}
        </Typography>
      </ModalBody>
      <ModalFooter>
        <Spacer size="zr md zr zr">
          <Spacer size="zr sm zr zr">
            <ModalButton variant={VARIANTS.SECONDARY}>Cancel</ModalButton>
          </Spacer>
        </Spacer>
        <ModalButton
          dataTestid="submit-btn-remove-challenge-dialog"
          onClick={onSubmit}
          variant={VARIANTS.CRITICAL}
        >
          {submitButtonTitle}
        </ModalButton>
      </ModalFooter>
    </>
  );
};

CriticalDialogContent.propTypes = CriticalDialogContentPropTypes;

export default CriticalDialogContent;
