import { useCallback } from 'react';

import { Box, Image, MediaGallery } from 'shared';
import Button from 'shared_DEPRECATED/components/Button/Button';
import { MediaType } from 'types/common';

import { JournalCardImageModal } from 'features/journal';

import { SIZES, modal } from 'shared/components/Modal';

type TJournalCardImageAttachment = {
  media: MediaType[];
  url: string;
};

export const JournalCardImageAttachment = ({
  media,
  url,
}: TJournalCardImageAttachment) => {
  const handleImageClick = useCallback(
    () =>
      modal.open({
        content:
          media.length > 1 ? (
            <MediaGallery media={media} />
          ) : (
            <JournalCardImageModal src={media[0].url} />
          ),
        props: {
          title: '',
          size: SIZES.LG,
        },
      }),
    [media]
  );

  if (!media.length) {
    return null;
  }

  return (
    <Button onClick={handleImageClick}>
      <Box
        w="3rem"
        h="3rem"
        overflow="hidden"
        borderRadius="0.5rem"
        boxShadow="var(--button-box-shadow)"
      >
        <Image
          alt="image"
          objectFit="cover"
          width="100%"
          height="100%"
          src={url}
        />
      </Box>
    </Button>
  );
};
