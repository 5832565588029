import { FailReasonValue } from '../types';

export const JOURNAL_NOTE_CHALLENGE_STATUSES = {
  EMPTY: 'empty',
  SUCCESS: 'success',
  FAIL: 'fail',
};

export const JOURNAL_NOTE_TYPES = {
  GENERAL: 'general',
  PROOF: 'proof',
} as const;

export const JOURNAL_SPRINT_FILTERS = {
  ALL: 'all',
  CURRENT: 'current',
  PAST: 'past',
};

export const JOURNAL_CHALLENGE_FILTERS = {
  ALL: 'all',
  WITHOUT_CHALLENGES: 'without-challenges',
  CURRENT_CHALLENGES: 'current-challenges',
};

export const DEFAULT_JOURNAL_QUERY_PARAMS = {
  sprintIds: [JOURNAL_CHALLENGE_FILTERS.ALL],
  challengeIds: [],
  withoutChallenges: false,
  isUnread: false,
  hasUserReplies: false,
};

export const FAIL_REASONS = {
  LACK_OF_TIME: 'lack_of_time',
  DID_NOT_PRIORITIZE: 'did_not_prioritize',
  UNEXPECTED_BLOCKER: 'unexpected_blocker',
  LACK_OF_CLARITY: 'lack_of_clarity',
  LACK_OF_FOCUS: 'lack_of_focus',
  LACK_OF_MOTIVATION: 'lack_of_motivation',
} as const;

export const FailReasonLabels: Record<FailReasonValue, string> = {
  [FAIL_REASONS.LACK_OF_TIME]: 'Lack of time',
  [FAIL_REASONS.DID_NOT_PRIORITIZE]: 'Did not prioritize',
  [FAIL_REASONS.UNEXPECTED_BLOCKER]: 'Unexpected blocker',
  [FAIL_REASONS.LACK_OF_CLARITY]: 'Lack of clarity',
  [FAIL_REASONS.LACK_OF_FOCUS]: 'Lack of focus',
  [FAIL_REASONS.LACK_OF_MOTIVATION]: 'Lack of motivation',
};

export const JOURNAL_NOTE_DEFAULT_VALUE = {
  noteType: JOURNAL_NOTE_TYPES.GENERAL,
  text: '',
  sprintId: '',
  challengeId: null,
  challengeFailReasons: [],
  challengeStatusDate: null,
  mediaIds: [],
  postToFeed: false,
  media: [],
};
