import { useRef } from 'react';

import { Flex, Typography } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

type FileUploadProps = {
  disabled?: boolean;
  onFileUpload: (files: File[]) => void;
  extensions?: string;
  multiple?: boolean;
  isImage?: boolean;
  dataTestid?: string;
  children?: React.ReactNode;
};

const FileUpload = ({
  onFileUpload,
  extensions,
  disabled = false,
  multiple = true,
  dataTestid,
  children,
}: FileUploadProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <Box as="label" style={{ cursor: disabled ? 'default' : 'pointer' }}>
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        data-testid={dataTestid}
      >
        {children ?? <FileUploadDefaultAnchor disabled={disabled} />}
      </Flex>
      <input
        disabled={disabled}
        hidden={true}
        accept={extensions}
        onChange={(e) => {
          const files = e.target.files;
          if (!files) {
            return;
          }

          onFileUpload(Array.from(files));
        }}
        type="file"
        ref={inputRef}
        onClick={handleInputClick}
        multiple={multiple}
      />
    </Box>
  );
};

export default FileUpload;

type TFileUploadDefaultAnchor = {
  disabled: boolean;
};

const FileUploadDefaultAnchor = ({ disabled }: TFileUploadDefaultAnchor) => (
  <>
    <Icon
      name={iconNames.paperClip}
      stroke={disabled ? 'var(--fgSecondary)' : 'var(--fgPrimary)'}
      strokeWidth={1}
    />
    <Spacer size="zr xsm" />
    <Typography
      color={disabled ? 'lightGray' : 'black'}
      fontWeight="medium"
      type="small"
    >
      Add attachments
    </Typography>
  </>
);
