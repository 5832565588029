import { useParams } from 'react-router-dom';

import { Button, Flex, Typography } from 'shared';

import { useJournalFilterContext } from 'features/journal/context';
import { useJournalMarkNotesAsReadMutation } from 'features/journal/hooks/mutation/useMarkNotesAsRead';
import { useParticipantQuery } from 'features/participants/hooks';

export const JournalFilterHeader = () => {
  const { email } = useParams();
  const { participant } = useParticipantQuery(email);

  const { resetQueryParams } = useJournalFilterContext();

  const { mutateAsync: markNotesAsRead } = useJournalMarkNotesAsReadMutation({
    participantId: participant.userId,
  });

  const handleMarkNotesAsRead = async () => await markNotesAsRead();

  return (
    <Flex width="100%">
      <Button onClick={handleMarkNotesAsRead} width="50%" size="lg">
        <Typography color="blue" type="meta" fontWeight="medium">
          Read all
        </Typography>
      </Button>
      <Button onClick={resetQueryParams} width="50%" size="lg">
        <Typography color="blue" type="meta" fontWeight="medium">
          Reset filters
        </Typography>
      </Button>
    </Flex>
  );
};
