import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const SprintFormEmptyState = () => (
  <Flex
    as="article"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    width="100%"
    height="100%"
  >
    <FlexItem width="23rem">
      <Spacer size="zr zr md">
        <header>
          <Spacer size="zr zr md">
            <Typography as="div" type="largeHeading" textAlign="center">
              👊
            </Typography>
          </Spacer>
          <Typography
            as="h1"
            type="body"
            fontWeight="semiBold"
            textAlign="center"
            color="black"
          >
            No challenges yet
          </Typography>
        </header>
      </Spacer>
    </FlexItem>
    <FlexItem>
      <Spacer size="zr zr lg">
        <Spacer size="zr zr md">
          <Typography as="p" color="gray" type="meta" textAlign="center">
            Create custom challenges or explore our library to add new ones.
          </Typography>
        </Spacer>
      </Spacer>
    </FlexItem>
  </Flex>
);

export default SprintFormEmptyState;
