import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';
import { useSidebarContext } from 'shared';

import {
  createTemporaryJournalReply,
  JournalNote,
  JournalNoteReply,
  useJournalQueryActions,
} from 'features/journal';
import { User } from 'features/user/User';

import { showErrorToast } from 'shared/components/Toast';

type TUseJournalCreateReplyMutation = { noteId: string };

export const useJournalCreateReplyMutation = ({
  noteId,
}: TUseJournalCreateReplyMutation) => {
  const { request } = useMutationHTTPRequest();
  const { user } = useSidebarContext() as { user: User };
  const queryClient = useQueryClient();

  const {
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
    updateJournalRelevantQueries,
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
  } = useJournalQueryActions();

  return useMutation(
    async ({ text }: { text: string }) =>
      request({
        url: `/api/journal/notes/${noteId}/replies/create`,
        body: { text },
      }),
    {
      onMutate: async ({ text }: { text: string }) => {
        const updatedReply = createTemporaryJournalReply({
          text,
          user,
          noteId,
        });

        cancelJournalNoteQueries(noteId);

        const previousJournalNoteData = getPreviousJournalNoteValue(noteId);

        updateJournalNoteQueryData(
          noteId,
          (draft: { note: JournalNote; replies: JournalNoteReply[] }) => {
            draft.replies = [...draft.replies, updatedReply];
          }
        );

        const relevantQueries = getJournalRelevantQueries();

        cancelJournalRelevantQueries();

        const previousJournalNotesData = relevantQueries.map(([key, data]) => ({
          key,
          data,
        }));

        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: page.notes.map((note: JournalNote) => {
              if (note.noteId === noteId) {
                note.repliesCount++;
              }

              return note;
            }),
          }));
        });

        return {
          previousJournalNoteData,
          previousJournalNotesData,
          temporaryReplyId: updatedReply.replyId,
        };
      },
      onSuccess: (newReply: JournalNoteReply, _, context) => {
        updateJournalNoteQueryData(
          noteId,
          (draft: { note: JournalNote; replies: JournalNoteReply[] }) => {
            draft.replies = draft.replies.map((reply) => {
              if (reply.replyId === context?.temporaryReplyId) {
                return newReply;
              }

              return reply;
            });
          }
        );
      },
      onError: (err, _, context) => {
        showErrorToast(
          "There's an error with creating reply. Please try again later."
        );

        logError(err);

        context?.previousJournalNotesData.forEach(({ key, data }: any) => {
          queryClient.setQueryData(key, data);
        });

        updateJournalNoteQueryData(
          noteId,
          () => () => context?.previousJournalNoteData
        );
      },
    }
  );
};
