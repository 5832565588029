import { useNavigate } from 'react-router-dom';

import { Box } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Nullable } from 'shared_DEPRECATED/types';

import { ChatUnreadIndicator } from 'features/chat/components/UnreadIndicator';
import {
  ParticipantsTableActionDisabled,
  ParticipantsTableActionWrapper,
} from 'features/participants/components/Table/Actions/';
import {
  ParticipantsTableLatestSprint,
  ParticipantsTableUserInfo,
} from 'features/participants/config/types';
import { useChatMessagesCountChange } from 'features/participants/hooks';

import { ButtonIcon, VARIANTS } from 'shared/components/Button';

type ParticipantsTableMessagesPropTypes = {
  userInfo: ParticipantsTableUserInfo;
  latestSprint: ParticipantsTableLatestSprint;
  chatId: Nullable<string>;
  unreadChatMessagesNumber: number;
  isChatMarkedAsUnread: boolean;
};

export const ParticipantsTableMessages = ({
  userInfo,
  latestSprint,
  chatId,
  unreadChatMessagesNumber,
  isChatMarkedAsUnread,
}: ParticipantsTableMessagesPropTypes) => {
  const { email: userEmail } = userInfo;

  const navigate = useNavigate();

  useChatMessagesCountChange({
    chatId,
    userEmail,
  });

  const handleMessageClick = () => {
    navigate(
      `/coach/participants/${userEmail}/${
        latestSprint?.sprintId || 'dashboard'
      }`,
      {
        state: {
          openChat: true,
        },
      }
    );
  };

  return chatId ? (
    <ButtonIcon
      ariaLabel="open chat"
      icon={
        <ParticipantsTableActionWrapper>
          <Box position="relative">
            <Icon
              name={iconNames.chat}
              stroke="var(--fgPrimary)"
              width={22}
              height={22}
            />
            <Box position="absolute" bottom="45%" left="40%">
              <ChatUnreadIndicator
                unreadMessagesCount={unreadChatMessagesNumber}
                isChatMarkAsUnread={isChatMarkedAsUnread}
              />
            </Box>
          </Box>
        </ParticipantsTableActionWrapper>
      }
      onClick={handleMessageClick}
      size="sm"
      variant={VARIANTS.TERTIARY}
      dataTestid="participant-messages-btn"
    />
  ) : (
    <ParticipantsTableActionDisabled
      icon={iconNames.chat}
      label="User has an inactive coaching status"
    />
  );
};
