import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';
import { useSidebarContext } from 'shared';

import {
  createTemporaryJournalReaction,
  JournalNote,
  JournalNoteReply,
  useJournalQueryActions,
} from 'features/journal';
import { User } from 'features/user/User';

import { showErrorToast } from 'shared/components/Toast';

export const useCreateJournalReplyReaction = () => {
  const { request } = useMutationHTTPRequest();
  const { user } = useSidebarContext() as { user: User };

  const {
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
  } = useJournalQueryActions();

  return useMutation(
    async ({ reaction, replyId }: MutationFnPropType) =>
      await request({
        url: `/api/journal/replies/${replyId}/reactions/create`,
        body: { reaction },
      }),
    {
      onMutate: ({ reaction, replyId, noteId }: MutationFnPropType) => {
        const updatedReaction = createTemporaryJournalReaction({
          user,
          reaction,
        });

        cancelJournalNoteQueries(noteId);

        const previousJournalNoteData = getPreviousJournalNoteValue(noteId);

        updateJournalNoteQueryData(
          noteId,
          (draft: { note: JournalNote; replies: JournalNoteReply[] }) => {
            draft.replies = draft.replies.map((reply) => {
              if (reply.replyId === replyId) {
                reply.reactions.push(updatedReaction);
              }

              return reply;
            });
          }
        );

        return { previousJournalNoteData };
      },
      onError: (err, { noteId, replyId }, context) => {
        showErrorToast(
          "There's an error with adding reaction. Please try again later."
        );

        updateJournalNoteQueryData(
          noteId,
          () => () => context?.previousJournalNoteData
        );
        logError(err);
      },

      onSuccess: (newReply: JournalNoteReply, { noteId }) => {
        updateJournalNoteQueryData(
          noteId,
          (draft: { note: JournalNote; replies: JournalNoteReply[] }) => {
            draft.replies = draft.replies.map((reply) => {
              if (reply.replyId === newReply.replyId) {
                return newReply;
              }

              return reply;
            });
          }
        );
      },
    }
  );
};

type MutationFnPropType = {
  reaction: string;
  replyId: string;
  noteId: string;
};
