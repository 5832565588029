import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import {
  SelectButton,
  SELECT_BUTTON_TYPES,
  SELECT_BUTTON_SIZES,
} from 'shared_DEPRECATED/components/Form/SelectButton';
import Loader from 'shared_DEPRECATED/components/Loader';
import { firstLetterUpperCase } from 'shared_DEPRECATED/utils';

import { useChallengeTargetUnitsQuery } from 'features/challenge/hooks/query/useTargetUnits';

export const ChallengeTargetAmountDialogUnitsField = () => {
  const { setValue, watch } = useFormContext();
  const targetUnit = watch('unit');
  const { data, isLoading } = useChallengeTargetUnitsQuery();

  useEffect(() => {
    if (data && !targetUnit) {
      setValue('unit', data[0].value);
    }
  }, [data, setValue, targetUnit]);

  if (isLoading) {
    return <Loader size="sm" />;
  }

  return (
    <Flex gap="1rem" flexWrap="wrap" justifyContent="flex-start">
      {data.map((unit: { value: string }) => (
        <SelectButton
          key={unit.value}
          checked={targetUnit === unit.value}
          value={unit.value}
          onChange={() => setValue('unit', unit.value)}
          type={SELECT_BUTTON_TYPES.SINGLE}
          size={SELECT_BUTTON_SIZES.SM}
        >
          <Flex>{firstLetterUpperCase(unit.value)}</Flex>
        </SelectButton>
      ))}
    </Flex>
  );
};
