import { For } from 'react-loops';

import { Flex, useSidebarContext } from 'shared';
import { ReactionEmojiPicker } from 'shared_DEPRECATED/components/Reactions';
import { DEFAULT_REACTIONS_OPTIONS } from 'shared_DEPRECATED/components/Reactions/config';

import {
  groupedReactionsByAuthor,
  JournalReaction,
  JournalCardReactionGroup,
} from 'features/journal';
import { User } from 'features/user/User';

type TJournalCardReactions = {
  reactions: JournalReaction[];
  onAddReaction: (reaction: string) => void;
  onRemoveReaction: (reactionId: number) => void;
  emojiPickerPosition?: 'start' | 'end';
};

export const JournalCardReactions = ({
  reactions,
  onAddReaction,
  onRemoveReaction,
  emojiPickerPosition = 'end',
}: TJournalCardReactions) => {
  const { user } = useSidebarContext() as { user: User };
  const groupedReactions = groupedReactionsByAuthor(reactions, user.userId);

  const myReactions = groupedReactions[user.userId]?.reactions ?? [];

  const handleReactionRemove = (updatedReactionId: number) => {
    const isMyReactionPresent = myReactions.some(
      ({ reactionId }) => reactionId === updatedReactionId
    );

    isMyReactionPresent && onRemoveReaction(updatedReactionId);
  };

  const handleReactionAdd = (updatedReaction: string) => {
    const isMyReactionPresent = myReactions.some(
      ({ reaction }) => reaction === updatedReaction
    );

    !isMyReactionPresent && onAddReaction(updatedReaction);
  };

  return (
    <Flex
      alignItems="flex-end"
      gap="4px"
      direction={emojiPickerPosition === 'start' ? 'row-reverse' : 'row'}
    >
      <Flex gap="4px">
        <For in={groupedReactions}>
          {({ author, isMy, reactions }) => (
            <JournalCardReactionGroup
              key={author.userId}
              author={author}
              reactions={reactions}
              onRemove={(reactionId) =>
                isMy && handleReactionRemove(reactionId)
              }
            />
          )}
        </For>
      </Flex>
      <ReactionEmojiPicker
        handleEmojiSelect={(reaction) => handleReactionAdd(reaction)}
        options={{
          ...DEFAULT_REACTIONS_OPTIONS,
          emojiSize: 20,
        }}
      />
    </Flex>
  );
};
