import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  CHALLENGE_FIELDS,
  CHALLENGE_SECTION_TITLES,
} from 'features/challenge/config';

import { Typography } from 'shared/components/Typography';

const SprintChallengesSectionPropTypes = {
  challengeField: PropTypes.oneOf(Object.values(CHALLENGE_FIELDS)).isRequired,
  children: PropTypes.node,
};

export const SprintChallengesSection = ({ challengeField, children }) => (
  <Box
    ariaLabel={CHALLENGE_SECTION_TITLES[challengeField]}
    dataTestid={`sprint-view-${
      CHALLENGE_SECTION_TITLES[challengeField].toLowerCase().split(' ')[0]
    }-challenges-section`}
  >
    <Typography
      color="gray"
      type="small"
      fontWeight="medium"
      dataTestid={`sprint-view-${
        CHALLENGE_SECTION_TITLES[challengeField].toLowerCase().split(' ')[0]
      }-challenges-section-title`}
    >
      {CHALLENGE_SECTION_TITLES[challengeField]}
    </Typography>
    <Spacer size="md zr" />
    {children}
    <Spacer size="zr zr lg" />
    <Spacer size="zr zr lg" />
  </Box>
);

SprintChallengesSection.propTypes = SprintChallengesSectionPropTypes;
