import { HStack, VStack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { When } from 'react-if';

import { Avatar, Checkbox, Flex, MenuItem, Typography } from 'shared';

import { EnrichedSubAccount } from 'features/subAccounts/types';

type SubAccountMenuItemProps = {
  account: EnrichedSubAccount;
  onSelect: (account: EnrichedSubAccount) => void;
};

export const SubAccountMenuItem = ({
  account,
  onSelect,
}: SubAccountMenuItemProps) => {
  const { name, isMain, isSelected, pictureUrl } = account;

  const handleClick = useCallback(() => onSelect(account), [onSelect, account]);

  return (
    <MenuItem onClick={handleClick} minH={12}>
      <HStack w="full">
        <Avatar name={name} src={pictureUrl} />
        <Flex justifyContent="space-between" w="full">
          <VStack alignItems="start" gap={0}>
            <Typography type="body" fontWeight="medium" color="black">
              {name}
            </Typography>
            {isMain && (
              <Typography type="xsm" color="blue">
                Main
              </Typography>
            )}
          </VStack>
          <When condition={isSelected}>
            <Checkbox isChecked variant="rounded" dataTestid="isSelected" />
          </When>
        </Flex>
      </HStack>
    </MenuItem>
  );
};
