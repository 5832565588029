import { ButtonGroup } from 'shared';

import {
  JournalNotesBody,
  JournalFilterProvider,
  JournalProvider,
  JournalFilter,
} from 'features/journal';

import {
  SidebarHeaderWithoutBorder,
  useSidebarContext,
} from 'shared/components/Sidebar';

export const JournalNotes = () => {
  const { filters } = useSidebarContext();

  return (
    <JournalFilterProvider initialFilters={filters}>
      <JournalProvider>
        <SidebarHeaderWithoutBorder title="Journal">
          <ButtonGroup size="xs">
            <JournalFilter />
          </ButtonGroup>
        </SidebarHeaderWithoutBorder>
        <JournalNotesBody />
      </JournalProvider>
    </JournalFilterProvider>
  );
};
