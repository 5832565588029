import { MouseEventHandler } from 'react';

import { ButtonIcon, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

type PrioritizeButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isPrioritized: boolean;
  size?: 'xs' | 'md';
  dataTestid?: string;
};

export const GoalPrioritizeToggleButton = ({
  onClick,
  isPrioritized,
  dataTestid,
  size = 'xs',
}: PrioritizeButtonProps) => {
  const colors = getColors(isPrioritized);

  return (
    <ButtonIcon
      dataTestid={dataTestid}
      ariaLabel="prioritize goal"
      icon={
        <Icon
          name={iconNames.flagNew}
          fill={colors.fill}
          stroke={colors.stroke}
        />
      }
      onClick={onClick}
      size={size}
      variant={VARIANTS.SECONDARY}
    />
  );
};

const getColors = (isPrioritized: boolean) =>
  isPrioritized
    ? {
        fill: 'var(--fgInteractive)',
        stroke: 'var(--fgInteractive)',
      }
    : {
        fill: 'var(--white)',
        stroke: '#5F6368',
      };
