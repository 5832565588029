import { Flex } from 'shared_DEPRECATED/components/Flex';

import { MOMENTUM_DEFAULT_SCORE } from 'features/momentum/config';
import { getMomentumConfig } from 'features/momentum/utils';

import { Badge } from 'shared/components/Badge';
import { Typography, TYPOGRAPHY_COLORS } from 'shared/components/Typography';

type MomentumTrendPropsType = { currentScore: number; previousScore?: number };

export const MomentumTrend = ({
  currentScore,
  previousScore = MOMENTUM_DEFAULT_SCORE,
}: MomentumTrendPropsType) => {
  const scoreDiff = currentScore - previousScore;

  const { sign, textColor } = getMomentumConfig(scoreDiff);

  return scoreDiff !== 0 ? (
    <Badge
      as="div"
      size="xsm"
      bgColor={getBadgeColor(scoreDiff)}
      dataTestid="momentum-trend-badge"
    >
      <Flex
        justifyContent="flex-start"
        gap="var(--spacing01)"
        alignItems="center"
      >
        <Typography type="small" color={textColor as TYPOGRAPHY_COLORS}>
          {sign}
          {Math.abs(scoreDiff).toFixed(1)}
        </Typography>
      </Flex>
    </Badge>
  ) : null;
};

const getBadgeColor = (scoreDiff: number) => {
  if (scoreDiff === 0) {
    return 'lightGray';
  }

  if (scoreDiff > 0) {
    return 'lightGreen';
  }

  if (scoreDiff < 0) {
    return 'lightRed';
  }
};
