import {
  Tag as ChakraTag,
  TagLabel, // TagLeftIcon,
  // TagRightIcon,
  TagCloseButton,
  TagProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

// import { iconNames } from 'shared/components/Icon';
import { Nullable } from 'shared_DEPRECATED/types';

export const TAG_SIZES = {
  XSM: 'xsm',
  SM: 'sm',
  MD: 'md',
} as const;

export type TAG_COLORS =
  | 'lightOrange'
  | 'lightPurple'
  | 'lightBlue'
  | 'lightYellow'
  | 'lightRed'
  | 'lightGray'
  | 'lightGreen'
  | 'blue'
  | 'gray'
  | 'darkGray'
  | 'green'
  | 'orange'
  | 'red'
  | 'black'
  | 'white';

type TTag = TagProps & {
  size?: typeof TAG_SIZES[keyof typeof TAG_SIZES];
  bgColor: TAG_COLORS;
  label?: ReactNode;
  onCloseButtonClick?: () => void;
  children?: ReactNode;
  withCloseButton?: boolean;
  dataTestid?: Nullable<string>;
  //   rightIcon: keyof typeof iconNames;
  //   leftIcon: keyof typeof iconNames;
};

// TODO: HOS-4097 - Add support for TagLeftIcon and TagRightIcon
// Provide TagLeftIcon and TagRightIcon once the Icon component is ready.
// Jira Task: https://hosinc.atlassian.net/browse/HOS-4097

export const Tag = ({
  label,
  size,
  bgColor,
  onCloseButtonClick,
  children,
  withCloseButton = true,
  dataTestid = null,
  //   rightIcon,
  //   leftIcon,
  ...props
}: TTag) => (
  <ChakraTag size={size} variant={bgColor} data-testid={dataTestid} {...props}>
    {children}
    {/* {!!leftIcon && <TagLeftIcon as={} />} */}
    <TagLabel>{label}</TagLabel>
    {/* {!!rightIcon && <TagRightIcon as={} />} */}
    {withCloseButton && <TagCloseButton onClick={onCloseButtonClick} />}
  </ChakraTag>
);
