import { Box } from '@chakra-ui/react';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { useState } from 'react';
import { When } from 'react-if';

import { LexicalEditor } from 'lexical';
import { logError } from 'lib/sentry/logError';

import { Placeholder } from 'shared/components/TextEditor/components/Placeholder';

import { ToolbarPlugin } from './ToolbarPlugin';
import { UndoRedoButtons } from './components/UndoRedo/UndoRedoButtons';
import { namespace, TextEditorFormats, TOOLBAR_HEIGHT } from './constants';
import { editorNodes } from './nodes/editorNodes';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import { CodeHighlightPlugin } from './plugins/CodeHighlightPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin/FloatingTextFormatToolbarPlugin';
import { ImagePlugin, INSERT_IMAGE_COMMAND } from './plugins/ImagePlugin';
import { ListMaxIndentLevelPlugin } from './plugins/ListMaxIndentLevelPlugin';
import { ShortcutPlugin } from './plugins/ShortcutPlugin';
import { TextEditorDragAndDrop } from './plugins/TextEditorDragAndDrop';
import { TextEditorOnChangePlugin } from './plugins/TextEditorOnChangePlugin';
import './textEditorStyles.css';
import { theme } from './themes/textEditorTheme';
import { TextEditorFormat } from './types';
import { prepareEditorState } from './utils';

export type TextEditorProps = {
  value: string;
  onChange: (value: string) => void;
  additionalToolbarActions?: React.ReactNode;
  format?: TextEditorFormat;
  editorStatePreparer?: typeof prepareEditorState;
  placeholder?: string;
  showTextSelectionTooltip?: boolean;
  onChangePlugin?: React.ReactNode;
};

export const TextEditor = ({
  value,
  onChange,
  additionalToolbarActions,
  format = TextEditorFormats.html,
  editorStatePreparer = prepareEditorState,
  placeholder = 'Type here',
  showTextSelectionTooltip = true,
  onChangePlugin,
}: TextEditorProps) => {
  const initialConfig: InitialConfigType = {
    namespace,
    onError: (error: Error) => {
      logError(error);
    },
    editorState: editorStatePreparer({
      value,
    }),
    theme,
    nodes: [...editorNodes],
  };

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<
    HTMLDivElement | undefined
  >(undefined);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const handleDrop = (editor: LexicalEditor, file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
        altText: 'image',
        src: reader.result as string,
      });
    };
    reader.readAsDataURL(file);
  };

  const textOnChangePlugin = onChangePlugin || (
    <TextEditorOnChangePlugin onChange={onChange} format={format} />
  );

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <LexicalErrorBoundary
        onError={(error: Error) => {
          logError(error);
        }}
      >
        <>
          <HistoryPlugin />
          <ToolbarPlugin
            setIsLinkEditMode={setIsLinkEditMode}
            additionalActions={additionalToolbarActions}
          >
            <UndoRedoButtons />
          </ToolbarPlugin>
          <Box
            position="relative"
            style={{ height: `calc(100% - ${TOOLBAR_HEIGHT}px)` }}
          >
            <RichTextPlugin
              placeholder={<Placeholder>{placeholder}</Placeholder>}
              contentEditable={
                <Box
                  className={`${namespace}__container`}
                  border="var(--border-secondary)"
                  ref={onRef}
                >
                  <TextEditorDragAndDrop onDrop={handleDrop}>
                    <ContentEditable
                      className={`${namespace}__content`}
                      data-testid="text-editor-content"
                    />
                  </TextEditorDragAndDrop>
                </Box>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <When condition={showTextSelectionTooltip}>
              <FloatingTextFormatToolbarPlugin
                anchorElem={floatingAnchorElem}
                setIsLinkEditMode={setIsLinkEditMode}
              />
            </When>
            <FloatingLinkEditorPlugin
              anchorElem={floatingAnchorElem}
              isLinkEditMode={isLinkEditMode}
              setIsLinkEditMode={setIsLinkEditMode}
            />
          </Box>
          <ListPlugin />
          {textOnChangePlugin}
          <CheckListPlugin />
          <ListMaxIndentLevelPlugin />
          <CodeHighlightPlugin />
          <AutoLinkPlugin />
          <LinkPlugin />
          <ImagePlugin />
          <TabIndentationPlugin />
          <ShortcutPlugin />
        </>
      </LexicalErrorBoundary>
    </LexicalComposer>
  );
};
