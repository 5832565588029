import { useEffect } from 'react';

// https://github.com/recharts/recharts/issues/3615
export const TurnOffDefaultPropsWarning: React.FC = () => {
  useEffect(() => {
    const originalConsoleError = console.error;

    console.error = (...args: any[]) => {
      if (typeof args[0] === 'string' && /defaultProps/.test(args[0])) {
        return;
      }

      originalConsoleError(...args);
    };

    return () => {
      console.error = originalConsoleError;
    };
  }, []);

  return null;
};
