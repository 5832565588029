import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';

import { ParticipantCoachingModeBadge } from 'features/participants/components/CoachingModeBadge';
import { CoachingMode } from 'features/participants/config/types';
import { UserInfoName, UserInfoTimezone } from 'features/user/components/Info/';

type UserInfoWithTitleProps = {
  name: string;
  pictureUrl: string;
  coachingMode: CoachingMode;
  timezone: string;
};

export const ParticipantInfoWithCoachingStatus = ({
  pictureUrl,
  name,
  coachingMode,
  timezone,
}: UserInfoWithTitleProps) => (
  <HotjarSuppression>
    <Flex
      as="span"
      justifyContent="flex-start"
      alignItems="center"
      gap="var(--spacing02)"
    >
      <Avatar name={name} size={SIZES.LG} src={pictureUrl} />
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        gap="var(--spacing01)"
        dataTestid="sidebar-participant-info"
      >
        <UserInfoName maxWidth="10rem">{name}</UserInfoName>
        <ParticipantCoachingModeBadge mode={coachingMode} />
        <UserInfoTimezone timezone={timezone} />
      </Flex>
    </Flex>
  </HotjarSuppression>
);
