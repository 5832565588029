import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useCreateGoalChallenge } from 'features/goal';

import { ButtonIcon, VARIANTS } from 'shared/components/Button';

type TAddChallengeButton = {
  focusAreaId: string;
};

export const AddChallengeButton = ({ focusAreaId }: TAddChallengeButton) => {
  const { onCreateGoalChallengeBtnClick } = useCreateGoalChallenge({
    focusAreaId,
  });

  return (
    <ButtonIcon
      ariaLabel="add challenge"
      icon={<Icon width={15} height={15} name={iconNames.plus} />}
      onClick={onCreateGoalChallengeBtnClick}
      size="md"
      variant={VARIANTS.TERTIARY}
    />
  );
};
