import { useColorMode } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { Box } from 'shared';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { COACHING_EXTERNAL_TOOL } from 'features/sessions/config';
import { CoachingTool } from 'features/sessions/config/types';

import { ButtonIcon } from 'shared/components/Button';
import { Typography } from 'shared/components/Typography';

import { NextSessionFormMiroBoardLinkView } from './MiroBoardLinkField/Link/';

type NextSessionCoachingToolsCardViewProps = {
  formKey: string;
  onDelete: () => void;
  editMode?: boolean;
  coachingTool: CoachingTool;
};
export const NextSessionCoachingToolsCardView = ({
  formKey,
  onDelete,
  coachingTool,
}: NextSessionCoachingToolsCardViewProps) => {
  const { setValue } = useFormContext();
  const { colorMode: theme } = useColorMode();
  const isThemeLight = theme === 'light';

  const { tool, unlistedToolName, link } = coachingTool;

  return (
    <>
      <FlexItem alignSelf="stretch" width="100%">
        <Flex justifyContent="space-between">
          <Typography type="body" fontWeight="semiBold" color="black">
            {tool.label}
          </Typography>
          <Flex gap="var(--spacing04)">
            <ButtonIcon
              ariaLabel="edit tool"
              onClick={() => setValue(`${formKey}.editMode`, true)}
              icon={<Icon name={iconNames.pen} />}
            />
            <ButtonIcon
              ariaLabel="delete tool"
              onClick={onDelete}
              icon={<Icon name={iconNames.bin} />}
            />
          </Flex>
        </Flex>
      </FlexItem>
      {tool.value === COACHING_EXTERNAL_TOOL && unlistedToolName && (
        <>
          <FlexItem alignSelf="stretch">
            <Typography
              type="small"
              color={isThemeLight ? 'gray' : 'black'}
              fontWeight="semiBold"
            >
              External session tool name
            </Typography>
          </FlexItem>
          <FlexItem alignSelf="stretch">
            <Typography type="small" color="black">
              {unlistedToolName}
            </Typography>
          </FlexItem>
        </>
      )}
      <Box marginRight="auto">
        {link && <NextSessionFormMiroBoardLinkView text={link} />}
      </Box>
    </>
  );
};
