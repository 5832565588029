import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { SubAccount, SubAccountsResponse } from '../../types';

export const useSubAccountsQuery = () => {
  const { request } = useQueryHTTPRequest();

  return useQuery<SubAccountsResponse, unknown, SubAccount[]>(
    [queryKeys.subAccounts],
    () => request({ url: '/api/sub-accounts' }),
    {
      initialData: { items: [] },
      select: (data) => data.items,
      refetchOnWindowFocus: false,
    }
  );
};
