import { For } from 'react-loops';

import { useSubAccounts } from 'features/subAccounts/hooks/useSubAccounts';

import { SubAccountMenuItem } from './SubAccountMenuItem';

export const SubAccountsMenu = () => {
  const { subAccounts, handleSubAccountSelect } = useSubAccounts();

  return (
    <For of={subAccounts}>
      {(account) => (
        <SubAccountMenuItem
          key={account.userId}
          onSelect={handleSubAccountSelect}
          account={account}
        />
      )}
    </For>
  );
};
