import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { useOptionsMenuContext } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const OptionsMenuButtonPropTypes = {
  /**
   * The content of the menu button.
   * @type {node}
   * @required
   * @memberof OptionsMenuButtonPropTypes
   * @name children
   * @example <OptionsMenuButton>Menu Button</OptionsMenuButton>
   * @example <OptionsMenuButton><Icon name="icon-name" /></OptionsMenuButton>
   */
  children: PropTypes.node.isRequired,
  /**
   * Button click handler.
   * @type {function}
   * @required
   * @memberof OptionsMenuButtonPropTypes
   * @name onClick
   * @example <OptionsMenuButton onClick={() => {}} />
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Optional data-testid attribute for testing.
   * @type {string}
   * @memberof OptionsMenuButtonPropTypes
   * @name dataTestid
   * @example <OptionsMenuButton dataTestid="menu-button" />
   */
  dataTestid: PropTypes.string,
};

const OptionsMenuButton = ({ children, onClick, dataTestid = null }) => {
  const [, toggleOpen] = useOptionsMenuContext();

  return (
    <Button
      onClick={(e) => {
        e.preventDefault();

        onClick();
        toggleOpen();
      }}
      variant="tertiary"
      dataTestid={dataTestid}
    >
      <Box
        style={{
          minWidth: '5rem',
        }}
      >
        <Spacer size="md">
          <Flex alignItems="center" justifyContent="flex-start">
            <Typography type="small" cropped={true} color="black" as="span">
              {children}
            </Typography>
          </Flex>
        </Spacer>
      </Box>
    </Button>
  );
};

OptionsMenuButton.propTypes = OptionsMenuButtonPropTypes;

export default OptionsMenuButton;
