import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

const SortableListItemPropTypes = {
  /**
   * Tag name of list item.
   * @type {string}
   * @memberof SortableListItemPropTypes
   * @name as
   * @default li
   * @example <SortableListItem as="div" />
   */
  as: PropTypes.string,
  /**
   * Item content.
   * @type {React.ReactNode}
   * @memberof SortableListPropTypes
   * @required
   * @name children
   * @example <SortableListItem><p>Item content</p></SortableListItem>
   */
  children: PropTypes.node.isRequired,
  dataTestid: PropTypes.string,
};

export const SortableListItem = ({
  children,
  dataTestid = null,
  as = 'li',
}) => (
  <Box as={as} data-testid={dataTestid}>
    <Spacer size="zr md">
      <Flex gap="var(--spacing03)" alignItems="center">
        <Box style={{ cursor: 'grab' }} className="drag-handler">
          <Icon name={iconNames.dragHandle} />
        </Box>
        <FlexItem flexGrow={1}>{children}</FlexItem>
      </Flex>
    </Spacer>
  </Box>
);

SortableListItem.propTypes = SortableListItemPropTypes;
