import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  ReactNode,
} from 'react';

import {
  JournalQueryParamsType,
  DEFAULT_JOURNAL_QUERY_PARAMS,
  JOURNAL_CHALLENGE_FILTERS,
} from 'features/journal';

type JournalFilterContextType = {
  queryParams: JournalQueryParamsType;
  onQueryParamsChange: (updatedParams: Partial<JournalQueryParamsType>) => void;
  resetQueryParams: () => void;
  isFilterApplied: boolean;
};

type JournalFilterProviderProps = {
  children: ReactNode;
  initialFilters: JournalQueryParamsType;
};

const JournalFilterContext = createContext<
  JournalFilterContextType | undefined
>(undefined);

export const JournalFilterProvider = ({
  children,
  initialFilters,
}: JournalFilterProviderProps) => {
  const [queryParams, setQueryParams] = useState<JournalQueryParamsType>(
    initialFilters || DEFAULT_JOURNAL_QUERY_PARAMS
  );

  const onQueryParamsChange = useCallback(
    (updatedParams: Partial<JournalQueryParamsType>) => {
      setQueryParams((prev) => ({ ...prev, ...updatedParams }));
    },
    []
  );

  const resetQueryParams = useCallback(
    () => setQueryParams(DEFAULT_JOURNAL_QUERY_PARAMS),
    []
  );

  const isFilterApplied = useMemo(
    () => !areQueryParamsDefault(queryParams),
    [queryParams]
  );
  const providerValue = useMemo(
    () => ({
      queryParams,
      onQueryParamsChange,
      resetQueryParams,
      isFilterApplied,
    }),
    [queryParams, onQueryParamsChange, resetQueryParams, isFilterApplied]
  );

  return (
    <JournalFilterContext.Provider value={providerValue}>
      {children}
    </JournalFilterContext.Provider>
  );
};

export const useJournalFilterContext = () => {
  const context = useContext(JournalFilterContext);

  if (!context) {
    throw new Error(
      'useJournalFilterContext must be used within JournalFilterProvider'
    );
  }

  return context;
};

const areQueryParamsDefault = (queryParams: JournalQueryParamsType): boolean =>
  queryParams.sprintIds[0] === JOURNAL_CHALLENGE_FILTERS.ALL &&
  queryParams.sprintIds.length ===
    DEFAULT_JOURNAL_QUERY_PARAMS.sprintIds.length &&
  queryParams.challengeIds.length ===
    DEFAULT_JOURNAL_QUERY_PARAMS.challengeIds.length &&
  queryParams.withoutChallenges ===
    DEFAULT_JOURNAL_QUERY_PARAMS.withoutChallenges &&
  queryParams.isUnread === DEFAULT_JOURNAL_QUERY_PARAMS.isUnread &&
  queryParams.hasUserReplies === DEFAULT_JOURNAL_QUERY_PARAMS.hasUserReplies;
