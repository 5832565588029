import { useOutletContext, useParams } from 'react-router-dom';

import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { SessionsModalContent } from 'features/sessions/components/Modal';
import { SprintsType } from 'features/sprint/config/types';

import { Button, VARIANTS } from 'shared/components/Button';
import { modal, SIZES } from 'shared/components/Modal';

type TCoachSessionsCreateButton = { nextSessionStartDate?: string };

export const CoachSessionsCreateButton = ({
  nextSessionStartDate,
}: TCoachSessionsCreateButton) => {
  const { sprints } = useOutletContext<{ sprints: SprintsType }>();
  const { email: userEmail } = useParams();
  const twoRecentSprints = sprints.slice(0, 2);

  const handleModalOpen = () => {
    modal.open({
      content: <SessionsModalContent key={userEmail} />,
      props: { size: SIZES.SM, title: 'Create session modal' },
      context: { sprints: twoRecentSprints, nextSessionStartDate },
    });
  };

  return (
    <Button
      dataTestid="add-new-session-btn"
      leftIcon={
        <Icon
          width={10}
          height={10}
          name={iconNames.plus}
          stroke="var(--fgPrimaryInverse)"
        />
      }
      variant={VARIANTS.PRIMARY}
      onClick={handleModalOpen}
    >
      Add new session
    </Button>
  );
};
