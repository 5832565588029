import { useFormContext } from 'react-hook-form';

import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { ModalButton } from 'shared/components/Modal/ModalButton';

type TJournalAddNoteDialogFooter = {
  onSubmit: () => void;
};

export const JournalAddNoteDialogFooter = ({
  onSubmit,
}: TJournalAddNoteDialogFooter) => {
  const { formState } = useFormContext();

  return (
    <Flex gap="8px">
      <ModalButton variant={VARIANTS.SECONDARY}>Cancel</ModalButton>
      <ModalButton
        onClick={onSubmit}
        shouldClose={formState.isValid}
        variant={VARIANTS.PRIMARY}
        dataTestid="goal-done-btn"
      >
        Save
      </ModalButton>
    </Flex>
  );
};
