import { useMySprintsQuery } from 'features/sprint/hooks/query/useMySprints';
import { useParticipantsSprintsQuery } from 'features/sprint/hooks/query/useParticipantsSprints';
import { useSharedSprintsQuery } from 'features/sprint/hooks/query/useSharedSprints';
import { USER_MODES } from 'features/user/config';
import { useUserMode } from 'features/user/hooks';

export const useSprints = (email) => {
  const userMode = useUserMode();

  const participantSprintsQueryData = useParticipantsSprintsQuery(email, {
    enabled: userMode === USER_MODES.coach || userMode === USER_MODES.admin,
  });
  const mySprintsQueryData = useMySprintsQuery({
    enabled: userMode === USER_MODES.dashboard,
  });
  const sharedQueryData = useSharedSprintsQuery(email, {
    enabled: userMode === USER_MODES.users,
  });

  const sprintDataMap = {
    [USER_MODES.coach]: participantSprintsQueryData,
    [USER_MODES.admin]: participantSprintsQueryData,
    [USER_MODES.dashboard]: mySprintsQueryData,
    [USER_MODES.users]: sharedQueryData,
  };

  return sprintDataMap[userMode];
};
