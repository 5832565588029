import { useFormContext } from 'react-hook-form';

import { FormControl, FormLabel, Textarea } from 'shared';

const DescriptionField = () => {
  const { register } = useFormContext();

  return (
    <FormControl>
      <FormLabel>Notes & Tips</FormLabel>
      <Textarea
        placeholder="Description"
        {...register('description')}
        data-testid="challenge-dialog-description-field"
      />
    </FormControl>
  );
};

export default DescriptionField;
