import PropTypes from 'prop-types';

import { Button, VARIANTS } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import FieldLabel from 'shared_DEPRECATED/components/Form/FieldLabel';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  WeekdayTargetType,
  WeeklyTargetType,
} from 'features/challenge/config/propTypes';
import { useGetChallengeFrequencyText } from 'features/challenge/hooks/useGetFrequencyText';

const FrequencyFieldPropTypes = {
  challengeTarget: PropTypes.oneOfType([WeekdayTargetType, WeeklyTargetType])
    .isRequired,
  onFieldClick: PropTypes.func.isRequired,
  sprintWeeksCount: PropTypes.number,
};

export const FrequencyField = ({
  challengeTarget,
  onFieldClick,
  sprintWeeksCount,
}) => {
  const getChallengeFrequencyText = useGetChallengeFrequencyText();

  return (
    <Flex>
      <FieldLabel>Frequency</FieldLabel>
      <Button
        leftIcon={<Icon name={iconNames.calendar} width={12} height={14} />}
        rightIcon={<Icon name={iconNames.chevronDown} width={6} height={9} />}
        onClick={onFieldClick}
        variant={VARIANTS.TERTIARY}
        dataTestid="challenge-dialog-frequency-field"
        size="sm"
      >
        {getChallengeFrequencyText(challengeTarget, sprintWeeksCount)}
      </Button>
    </Flex>
  );
};

FrequencyField.propTypes = FrequencyFieldPropTypes;
