import { notificationType } from 'app/notifications/config';

export const getNotificationText = (notification) => {
  const notificationText = {
    editor:
      notification.editorName ||
      notification.sharerName ||
      notification.sharerEmail,
    action: 'edited',
    title: notification.challengeTitle || notification.sprintTitle,
  };

  switch (notification.type) {
    case notificationType.SPRINT_CHALLENGE_ADDED:
      notificationText.action = 'added';
      break;
    case notificationType.SPRINT_CHALLENGE_REMOVED:
      notificationText.action = 'removed';
      break;
    case notificationType.SPRINT_CHALLENGE_FREQUENCY_CHANGED:
      notificationText.action = 'changed frequency in';
      break;
    case notificationType.SPRINT_SHARED:
      notificationText.action = 'shared a sprint with you';
      notificationText.title = notification.sprintTitle;
      break;
    case notificationType.SPRINT_TITLE_CHANGED:
    case notificationType.SPRINT_CHALLENGE_TITLE_CHANGED:
      notificationText.action = 'edited';
      notificationText.title = notification.after;
      break;
    case notificationType.CHALLENGE_NOTE_CREATED:
      notificationText.action = 'left note in';
      break;
    case notificationType.CHALLENGE_NOTE_REPLY_ADDED:
      notificationText.action = 'replied to';
      break;
    case notificationType.DIMENSIONS_ASSESSMENT_ADDED:
      notificationText.action = notification.sprintTitle
        ? 'had taken a new assessment to'
        : 'had taken a new assessment';
      notificationText.title = notification.sprintTitle;
      break;
    case notificationType.COACH_ASSIGNED_TO_CLIENT:
      notificationText.editor =
        notification.clientName || notification.clientEmail;
      notificationText.action = 'has been added to your participant list';
      break;
    case notificationType.COACH_REMOVED_FROM_CLIENT:
      notificationText.editor =
        notification.clientName || notification.clientEmail;
      notificationText.action = 'is no longer on your participant list';
      break;
    default:
      notificationText.action = 'edited';
  }

  return notificationText;
};

export const getNotificationUserInfo = (notification) => {
  switch (notification.type) {
    case notificationType.COACH_ASSIGNED_TO_CLIENT:
    case notificationType.COACH_REMOVED_FROM_CLIENT:
      return {
        name: notification.clientName,
        email: notification.clientEmail,
        picture: notification.clientPicture,
      };
    case notificationType.SPRINT_TITLE_CHANGED:
    case notificationType.SPRINT_CHALLENGE_REMOVED:
    case notificationType.SPRINT_CHALLENGE_ADDED:
    case notificationType.SPRINT_CHALLENGE_FREQUENCY_CHANGED:
      return {
        name: notification.editorName,
        email: notification.editorEmail,
        picture: notification.editorPicture,
      };
    case notificationType.DIMENSIONS_ASSESSMENT_ADDED:
    case notificationType.CHALLENGE_NOTE_REPLY_ADDED:
    case notificationType.CHALLENGE_NOTE_CREATED:
    case notificationType.SPRINT_SHARED:
    default:
      return {
        name: notification.sharerName,
        email: notification.sharerEmail,
        picture: notification.sharerPicture,
      };
  }
};

export const isValidNotification = (notification) => {
  return Object.values(notificationType).includes(notification.type);
};
