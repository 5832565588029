import { KeyboardEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import { Box } from 'shared';

type TJournalFormTextInput = {
  handleKeyDown: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholderText: string;
};

export const JournalFormTextInput = ({
  handleKeyDown,
  placeholderText = 'Write a reply...',
}: TJournalFormTextInput) => {
  const { register } = useFormContext();

  return (
    <Box position="relative" w="100%">
      <TextareaAutosize
        placeholder={placeholderText}
        //@ts-ignore
        style={styles}
        maxRows={3}
        onFocus={(e) => (e.target.style.outline = 'none')}
        onKeyDown={handleKeyDown}
        {...register('text', {
          required: 'Text is required',
          setValueAs: (value) => value.trim(),
        })}
      />
    </Box>
  );
};

const styles = {
  borderRadius: '24px',
  width: '100%',
  display: 'block',
  padding: '12px 32px 12px 16px',
  fontWeight: '400',
  fontSize: 'var(--font-size-large)',
  lineHeight: '22px',
  boxSizing: 'border-box',
  border: 'none',
  resize: 'none',
  color: 'var(--bgCompPrimaryInverse)',
  background: 'var(--fgPrimaryInverse)',
};
