import React, { ReactNode, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { ButtonIcon, Flex, VARIANTS, Box } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { JournalFormTextInput } from 'features/journal';

type TJournalForm = {
  text?: string;
  placeholderText: string;
  onSave: ({ text }: { text: string }) => Promise<void>;
  children?: ReactNode;
};

export const JournalForm = ({
  text = '',
  onSave,
  placeholderText,
  children,
}: TJournalForm) => {
  const methods = useForm({
    defaultValues: { text },
    mode: 'onChange',
  });

  const { handleSubmit, setValue, reset, formState, control } = methods;

  const currentText = useWatch({ control: control, name: 'text' });

  const isTextPresent = Boolean(currentText.length);

  const handleSave = (formValues: { text: string }) => {
    if (formState.errors.text) return;
    onSave({ text: formValues.text });
    reset();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(handleSave)();
    }
  };

  useEffect(() => {
    setValue('text', text);
  }, [text, setValue]);

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <FormProvider {...methods}>
        <Box>
          {children}
          <Flex alignItems="flex-end" gap="4px">
            <Box flex="1">
              <JournalFormTextInput
                handleKeyDown={handleKeyDown}
                placeholderText={placeholderText}
              />
            </Box>
            <ButtonIcon
              ariaLabel="send reply"
              icon={
                <Icon
                  name={iconNames.paperPlane}
                  stroke={
                    !isTextPresent
                      ? 'var(--fgPrimary)'
                      : 'var(--fgPrimaryInverse)'
                  }
                  height={18}
                  width={18}
                />
              }
              type="submit"
              variant={VARIANTS.PRIMARY}
              disabled={!isTextPresent}
              dataTestid="journal-form-submit"
              isRound
              style={{
                height: '48px',
                width: '48px',
              }}
            />
          </Flex>
        </Box>
      </FormProvider>
    </form>
  );
};
