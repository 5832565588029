import { useFormContext, useWatch } from 'react-hook-form';

export const useProofFieldConfig = () => {
  const { setValue } = useFormContext();

  const proofRequired = useWatch({ name: 'proofRequired' });

  const handleProofRequiredChange = () => {
    setValue('proofRequired', !proofRequired);
  };

  return {
    onChange: handleProofRequiredChange,
    checked: proofRequired,
  };
};
