import PropTypes from 'prop-types';

import { DatePicker } from 'shared_DEPRECATED/components/Form/DatePicker';
import { SIZES } from 'shared_DEPRECATED/components/Form/DatePicker/config';
import { iconNames } from 'shared_DEPRECATED/components/Icon';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { Typography } from 'shared/components/Typography';

const DueDateFieldPropTypes = {
  dueDate: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  startDate: PropTypes.string,
};

export const DueDateField = ({
  dueDate,
  onDateChange,
  startDate = dateUtils(),
}) => {
  return (
    <Typography
      as="div"
      type="meta"
      dataTestid="challenge-dialog-due-date-field"
    >
      <DatePicker
        selected={dateUtils(dueDate).toDate()}
        onChange={onDateChange}
        anchorIcon={iconNames.calendar}
        minDate={dateUtils(startDate).toDate()}
        placeholderText="Select due date"
        size={SIZES.SM}
      />
    </Typography>
  );
};

DueDateField.propTypes = DueDateFieldPropTypes;
