import { USER_TYPES } from 'shared_DEPRECATED/types/User';

import { useUserMode } from 'features/user/hooks';

import { Typography } from 'shared/components/Typography';

export const SprintNavigationEmpty = () => {
  const userMode = useUserMode();

  return (
    <Typography type="small" fontWeight="regular" color="gray">
      👋 Explore challenges in our library in hOS app and create sprint
      {userMode === USER_TYPES.COACH &&
        ` with
      user`}
      .
    </Typography>
  );
};
