import { useFormContext } from 'react-hook-form';

import { FormControl, FormErrorMessage, FormLabel, TextInput } from 'shared';
import { useFormFieldError } from 'shared_DEPRECATED/hooks';

const TitleField = () => {
  const { register } = useFormContext();
  const titleValidationError = useFormFieldError('title');

  return (
    <FormControl isInvalid={titleValidationError} isRequired={true}>
      <FormLabel>Challenge Title</FormLabel>
      <TextInput
        placeholder="Title"
        {...register('title', {
          required: { value: true, message: 'This field is required' },
        })}
        dataTestid="challenge-dialog-title-field"
        size="sm"
      />
      <FormErrorMessage data-testid="challenge-dialog-title-field-validation">
        {titleValidationError?.message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default TitleField;
