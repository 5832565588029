import { useMutation } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import {
  JournalNote,
  JournalNoteReply,
  useJournalQueryActions,
} from 'features/journal';

import { showErrorToast } from 'shared/components/Toast';

type TUseJournalDeleteReplyMutation = {
  noteId: string;
};

export const useJournalDeleteReplyMutation = ({
  noteId,
}: TUseJournalDeleteReplyMutation) => {
  const { request } = useMutationHTTPRequest();

  const {
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
  } = useJournalQueryActions();

  return useMutation(
    async (replyId) =>
      await request({
        url: `/api/journal/replies/${replyId}/delete`,
      }),
    {
      onMutate: async (replyId: string) => {
        cancelJournalNoteQueries(noteId);

        const previousNoteValue = getPreviousJournalNoteValue(noteId);

        updateJournalNoteQueryData(
          noteId,
          (draft: { note: JournalNote; replies: JournalNoteReply[] }) => {
            draft.note.repliesCount = draft.note.repliesCount -= 1;
            draft.replies = draft.replies.filter(
              (reply: JournalNoteReply) => reply.replyId !== replyId
            );
          }
        );

        return { previousNoteValue };
      },
      onError: (err, _, context) => {
        showErrorToast(
          "There's an error with deleting reply. Please try again later."
        );

        updateJournalNoteQueryData(noteId, () => context?.previousNoteValue);

        logError(err);
      },
    }
  );
};
