import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';

export const SprintInfoContainer = ({ children }) => {
  return (
    <Flex alignItems="center" justifyContent="flex-start">
      {children}
    </Flex>
  );
};

SprintInfoContainer.propTypes = { children: PropTypes.node.isRequired };
