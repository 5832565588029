import { ButtonProps } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { Button } from 'shared/components/Button';

import { VARIANTS } from './config';

type ButtonLinkProps = {
  children: React.ReactNode;
  to: string;
  variant?: typeof VARIANTS[keyof typeof VARIANTS];
} & ButtonProps;

const ButtonLink = ({
  children,
  to,
  variant = VARIANTS.TERTIARY,
  ...props
}: ButtonLinkProps) => {
  return (
    <Link to={to}>
      <Button variant={variant} {...props}>
        {children}
      </Button>
    </Link>
  );
};

export default ButtonLink;
