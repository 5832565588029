import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { noop } from 'shared_DEPRECATED/utils/function';

import { useChallengeTargetUnitsQuery } from 'features/challenge/hooks/query/useTargetUnits';

import { ModalButton } from 'shared/components/Modal/ModalButton';

type ChallengeTargetAmountDialogButtonsPropTypes = {
  onSave: () => void;
  onCancel?: () => void;
  shouldCloseOnSubmit?: boolean;
};

export const ChallengeTargetAmountDialogButtons = ({
  onSave,
  onCancel = noop,
  shouldCloseOnSubmit = true,
}: ChallengeTargetAmountDialogButtonsPropTypes) => {
  const { isLoading } = useChallengeTargetUnitsQuery();
  const isSaveDisabled = isLoading;

  return (
    <>
      <ModalButton
        variant={VARIANTS.SECONDARY}
        onClick={onCancel}
        dataTestid="challenge-frequency-dialog-cancel"
        shouldClose={shouldCloseOnSubmit}
      >
        Cancel
      </ModalButton>
      <Spacer size="zr md zr sm" />
      <ModalButton
        disabled={isSaveDisabled}
        onClick={onSave}
        variant={VARIANTS.PRIMARY}
        dataTestid="challenge-frequency-dialog-save"
        shouldClose={shouldCloseOnSubmit}
      >
        Save
      </ModalButton>
    </>
  );
};
