import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

export const useChallengeTargetUnitsQuery = () => {
  const { request } = useQueryHTTPRequest();

  return useQuery(
    [queryKeys.challengeTargetUnits],
    () => request({ url: '/api/challenges/units' }),
    {
      select: (data) => data.items,
    }
  );
};
