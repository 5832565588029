import { Suspense, useCallback } from 'react';

import { useSidebarContext } from 'shared';
import Loader from 'shared_DEPRECATED/components/Loader';

import { JournalNotePOSTType, JournalNoteAddDialog } from 'features/journal';

import { modal, SIZES } from 'shared/components/Modal';

export const useOpenJournalAddNoteModal = () => {
  const { participantId } = useSidebarContext();

  return useCallback(
    ({
      onSubmit,
      defaultValues,
    }: {
      onSubmit: (note: JournalNotePOSTType) => Promise<void>;
      defaultValues?: any;
    }) => {
      modal.open({
        content: (
          <Suspense fallback={<Loader size="sm" />}>
            <JournalNoteAddDialog
              onSubmit={onSubmit}
              defaultValues={defaultValues}
            />
          </Suspense>
        ),
        props: {
          size: SIZES.MD,
        },
        context: { participantId },
      });
    },
    [participantId]
  );
};
