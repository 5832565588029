import { useParams } from 'react-router-dom';

import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import ButtonLink from 'shared/components/Button/ButtonLink';
import { Typography } from 'shared/components/Typography';

export const SessionsBackButton = () => {
  const { email } = useParams();

  return (
    <Spacer size="zr zr lg">
      <ButtonLink
        to={`/coach/participants/${email}/sessions`}
        data-testid="back-session-btn"
        size="xs"
      >
        <Icon
          name={iconNames.arrowLeft}
          height={12}
          width={14}
          stroke="var(--fgInteractive)"
        />
        <Spacer size="zr sm" />
        <Typography color="blue" type="small">
          Back
        </Typography>
      </ButtonLink>
    </Spacer>
  );
};
