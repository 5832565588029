import { toastConfig, toastErrorConfig } from 'lib/reactToastify/config';
import {
  Id,
  toast,
  ToastContent,
  ToastOptions,
  UpdateOptions,
} from 'react-toastify';

export const showSuccessToast = (
  content: ToastContent<unknown>,
  options?: ToastOptions<{}> | undefined
) => {
  return toast.success(content, {
    ...toastConfig,
    ...options,
  });
};

export const showErrorToast = (
  content: ToastContent<unknown>,
  options?: ToastOptions<{}> | undefined
) => {
  return toast.error(content, {
    ...toastErrorConfig,
    ...options,
  });
};

export const showLoadingToast = (
  content: ToastContent<unknown>,
  options?: ToastOptions<{}> | undefined
) => {
  return toast.loading(content, {
    ...toastErrorConfig,
    ...options,
  });
};

export const updateToast = (
  toastId: Id,
  options?: UpdateOptions<{}> | undefined
) => {
  return toast.update(toastId, {
    ...toastErrorConfig,
    ...options,
  });
};
