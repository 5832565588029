import { TagCloseButton, TagLabel } from '@chakra-ui/react';

import { Box } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { GoalOption } from 'features/challenge/config/types';

import { Tag } from 'shared/components/Tag';
import { Typography } from 'shared/components/Typography';

type TChallengeGoalsFieldAnchor = {
  goalOptions: GoalOption[];
  handleDeleteGoal: (goalId: string) => void;
};

export const ChallengeGoalsFieldAnchor = ({
  goalOptions,
  handleDeleteGoal,
}: TChallengeGoalsFieldAnchor) => {
  return (
    <Flex justifyContent="flex-start" width="34.5rem">
      {goalOptions.length ? (
        <Flex width="100%" justifyContent="flex-start" flexWrap="wrap">
          {goalOptions.map(({ title, goalId }, index) => (
            <Spacer key={`${title}-${index}`} size="sm">
              <Tag bgColor="lightGray" withCloseButton={false}>
                <TagLabel>
                  <Box maxW="100%">
                    <Typography>{title}</Typography>
                  </Box>
                </TagLabel>
                <TagCloseButton
                  as={Box}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeleteGoal(goalId);
                  }}
                />
              </Tag>
            </Spacer>
          ))}
        </Flex>
      ) : (
        <Spacers sizes={['sm', 'xsm']}>
          <Typography color="gray" type="meta">
            No goal linked
          </Typography>
        </Spacers>
      )}
    </Flex>
  );
};
