import {
  HOS_SUB_ACCOUNT_GROUP_ID,
  HOS_SUB_ACCOUNT_USER_ID,
  queryKeys,
} from 'api/config';

import { getSubAccountFromLocalStorage } from 'features/subAccounts/utils';

export const getSharedSprintsQueryKey = (email: string) => [
  queryKeys.sharedSprints,
  email,
];

export const getParticipantsSprintsQueryKey = (email: string) => [
  queryKeys.participantsSprints,
  email,
];

export const getChallengeNotesQueryKey = (queryString: string) => [
  queryKeys.challengeNotes,
  queryString,
];

export const getNoteRepliesQueryKey = (noteId: string) => [
  queryKeys.noteReplies,
  noteId,
];

export const getJournalQueryKey =
  ({
    participantId,
    isCoachMode,
    queryParams,
  }: {
    participantId: string;
    isCoachMode: boolean;
    queryParams?: string;
  }) =>
  (noteId?: string) =>
    [
      queryKeys.journal,
      queryParams,
      isCoachMode ? participantId : null,
      noteId,
    ].filter(Boolean);

export const getSubAccountHeaders = () => {
  const subAccount = getSubAccountFromLocalStorage();

  return subAccount
    ? {
        [HOS_SUB_ACCOUNT_USER_ID]: subAccount.userId,
        [HOS_SUB_ACCOUNT_GROUP_ID]: subAccount.groupId,
      }
    : {};
};
