import { For } from 'react-loops';

import { Flex } from 'shared';

import {
  JournalFilterButton,
  JournalFilterSectionLabel,
  JournalFilterLabel,
  useJournalChallengeFilterContext,
  JOURNAL_CHALLENGE_FILTERS,
  useJournalFilterContext,
} from 'features/journal';

export const JournalFilterByChallenge = () => {
  const {
    handleAllChallengesClick,
    handleChallengeClick,
    journalChallengeFilterState,
    currentSprintChallenges,
    handleWithoutChallengesClick,
  } = useJournalChallengeFilterContext();
  const { queryParams } = useJournalFilterContext();

  return (
    <>
      <JournalFilterLabel>By Challenge</JournalFilterLabel>
      <Flex gap="8px">
        <JournalFilterButton
          isActive={
            !!journalChallengeFilterState[JOURNAL_CHALLENGE_FILTERS.ALL]
          }
          onClick={handleAllChallengesClick}
          disabled={!!(currentSprintChallenges.length === 0)}
        >
          All challenges
        </JournalFilterButton>
        <JournalFilterButton
          isActive={queryParams.withoutChallenges}
          onClick={handleWithoutChallengesClick}
        >
          Without challenge
        </JournalFilterButton>
      </Flex>
      {Boolean(currentSprintChallenges.length) && (
        <JournalFilterSectionLabel>
          Current challenges
        </JournalFilterSectionLabel>
      )}
      <Flex gap="8px" flexWrap="wrap">
        <For of={currentSprintChallenges}>
          {({ title, challengeId }) => (
            <JournalFilterButton
              isActive={
                !!journalChallengeFilterState[
                  `${JOURNAL_CHALLENGE_FILTERS.CURRENT_CHALLENGES}-${challengeId}`
                ]
              }
              onClick={() => handleChallengeClick(challengeId)}
            >
              {title}
            </JournalFilterButton>
          )}
        </For>
      </Flex>
    </>
  );
};
