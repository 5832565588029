import { useFormContext, useWatch } from 'react-hook-form';
import { For } from 'react-loops';

import { Box, Flex, Typography } from 'shared';
import { isImage } from 'shared_DEPRECATED/components/File/utils';
import FileUpload from 'shared_DEPRECATED/components/Form/FileUpload';
import OptionalText from 'shared_DEPRECATED/components/Form/OptionalText';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Image from 'shared_DEPRECATED/components/Image';
import { Video } from 'shared_DEPRECATED/components/Video/Video';

import { AttachmentWithFile } from 'features/journal/types';

import { JournalAddNoteFormSection } from './Section';

export const JournalAddNoteFormAttachMediaSection = () => {
  const { control, setValue } = useFormContext();

  const media = useWatch({
    control,
    name: 'media',
  }) as AttachmentWithFile[];

  const handleFileChange = async (files: File[]) => {
    if (!files) return;

    setValue('media', [
      ...media,
      ...files.map((file) => ({
        name: file.name,
        contentLength: file.size,
        contentType: file.type,
        url: URL.createObjectURL(file),
        file,
      })),
    ]);
  };

  return (
    <JournalAddNoteFormSection>
      <Flex width="100%" justifyContent="space-between">
        <Typography
          as="label"
          color="black"
          fontWeight="medium"
          type="smallHeading"
        >
          Attach media
        </Typography>
        <OptionalText />
      </Flex>
      <Flex width="100%" gap="1rem" alignItems="center">
        <FileUpload
          onFileUpload={handleFileChange}
          dataTestid="attachment-file-btn"
          extensions="image/*,video/*,application/vnd.apple.mpegurl"
        >
          <Flex
            width="3.75rem"
            height="3.75rem"
            backgroundColor="var(--bgSecondary)"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              name={iconNames.image}
              stroke="var(--fgTertiary)"
              strokeWidth={1}
            />
          </Flex>
        </FileUpload>
        <Flex flexWrap="wrap" gap="var(--spacing02)">
          <For of={media}>
            {({ file }) => {
              const mediaUrl = URL.createObjectURL(file);

              return (
                <Box
                  w="3.75rem"
                  h="3.75rem"
                  maxWidth="3.75rem"
                  overflow="hidden"
                  borderRadius="0.5rem"
                  boxShadow="var(--button-box-shadow)"
                  position="relative"
                >
                  {isImage(file.type) ? (
                    <Image
                      src={mediaUrl}
                      alt={file.name}
                      width="100%"
                      height="100%"
                      borderRadius={5}
                      dataTestId="image-preview"
                    />
                  ) : (
                    <Video
                      src={mediaUrl}
                      controls={false}
                      playing={false}
                      muted
                      width="100%"
                      height="100%"
                      config={{
                        file: {
                          attributes: {
                            style: {
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            },
                          },
                        },
                      }}
                      playIcon={<></>}
                    />
                  )}
                </Box>
              );
            }}
          </For>
        </Flex>
      </Flex>
    </JournalAddNoteFormSection>
  );
};
