import { When } from 'react-if';
import { useParams } from 'react-router-dom';

import { NavigationLink } from 'app/layout/components/Layout/CoachSidebar/Navigation';
import { usePlanningFeatureFlag } from 'featureFlags/hooks/usePlanningFeatureFlag';
import { motion } from 'framer-motion';
import { VARIANTS } from 'shared';
import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import Box from 'shared_DEPRECATED/components/Box';
import ButtonLink from 'shared_DEPRECATED/components/Button/ButtonLink';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import { iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';
import { getPictureUrl } from 'shared_DEPRECATED/utils';
import { routeNames } from 'shared_DEPRECATED/utils/config';

import { useGoalsEnabled } from 'features/goal';
import { useParticipantQuery } from 'features/participants/hooks';
import { SprintInfoStatus } from 'features/sprint/components/Info';
import {
  useCurrentSprint,
  useSprintLink,
  useSprints,
} from 'features/sprint/hooks';
import { sharedSprintLinks } from 'features/sprint/utils';

export const CoachSidebarClosed = () => {
  const { email } = useParams();
  const { participant } = useParticipantQuery(email);
  const getNavigationLinkWithParticipantEmail = getNavigationLink(email!);
  const isGoalsEnabled = useGoalsEnabled();
  const isPlanningEnabled = usePlanningFeatureFlag();
  const { data: sprint } = useCurrentSprint();
  const { data: sprints } = useSprints(email);
  const currentSprint = sprint || sprints?.[0];
  const sprintLink = useSprintLink(currentSprint?.sprintId ?? sprint?.sprintId);

  return (
    <motion.div
      key="closed"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <HotjarSuppression>
        <Flex
          as="span"
          justifyContent="flex-start"
          alignItems="center"
          gap="var(--spacing02)"
        >
          <Avatar
            name={participant.userName}
            size={SIZES.LG}
            src={getPictureUrl(participant.userPicture)}
          />
        </Flex>
      </HotjarSuppression>
      <Spacers sizes={['zr zr lg', 'zr zr md']} />
      <NavigationLink
        iconConfig={{
          name: iconNames.dashboard,
        }}
        link={getNavigationLinkWithParticipantEmail('dashboard')}
        dataTestid="dashboard-bnt"
        title="Dashboard"
      />
      <Spacer size="xsm zr" />
      <NavigationLink
        iconConfig={{
          name: iconNames.sessions,
        }}
        link={getNavigationLinkWithParticipantEmail('sessions')}
        dataTestid="sessions-bnt"
        title="Sessions"
      />
      <Spacer size="xsm zr" />
      {isGoalsEnabled && (
        <NavigationLink
          iconConfig={{
            name: 'goals',
          }}
          link={getNavigationLinkWithParticipantEmail(routeNames.GOALS)}
          dataTestid="roadmap-navigation-goals-item"
          title="Goals"
        />
      )}
      <Spacer size="xsm zr" />
      {isPlanningEnabled && (
        <NavigationLink
          iconConfig={{
            name: 'planning',
          }}
          link={getNavigationLinkWithParticipantEmail(routeNames.PLANNING)}
          dataTestid="roadmap-navigation-planning-item"
          title="Planning"
        />
      )}
      <Spacer size="xsm zr" />
      <NavigationLink
        iconConfig={{
          name: iconNames.group,
        }}
        link={getNavigationLinkWithParticipantEmail('groups')}
        dataTestid="groups-btn"
        title="Groups"
      />
      <Spacers sizes={['zr zr lg', 'zr zr md']} />
      <Box style={{ borderTop: 'var(--border-secondary)' }} />
      <Spacers sizes={['lg zr zr', 'md zr zr']} />
      {/* @ts-ignore */}
      <When condition={currentSprint}>
        {() => (
          // @ts-ignore
          <ButtonLink to={sprintLink} variant={VARIANTS.TERTIARY}>
            <SprintInfoStatus
              weekIndex={currentSprint.currentWeekNumber}
              successRate={currentSprint.successRate}
              status={currentSprint.state}
            />
          </ButtonLink>
        )}
      </When>
    </motion.div>
  );
};

const getNavigationLink = (email: string) => (path: string) =>
  `${sharedSprintLinks.participants}/${email}/${path}`;
