import { useWatch } from 'react-hook-form';

import { FormControl, FormHelperText, FormLabel } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import {
  ChallengeReminderFieldPicker,
  ChallengeReminderFieldSwitch,
} from 'features/challenge/components/Form/fields/ReminderField/';

export const ChallengeReminderField = () => {
  const initialReminder = useWatch({ name: 'reminder' });

  return (
    <FormControl>
      <Flex>
        <Box
          style={{
            maxWidth: '31rem',
          }}
        >
          <FormLabel>Reminder</FormLabel>
          <FormHelperText>
            The reminder will be set according to participant’s time zone
          </FormHelperText>
        </Box>
        <ChallengeReminderFieldSwitch reminder={initialReminder} />
      </Flex>
      {initialReminder && (
        <ChallengeReminderFieldPicker initialReminder={initialReminder} />
      )}
    </FormControl>
  );
};
