import { useQuery } from '@tanstack/react-query';

import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getJournalQueryKey } from 'api/utils';
import { useSidebarContext } from 'shared';

import { JournalNote, JournalNoteReply } from 'features/journal';
import { useIsCoachMode } from 'features/user/hooks/useIsCoachMode';

type TUseJournalNoteQuery = {
  noteId: string;
};

export const useJournalNoteQuery = ({ noteId }: TUseJournalNoteQuery) => {
  const { request } = useQueryHTTPRequest();

  const { participantId } = useSidebarContext();

  const isCoachMode = useIsCoachMode();

  const queryKey = getJournalQueryKey({ participantId, isCoachMode });

  return useQuery<{ note: JournalNote; replies: JournalNoteReply[] }>(
    queryKey(noteId),
    async () => await request({ url: `/api/journal/notes/${noteId}` }),
    {
      select: (data) => ({
        note: data?.note ?? {},
        replies: data?.replies ?? [],
      }),
    }
  );
};
