import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { IBaseChallenge } from 'features/challenge/config/types';
import {
  JournalReplies,
  JournalNotes,
  JournalNote,
  NotesGroupedBySprintIdAndDate,
  mapNotesToSprintsType,
  JOURNAL_SPRINT_FILTERS,
  JournalReaction,
  JournalAuthor,
  JournalAuthorPicture,
  JOURNAL_CHALLENGE_FILTERS,
  JournalNoteType,
  FailReasonsType,
  JournalQueryParamsType,
} from 'features/journal';
import { ParticipantSprint } from 'features/sprint';
import { IFinalSprint } from 'features/sprint/config/types';
import { User } from 'features/user/User';

import { sidebar } from 'shared/components/Sidebar';
import { getUuId } from 'shared/utils/uuid';

export const createTemporaryJournalNote = ({
  user,
  text,
  noteType,
  challengeFailReasons,
  challengeId,
  sprint,
}: {
  user: User;
  text?: string;
  noteType: JournalNoteType;
  challengeFailReasons: FailReasonsType;
  challengeId?: Nullable<string>;
  sprint: ParticipantSprint;
}) => {
  const temporaryNoteId = getUuId();
  const challengeTitle = challengeId && sprint.getChallenge(challengeId)?.title;

  return {
    noteId: temporaryNoteId,
    authorId: user.userId,
    text,
    noteType,
    sprintId: sprint.sprintId,
    challenge: {
      id: challengeId,
      title: challengeTitle,
      status: '',
      statusDate: dateUtils(new Date()).format('YYYY-MM-DD'),
      failReasons: challengeFailReasons,
    },
    mediaList: [],
    repliesCount: 0,
    createdAt: dateUtils().toISOString(),
    reactions: [],
    isRead: true,
    hasUnreadReplies: false,
  };
};

export const createTemporaryJournalReaction = ({
  user,
  reaction,
}: {
  user: User;
  reaction: string;
}) => {
  const temporaryReactionId = parseInt(getUuId());

  return {
    reactionId: temporaryReactionId,
    author: {
      userId: user.userId,
      picture: { url: user.picture.url } as JournalAuthorPicture,
      fullName: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
    },
    temporaryReactionId,
    reaction,
    createdAt: new Date().toISOString(),
  };
};

export const createTemporaryJournalReply = ({
  user,
  noteId,
  text,
}: {
  user: User;
  noteId: string;
  text: string;
}) => {
  const temporaryReplyId = getUuId();

  return {
    author: {
      userId: user.userId,
      picture: { url: user.picture.url } as JournalAuthorPicture,
      fullName: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
    },
    createdAt: new Date().toISOString(),
    replyId: temporaryReplyId,
    noteId,
    text,
    updatedAt: null,
    reactions: [],
    isRead: true,
  };
};

export const groupedReactionsByAuthor = (
  reactions: JournalReaction[],
  userId: string
) => {
  return reactions?.reduce((acc, reaction) => {
    const authorId = reaction.author.userId;

    if (!acc[authorId]) {
      acc[authorId] = {
        author: reaction.author,
        isMy: Boolean(userId === authorId),
        reactions: [],
      };
    }

    acc[authorId].reactions.push(reaction);

    return acc;
  }, {} as { [authorId: string]: { author: JournalAuthor; isMy: boolean; reactions: JournalReaction[] } });
};

export const groupNotesByDate = (notes: JournalNote[]) => {
  const groupedByDate = notes.reduce(
    (acc, note) => {
      const dateKey =
        note.challenge?.statusDate ||
        dateUtils(note.createdAt).format('YYYY-MM-DD');

      if (!acc[dateKey]) {
        acc[dateKey] = {
          notesByDate: { [dateKey]: [] },
        };
      }

      acc[dateKey].notesByDate[dateKey].push(note);

      return acc;
    },
    {} as Record<
      string,
      {
        notesByDate: {
          [dateKey: string]: JournalNote[];
        };
      }
    >
  );

  return Object.values(groupedByDate);
};

export const groupNotesBySprintAndDate = (
  notes: JournalNote[]
): NotesGroupedBySprintIdAndDate =>
  notes.reduce((acc, note) => {
    const { sprintId } = note;

    const challengeStatusDate = note?.challenge?.statusDate;

    const dateKey = challengeStatusDate
      ? challengeStatusDate
      : dateUtils(note.createdAt).format('YYYY-MM-DD');

    if (!acc[sprintId]) {
      acc[sprintId] = {};
    }

    if (!acc[sprintId][dateKey]) {
      acc[sprintId][dateKey] = [];
    }

    acc[sprintId][dateKey].push(note);

    return acc;
  }, {} as NotesGroupedBySprintIdAndDate);

export const mapNotesToSprints = (
  notes: JournalNote[],
  sprints: IFinalSprint[]
): mapNotesToSprintsType => {
  const notesGroupedBySprintAndDate = groupNotesBySprintAndDate(notes);

  return sprints
    .filter(({ sprintId }) => Boolean(notesGroupedBySprintAndDate[sprintId]))
    .map(({ sprintId, title: sprintTitle, formattedDate }) => {
      const notesByDate = notesGroupedBySprintAndDate[sprintId];

      return {
        sprintId,
        sprintTitle,
        formattedDate,
        notesByDate,
      };
    });
};

export const createJournalSprintFilterState = (
  selectedSprintIds: string[],
  currentSprint: IFinalSprint,
  pastSprints: IFinalSprint[]
): Record<string, boolean> => {
  const currentSprintId = currentSprint?.sprintId;

  return pastSprints.reduce<Record<string, boolean>>(
    (acc, { sprintId }) => {
      const isPastSelected = selectedSprintIds.includes(sprintId);

      return {
        ...acc,
        [`${JOURNAL_SPRINT_FILTERS.PAST}-${sprintId}`]: isPastSelected,
      };
    },
    {
      [JOURNAL_SPRINT_FILTERS.ALL]: selectedSprintIds.includes(
        JOURNAL_CHALLENGE_FILTERS.ALL
      ),
      [JOURNAL_SPRINT_FILTERS.CURRENT]:
        selectedSprintIds.includes(currentSprintId),
    }
  );
};

export const createJournalChallengeFilterState = (
  selectedChallengeIds: string[],
  challenges: IBaseChallenge[]
): Record<string, boolean> =>
  challenges.reduce<Record<string, boolean>>(
    (acc, { challengeId }) => ({
      ...acc,
      [`${JOURNAL_CHALLENGE_FILTERS.CURRENT_CHALLENGES}-${challengeId}`]:
        selectedChallengeIds.includes(challengeId),
    }),
    {
      [JOURNAL_CHALLENGE_FILTERS.ALL]:
        challenges.length !== 0 &&
        selectedChallengeIds.length === challenges.length,
      [JOURNAL_CHALLENGE_FILTERS.WITHOUT_CHALLENGES]:
        selectedChallengeIds.length === 0,
    }
  );

export const openJournalSidebar = ({
  user,
  participantId,
  participantEmail,
  filters,
}: {
  user: User;
  participantEmail: string;
  participantId?: string;
  filters?: JournalQueryParamsType;
}) =>
  sidebar.open({
    content: <JournalNotes />,
    props: { title: 'Journal Notes Sidebar' },
    context: {
      user,
      location: 'notes',
      participantEmail,
      participantId,
      filters,
    },
  });

export const openJournalRepliesSidebar = ({
  noteId,
  user,
  participantEmail,
  participantId,
  filters,
}: {
  noteId: string;
  user: User;
  participantEmail: string;
  participantId: string;
  filters?: JournalQueryParamsType;
}) =>
  sidebar.open({
    content: <JournalReplies />,
    props: { title: 'Journal Replies Sidebar' },
    context: {
      noteId,
      user,
      location: 'replies',
      participantEmail,
      participantId,
      filters,
    },
  });
