import { Box } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { BasicTextEditor } from 'shared';

import {
  SessionSidebarFieldset,
  SessionSidebarFieldsetLabel,
} from 'features/sessions/components';

import { TextEditorFormats } from 'shared/components/TextEditor/constants';

export const NextSessionNotes = () => {
  const { getValues, setValue } = useFormContext();
  const fieldKey = getValues('nextSessionFieldKey') || '';
  const notesValue = useWatch({ name: `${fieldKey}.notes` }) || '';

  const handleChange = (value: string) => {
    setValue(`${fieldKey}.notes`, value, {
      shouldDirty: true,
    });
  };

  return (
    <SessionSidebarFieldset>
      <SessionSidebarFieldsetLabel>
        Next session notes
      </SessionSidebarFieldsetLabel>
      <Box h="12rem">
        <BasicTextEditor
          value={notesValue}
          onChange={handleChange}
          placeholder="Start writing here"
          format={TextEditorFormats.json}
        />
      </Box>
    </SessionSidebarFieldset>
  );
};
