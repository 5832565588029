import { ReactNode } from 'react';

import { Flex } from 'shared';

type TJournalAddNoteFormSection = {
  children: ReactNode;
};

export const JournalAddNoteFormSection = ({
  children,
}: TJournalAddNoteFormSection) => (
  <Flex gap="0.75rem" flexDirection="column" width="100%" pb="4px">
    {children}
  </Flex>
);
