import Box from 'shared_DEPRECATED/components/Box';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  useAddPlanningDraftSprintMutation,
  usePlanningAnalyticsEvents,
} from 'features/planning';

import { Button, VARIANTS } from 'shared/components/Button';
import { showLoadingToast, updateToast } from 'shared/components/Toast';

export const PlanningAddSprintButton = () => {
  const { mutateAsync: addSprint } = useAddPlanningDraftSprintMutation();
  const { sendPlanningSprintDraftAddedEvent } = usePlanningAnalyticsEvents();

  const handleAddSprint = async () => {
    const toastId = showLoadingToast('Adding draft sprint...');

    try {
      await addSprint();

      sendPlanningSprintDraftAddedEvent();

      updateToast(toastId, {
        render: 'Draft sprint added!',
        type: 'success',
        isLoading: false,
        icon: false,
      });
    } catch (e) {
      updateToast(toastId, {
        render:
          "There's an error with adding draft sprint. Please try again later.",
        type: 'error',
        isLoading: false,
        icon: false,
      });
    }
  };

  return (
    <Box
      style={{
        borderRadius: 'var(--border-radius16)',
        overflow: 'hidden',
      }}
    >
      <Button
        onClick={handleAddSprint}
        variant={VARIANTS.PRIMARY}
        dataTestid="add-new-sprint-draft"
        leftIcon={
          <Icon
            name={iconNames.plus}
            width={13}
            height={13}
            strokeWidth={2}
            stroke="var(--fgPrimaryInverse)"
          />
        }
      >
        Add sprint draft
      </Button>
    </Box>
  );
};
