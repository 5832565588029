import { useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import { Flex } from 'shared';
import OptionalText from 'shared_DEPRECATED/components/Form/OptionalText';

import { JournalAddNoteFormSection } from 'features/journal';

import { Typography } from 'shared/components/Typography';

export const JournalAddNoteFormTextSection = () => {
  const { register } = useFormContext();

  return (
    <JournalAddNoteFormSection>
      <Flex width="100%" justifyContent="space-between">
        <Typography
          as="label"
          color="black"
          fontWeight="medium"
          type="smallHeading"
        >
          Text
        </Typography>
        <OptionalText />
      </Flex>
      <TextareaAutosize
        {...register('text', {
          setValueAs: (v: string) => v.trim(),
        })}
        placeholder="Share your thoughts"
        style={{
          width: '100%',
          borderRadius: 'var(--border-radius4)',
          border: 'var(--border-secondary)',
          backgroundColor: 'transparent',
          color: 'var(--fgPrimary)',
          boxSizing: 'border-box',
          lineHeight: 'var(--line-height-medium)',
          resize: 'none',
          padding: '10px',
          outline: 'var(--fgPrimary)',
          overflow: 'auto',
        }}
        minRows={6}
        maxRows={12}
      />
    </JournalAddNoteFormSection>
  );
};
