import { JournalFilterButton, useJournalFilterContext } from 'features/journal';

export const JournalFilterUnread = () => {
  const { queryParams, onQueryParamsChange } = useJournalFilterContext();

  const handleUnreadFilterChange = () => {
    onQueryParamsChange({ isUnread: !queryParams.isUnread });
  };

  return (
    <JournalFilterButton
      isActive={queryParams.isUnread}
      onClick={handleUnreadFilterChange}
    >
      Unread
    </JournalFilterButton>
  );
};
