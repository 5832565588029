import { JournalFilterButton, useJournalFilterContext } from 'features/journal';

export const JournalFilterReplies = () => {
  const { queryParams, onQueryParamsChange } = useJournalFilterContext();

  const handleUserRepliesFilterChange = () => {
    onQueryParamsChange({ hasUserReplies: !queryParams.hasUserReplies });
  };

  return (
    <JournalFilterButton
      isActive={queryParams.hasUserReplies}
      onClick={handleUserRepliesFilterChange}
    >
      User replies
    </JournalFilterButton>
  );
};
