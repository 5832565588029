import { Divider, Flex } from 'shared';
import { FloatingPopover } from 'shared_DEPRECATED/components/Floating';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { useCoachSettingsEnabled } from 'features/coach/settings/hooks';

import { HeaderMenuOptionButton } from './OptionButton';
import { HeaderMenuPopoverButton } from './PopoverButton';
import { HeaderMenuUserInfo } from './UserInfo';

type HeaderMenuContentProps__OLD = {
  navigateToSettingsPage: () => void;
  handleLogout: () => void;
  name: string;
  email: string;
  url: string;
  isOpen: boolean;
  toggleOpen: () => void;
};

export const HeaderMenuContent__OLD = ({
  navigateToSettingsPage,
  handleLogout,
  name,
  email,
  url,
  isOpen,
  toggleOpen,
}: HeaderMenuContentProps__OLD) => {
  const isCoachProfileFormPageDisabled = useCoachSettingsEnabled();

  return (
    <FloatingPopover
      onClose={toggleOpen}
      isOpen={isOpen}
      anchor={
        <HeaderMenuPopoverButton
          onClick={toggleOpen}
          name={name}
          pictureUrl={url}
          dataTestid="UserMenu_popoverButton"
        />
      }
      offsetTop={4}
      isAutoUpdate={true}
      dataTestid="header-menu"
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Spacer size="lg">
          <HeaderMenuUserInfo name={name} email={email} pictureUrl={url} />
        </Spacer>
        {!isCoachProfileFormPageDisabled && (
          <HeaderMenuOptionButton
            dataTestid="UserMenuOptionButton_profileSettings"
            onClick={navigateToSettingsPage}
          >
            Settings
          </HeaderMenuOptionButton>
        )}
        <Divider />
        <HeaderMenuOptionButton
          dataTestid="UserMenuOptionButton_logOut"
          onClick={handleLogout}
        >
          Log out
        </HeaderMenuOptionButton>
      </Flex>
    </FloatingPopover>
  );
};
