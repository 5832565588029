import { extendTheme, ThemeOverride, ThemeConfig } from '@chakra-ui/react';

import { Dict } from '@chakra-ui/utils';

import { checkboxTheme } from './components/Checkbox';
import { menuTheme } from './components/Menu';

const config = {
  initialColorMode: 'system',
} as ThemeConfig;

type ExtendTheme = (...extensions: Array<ThemeOverride>) => Dict;

export const theme = (extendTheme as ExtendTheme)({
  semanticTokens: {
    colors: {
      black: '#000000',
      white: '#ffffff',
      gray: '#6d7081',
      bgPrimary: {
        default: '#ffffff',
        _dark: '#17181c',
      },
      bgSecondary: {
        default: '#f2f2f7',
        _dark: '#000000',
      },
      bgTertiary: {
        default: '#f8f9fd',
        _dark: '#202227',
      },
      bgCompPrimary: {
        default: '#ffffff',
        _dark: '#3a4053',
      },
      bgCompPrimaryInverse: {
        default: '#17181c',
        _dark: '#ffffff',
      },
      bgCompSecondaryTiny: {
        default: '#66739933',
        _dark: '#73798c66',
      },
      bgCompSecondaryMid: {
        default: '#6673991f',
        _dark: '#73798c3d',
      },
      bgCompSecondaryBig: {
        default: '#6673990f',
        _dark: '#66739940',
      },
      bgCompAccent: {
        default: '#3f7bf3',
        _dark: '#2267f1',
      },
      bgCompMinorAccent: {
        default: '#d9e5fd',
        _dark: '#062056',
      },
      bgCompHoverAccent: {
        default: '#326de3',
        _dark: '#1e5cd9',
      },
      bgCompSuccess: {
        default: '#39ac6f',
        _dark: '#349b64',
      },
      bgCompMinorSuccess: {
        default: '#d1f0df',
        _dark: '#1e3428',
      },
      bgCompWarning: {
        default: '#eeba2a',
        _dark: '#e9b013',
      },
      bgCompMinorWarning: {
        default: '#faeabf',
        _dark: '#4e3b06',
      },
      bgCompDanger: {
        default: '#ee4946',
        _dark: '#eb2c28',
      },
      bgCompMinorDanger: {
        default: '#fcdada',
        _dark: '#3b2626',
      },
      fgPrimary: {
        default: '#000000',
        _dark: '#ffffff',
      },
      fgPrimaryInverse: {
        default: '#ffffff',
        _dark: '#000000',
      },
      fgSecondary: {
        default: '#393d4699',
        _dark: '#ffffff99',
      },
      fgTertiary: {
        default: '#393d464d',
        _dark: '#ffffff4d',
      },
      fgInteractive: {
        default: '#0f56e6',
        _dark: '#5288f4',
      },
      fgSuccess: {
        default: '#2e8a59',
        _dark: '#42c07d',
      },
      fgWarning: {
        default: '#d09d11',
        _dark: '#f0c140',
      },
      fgDanger: {
        default: '#e01815',
        _dark: '#ef5a57',
      },
      lifeDimsCareer: {
        default: '#129ade',
        _dark: '#129ade',
      },
      lifeDimsMinorCareer: {
        default: '#cdebfa',
        _dark: '#08354d',
      },
      lifeDimsEnjoyment: {
        default: '#ea8c2e',
        _dark: '#ea8c2e',
      },
      lifeDimsMinorEnjoyment: {
        default: '#fae3cd',
        _dark: '#4a2d10',
      },
      lifeDimsHealth: {
        default: '#eb4770',
        _dark: '#eb4770',
      },
      lifeDimsMinorHealth: {
        default: '#facdd8',
        _dark: '#4d1724',
      },
      lifeDimsRelationships: {
        default: '#7950f1',
        _dark: '#7950f1',
      },
      lifeDimsMinorRelationships: {
        default: '#d8cdfa',
        _dark: '#2a1d52',
      },
      lifeDimsFinances: {
        default: '#2caf9a',
        _dark: '#2caf9a',
      },
      lifeDimsMinorFinances: {
        default: '#cdfaf3',
        _dark: '#11453d',
      },
      lifeDimsImpact: {
        default: '#cf47eb',
        _dark: '#cf47eb',
      },
      lifeDimsMinorImpact: {
        default: '#f2cdfa',
        _dark: '#43184d',
      },
      lifeDimsSpirituality: {
        default: '#eeba2a',
        _dark: '#eeba2a',
      },
      lifeDimsMinorSpirituality: {
        default: '#faeecd',
        _dark: '#4a3a0f',
      },
      divPrimary: {
        default: '#6673991f',
        _dark: '#73798c3d',
      },
      brdPrimary: {
        default: '#17181c',
        _dark: '#ffffff',
      },
      brdSecondary: {
        default: '#66739933',
        _dark: '#73798c66',
      },
      brdAccent: {
        default: '#3f7bf3',
        _dark: '#3f7bf3',
      },
      brdSuccess: {
        default: '#39ac6f',
        _dark: '#39ac6f',
      },
      brdDanger: {
        default: '#eb2c28',
        _dark: '#eb2c28',
      },
      ovlPrimary: {
        default: '#17181c66',
        _dark: '#17181c66',
      },
    },
    radii: {
      borderRadius00: '0.125rem',
      borderRadius01: '0.25rem',
      borderRadius02: '0.5rem',
      borderRadius03: ' 0.75rem',
      borderRadius04: '1rem',
      borderRadius05: '1.125rem',
      round: '50%',
    },
    fontSizes: {
      xsm: '0.625rem',
      small: '0.75rem',
      meta: '0.875rem',
      body: '1rem',
      smallHeading: '1.125rem',
      heading: '1.25rem',
      semiLargeHeading: '1.5rem',
      largeHeading: '2rem',
      extraLargeHeading: '2.5rem',
    },
  },
  space: {
    spacing00: '0.125rem',
    spacing01: '0.25rem',
    spacing02: '0.5rem',
    spacing03: '0.75rem',
    spacing04: '1rem',
    spacing05: '1.25rem',
    spacing06: '1.5rem',
    spacing07: '2rem',
    spacing08: '2.5rem',
    spacing09: '3rem',
    spacing12: '5rem',
  },
  radii: {
    borderRadius00: '0.125rem',
    borderRadius01: '0.25rem',
    borderRadius02: '0.5rem',
    borderRadius03: ' 0.75rem',
    borderRadius04: '1rem',
    borderRadius05: '1.125rem',
    borderRadius06: '1.5rem',
  },
  components: {
    Badge: {
      baseStyle: {
        borderRadius: 'borderRadius06',
        px: 'spacing00',
        py: 'spacing00',
        fontWeight: 'medium',
      },
      sizes: {
        xsm: {
          px: 'spacing01',
          py: '0',
        },
        sm: {
          px: 'spacing02',
          py: 'spacing01',
        },
        md: {
          px: 'spacing03',
          py: 'spacing00',
        },
      },
      variants: {
        lightOrange: { background: 'bgCompMinorWarning' },
        lightBlue: { background: 'bgCompMinorAccent' },
        lightYellow: { background: 'bgCompMinorWarning' },
        lightRed: { background: 'bgCompMinorDanger' },
        lightGray: { background: 'bgCompSecondaryTiny' },
        lightGreen: { background: 'bgCompMinorSuccess' },
        blue: { background: 'bgCompAccent' },
        gray: { background: 'bgSecondary' },
        darkGray: { background: 'bgCompPrimaryInverse' },
        green: { background: 'bgCompSuccess' },
        orange: { background: 'bgCompWarning' },
        red: { background: 'bgCompDanger' },
        black: { background: 'black' },
        white: { background: 'bgPrimary' },
        whiteTotal: { background: 'white' },
      },
    },
    Button: {
      baseStyle: () => ({
        borderRadius: '40px',
        _focusVisible: {
          outline: 'none',
        },
      }),
      sizes: {
        sm: {
          fontSize: 'xs',
          lineHeight: '1rem',
        },
        md: {
          fontSize: 'sm',
          lineHeight: '1.25rem',
        },
      },
      variants: {
        critical: ({ colorMode }: { colorMode: 'light' | 'dark' }) => ({
          fontWeight: 'medium',
          bg: 'bgCompDanger',
          color: 'white',
          _disabled: {
            bg: 'bgSecondary',
            color: colorMode === 'light' ? 'black' : 'white',
            pointerEvents: 'none',
          },
        }),
        primary: ({ colorMode }: { colorMode: 'light' | 'dark' }) => ({
          fontWeight: 'medium',
          bg: 'bgCompPrimaryInverse',
          color: 'fgPrimaryInverse',
          _hover: {
            bg: 'bgCompPrimaryInverse',
          },
          _disabled: {
            bg: 'bgSecondary',
            color: colorMode === 'light' ? 'black' : 'white',
            pointerEvents: 'none',
          },
        }),
        tertiary: ({ colorMode }: { colorMode: 'light' | 'dark' }) => ({
          fontWeight: 'medium',
          bg: 'transparent',
          color: 'fgPrimary',
          _hover: {
            bg: 'bgSecondary',
          },
          _disabled: {
            bg: 'bgSecondary',
            color: colorMode === 'light' ? 'black' : 'white',
            pointerEvents: 'none',
          },
        }),
        secondary: ({ colorMode }: { colorMode: 'light' | 'dark' }) => ({
          fontWeight: 'medium',
          borderColor: 'brdSecondary',
          borderWidth: '1px',
          borderStyle: 'solid',
          color: 'fgPrimary',
          _disabled: {
            bg: 'bgSecondary',
            color: colorMode === 'light' ? 'black' : 'white',
            pointerEvents: 'none',
          },
          _active: {
            bg: 'bgSecondary',
          },
          _hover: {
            borderColor: 'black',
          },
        }),
      },
    },
    CloseButton: {
      baseStyle: ({ colorMode }: { colorMode: 'light' | 'dark' }) => ({
        fontWeight: 'medium',
        bg: 'transparent',
        color: 'fgPrimary',
        _hover: {
          bg: 'bgSecondary',
        },
        _disabled: {
          bg: 'bgSecondary',
          color: colorMode === 'light' ? 'black' : 'white',
          pointerEvents: 'none',
        },
      }),
    },
    Checkbox: checkboxTheme,
    Drawer: {
      baseStyle: {
        dialog: {
          bg: 'bgPrimary',
        },
      },
      sizes: {
        menu: {
          dialog: { maxWidth: '384px' },
        },
      },
    },
    Form: {
      baseStyle: {
        helperText: {
          fontSize: 'xs',
        },
      },
    },
    FormError: {
      baseStyle: {
        text: {
          fontSize: 'xs',
          color: 'fgDanger',
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'xs',
        fontWeight: '600',
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: 'brdSecondary',
            borderRadius: 'borderRadius02',
            color: 'fgPrimary',
            _disabled: {
              bg: 'bgCompSecondaryMid',
            },
            _invalid: {
              borderColor: 'brdDanger',
            },
            _placeholder: {
              color: 'fgSecondary',
            },
            _focusVisible: {
              borderColor: 'fgPrimary',
              boxShadow: 'none',
              _invalid: {
                borderColor: 'brdDanger',
              },
            },
          },
        },
      },
    },
    Menu: menuTheme,
    Modal: {
      baseStyle: {
        footer: {
          bg: 'bgPrimary',
          borderRadius: 'borderRadius03',
        },
        dialog: {
          bg: 'bgPrimary',
        },
        content: {
          maxH: '65vh',
        },
        header: {
          p: '0.75rem',
        },
      },
      sizes: {
        sm: {
          dialog: {
            maxW: '30rem', // 480px
          },
        },
        md: {
          dialog: {
            maxW: '39.875rem', // 600px
          },
        },
        lg: {
          dialog: {
            maxW: '46rem', // 736px
          },
        },
        auto: {
          dialog: {
            width: 'auto',
          },
        },
      },
    },
    Popover: {
      parts: ['content', 'header', 'body', 'footer'],
      baseStyle: {
        content: {
          width: 'auto',
          _focusVisible: {
            boxShadow: 'none',
          },
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            bg: 'bgCompAccent',
            borderColor: 'brdAccent',
          },
          _focusVisible: {
            boxShadow: 'none',
          },
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          _checked: {
            bg: 'bgCompAccent',
          },
          _focusVisible: {
            boxShadow: 'none',
          },
        },
      },
    },
    Tag: {
      baseStyle: {
        borderRadius: 'borderRadius06',
        px: 'spacing00',
        py: 'spacing00',
      },
      sizes: {
        xsm: {
          px: 'spacing01',
          py: '0',
        },
        sm: {
          px: 'spacing02',
          py: 'spacing00',
        },
        md: {
          px: 'spacing03',
          py: 'spacing00',
        },
      },
      variants: {
        lightOrange: { container: { bg: 'bgCompMinorWarning' } },
        lightBlue: { container: { bg: 'bgCompMinorAccent' } },
        lightYellow: { container: { bg: 'bgCompMinorWarning' } },
        lightRed: { container: { bg: 'bgCompMinorDanger' } },
        lightGray: { container: { bg: 'bgCompSecondaryTiny' } },
        lightGreen: { container: { bg: 'bgCompMinorSuccess' } },
        blue: { container: { bg: 'bgCompAccent' } },
        gray: { container: { bg: 'bgSecondary' } },
        darkGray: { container: { bg: 'bgCompPrimaryInverse' } },
        green: { container: { bg: 'bgCompSuccess' } },
        orange: { container: { bg: 'bgCompWarning' } },
        red: { container: { bg: 'bgCompDanger' } },
        black: { container: { bg: 'black' } },
      },
    },
    Text: {
      baseStyle: {
        color: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
      },
      sizes: {
        xsm: {
          fontSize: '0.625rem',
          lineHeight: '0.75rem',
        },
        small: {
          fontSize: 'xs',
          lineHeight: '1rem',
        },
        meta: {
          fontSize: 'sm',
          lineHeight: '1.25rem',
        },
        body: {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
        smallHeading: {
          fontSize: '1rem',
          lineHeight: '1.25rem',
        },
        heading: {
          fontSize: '1.25rem',
          lineHeight: '1.5rem',
        },
        semiLargeHeading: {
          fontSize: '1.5rem',
          lineHeight: '1.75rem',
        },
        largeHeading: {
          fontSize: '2rem',
          lineHeight: '2.25rem',
        },
        extraLargeHeading: {
          fontSize: '2.5rem',
        },
      },
      variants: {
        black: { color: 'fgPrimary' },
        blackTotal: { color: 'black' },
        gray: { color: 'fgSecondary' },
        grayTotal: { color: 'gray' },
        lightGray: { color: 'bgCompSecondaryTiny' },
        green: { color: 'fgSuccess' },
        orange: { color: 'fgWarning' },
        red: { color: 'fgDanger' },
        blue: { color: 'fgInteractive' },
        white: { color: 'fgPrimaryInverse' },
        whiteTotal: { color: 'white' },
      },
    },
    Textarea: {
      variants: {
        outline: {
          borderColor: 'brdSecondary',
          borderRadius: 'borderRadius02',
          color: 'fgPrimary',
          _disabled: {
            bg: 'bgCompSecondaryMid',
          },
          _invalid: {
            borderColor: 'brdDanger',
          },
          _placeholder: {
            color: 'fgSecondary',
          },
          _focusVisible: {
            borderColor: 'fgPrimary',
            boxShadow: 'none',
            _invalid: {
              borderColor: 'brdDanger',
            },
          },
        },
      },
    },
  },
  config,
  styles: {
    global: {
      body: {
        bg: 'bgPrimary',
      },
    },
  },
});
