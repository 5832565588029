import PropTypes from 'prop-types';

import { Typography } from 'shared/components/Typography';

export const SprintInfoContent = ({ children, dataTestid }) => {
  return (
    <Typography
      whiteSpace="nowrap"
      color="gray"
      type="small"
      fontWeight="regular"
      dataTestid={dataTestid}
    >
      {children}
    </Typography>
  );
};

SprintInfoContent.propTypes = {
  children: PropTypes.node,
  dataTestid: PropTypes.string,
};
