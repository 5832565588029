import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { Else, If, Then } from 'react-if';
import { useNavigate } from 'react-router-dom';

import { MULTIPLE_PROFILES_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';
import useToggle from 'shared_DEPRECATED/hooks/useToggle';

import {
  clearSubAccountFromLocalStorage,
  getSubAccountFromLocalStorage,
} from 'features/subAccounts/utils';
import { RenderIfParticipant } from 'features/user/components/RenderIfParticipant';

import { HeaderMenuContent } from './MenuContent';
import { HeaderMenuContent__OLD } from './MenuContent__OLD';
import { SubAccountsMenu } from './SubAccountsMenu';

type HeaderMenuPropTypes = {
  name: string;
  url: string;
  email: string;
};

const HeaderMenu = ({ name, email, url }: HeaderMenuPropTypes) => {
  const { logout } = useAuth0();

  const isMultipleProfilesFFEnabled = useFeatureFlag(
    MULTIPLE_PROFILES_FEATURE_FLAG
  );

  const isLoggedInWithSubAccount = !!getSubAccountFromLocalStorage();

  const [isOpen, toggleOpen] = useToggle();
  const navigate = useNavigate();

  const navigateToSettingsPage = useCallback(() => {
    toggleOpen();
    navigate('coach/settings');
  }, [toggleOpen, navigate]);

  const handleLogout = useCallback(() => {
    toggleOpen();
    clearSubAccountFromLocalStorage();
    logout({ returnTo: window.location.origin });
  }, [toggleOpen, logout]);

  return (
    <If condition={isMultipleProfilesFFEnabled}>
      <Then>
        <HeaderMenuContent
          navigateToSettingsPage={navigateToSettingsPage}
          handleLogout={handleLogout}
          name={name}
          email={isLoggedInWithSubAccount ? null : email}
          profileUrl={url}
        >
          <RenderIfParticipant>
            <SubAccountsMenu />
          </RenderIfParticipant>
        </HeaderMenuContent>
      </Then>
      <Else>
        {/*eslint-disable-next-line react/jsx-pascal-case */}
        <HeaderMenuContent__OLD
          isOpen={isOpen}
          toggleOpen={toggleOpen}
          navigateToSettingsPage={navigateToSettingsPage}
          handleLogout={handleLogout}
          name={name}
          email={email}
          url={url}
        />
      </Else>
    </If>
  );
};

export default HeaderMenu;
