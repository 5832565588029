import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useMemo } from 'react';

import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { EditableUserInfo } from 'features/user/config/types';
import { useEditUserInfo } from 'features/user/hooks/useEditInfo';

import { ButtonIcon, VARIANTS } from 'shared/components/Button';

type TUserEditButtonProps = {
  email: string;
  firstName: string;
  lastName: string;
  dataTestid?: string;
  mutateFunc: UseMutateAsyncFunction<
    void,
    unknown,
    { updatedUserInfo: EditableUserInfo }
  >;
};

export const UserEditButton = ({
  email,
  firstName,
  lastName,
  dataTestid,
  mutateFunc,
}: TUserEditButtonProps) => {
  const prevUserInfo = useMemo(
    () => ({ firstName, lastName }),
    [firstName, lastName]
  );

  const { onEditUserInfoClick } = useEditUserInfo({
    userEmail: email,
    prevUserInfo,
    mutateFunc: mutateFunc,
  });

  return (
    <ButtonIcon
      ariaLabel="edit user info"
      onClick={onEditUserInfoClick}
      variant={VARIANTS.TERTIARY}
      dataTestid={dataTestid}
    >
      <Icon name={iconNames.pen} width={14} height={14} />
    </ButtonIcon>
  );
};
