import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import ChallengeTargetAmountDialogPeriodField from 'features/challenge/components/TargetAmount/Dialog/PeriodField';
import { ChallengeTargetAmountDialogUnitsField } from 'features/challenge/components/TargetAmount/Dialog/UnitsField';
import { ChallengeTargetAmountDialogValueField } from 'features/challenge/components/TargetAmount/Dialog/ValueField';

export const ChallengeTargetAmountDialogBody = () => {
  const { setValue } = useFormContext();
  const reactHookFormConfig = useMemo(
    () => ({
      onBlur: (e: React.FocusEvent<HTMLInputElement>) =>
        Number(e.target.value) < 1 && setValue('value', 1),
    }),
    [setValue]
  );

  return (
    <>
      <ChallengeTargetAmountDialogValueField
        min={1}
        reactHookFormConfig={reactHookFormConfig}
      />
      <Spacers sizes={['zr zr lg', 'zr zr md']} />
      <Spacer size="zr xsm">
        <ChallengeTargetAmountDialogUnitsField />
      </Spacer>
      <ChallengeTargetAmountDialogPeriodField />
      <Spacers sizes={['zr zr xsm']} />
    </>
  );
};
