import {
  createContext,
  useContext,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';

import { useSidebarContext } from 'shared';

import { IBaseChallenge } from 'features/challenge/config/types';
import {
  useJournalFilterContext,
  createJournalChallengeFilterState,
  useJournalMetadataQuery,
} from 'features/journal';
import { SprintsType } from 'features/sprint/config/types';
import { useSprints } from 'features/sprint/hooks';

type JournalChallengeFilterContextType = {
  journalChallengeFilterState: { [key: string]: boolean };
  handleAllChallengesClick: () => void;
  handleChallengeClick: (challengeId: string) => void;
  handleWithoutChallengesClick: () => void;
  currentSprintChallenges: IBaseChallenge[];
};

type JournalChallengeFilterContextProviderProps = {
  children: ReactNode;
};

const JournalChallengeFilterContext = createContext<
  JournalChallengeFilterContextType | undefined
>(undefined);

export const JournalChallengeFilterProvider = ({
  children,
}: JournalChallengeFilterContextProviderProps) => {
  const { participantEmail, participantId } = useSidebarContext();

  const { data: sprints = [] } = useSprints(participantEmail) as {
    data: SprintsType;
  };

  const [currentSprint] = sprints;

  const { data: { challenges } = { challenges: [] } } = useJournalMetadataQuery(
    { participantId }
  );
  const { queryParams, onQueryParamsChange } = useJournalFilterContext();

  const selectedChallengeIds = useMemo(
    () => queryParams.challengeIds || [],
    [queryParams.challengeIds]
  );

  const handleAllChallengesClick = useCallback(() => {
    onQueryParamsChange({
      challengeIds: challenges.map((challenge) => challenge.challengeId),
      sprintIds: getSprintIds(queryParams, currentSprint.sprintId),
      withoutChallenges: false,
    });
  }, [onQueryParamsChange, challenges, queryParams, currentSprint]);

  const handleChallengeClick = useCallback(
    (challengeId: string) => {
      const isChallengePresent = selectedChallengeIds.includes(challengeId);

      onQueryParamsChange({
        challengeIds: isChallengePresent
          ? selectedChallengeIds.filter((id) => id !== challengeId)
          : [...selectedChallengeIds, challengeId],
        sprintIds: getSprintIds(queryParams, currentSprint?.sprintId),
        withoutChallenges: false,
      });
    },
    [selectedChallengeIds, onQueryParamsChange, queryParams, currentSprint]
  );

  const handleWithoutChallengesClick = useCallback(() => {
    onQueryParamsChange({
      challengeIds: [],
      withoutChallenges: true,
      sprintIds: getSprintIds(queryParams, currentSprint?.sprintId),
    });
  }, [onQueryParamsChange, queryParams, currentSprint]);

  const journalChallengeFilterState = useMemo(
    () => createJournalChallengeFilterState(selectedChallengeIds, challenges),
    [selectedChallengeIds, challenges]
  );

  const providerValue = useMemo(
    () => ({
      journalChallengeFilterState,
      handleAllChallengesClick,
      handleChallengeClick,
      handleWithoutChallengesClick,
      currentSprintChallenges: challenges,
    }),
    [
      journalChallengeFilterState,
      handleAllChallengesClick,
      handleChallengeClick,
      handleWithoutChallengesClick,
      challenges,
    ]
  );

  return (
    <JournalChallengeFilterContext.Provider value={providerValue}>
      {children}
    </JournalChallengeFilterContext.Provider>
  );
};

export const useJournalChallengeFilterContext = () => {
  const context = useContext(JournalChallengeFilterContext);

  if (!context) {
    throw new Error(
      'useJournalChallengeFilterContext must be used within JournalChallengeFilterContext.Provider'
    );
  }

  return context;
};

const getSprintIds = (
  queryParams: { sprintIds: string[] },
  currentSprintId: string
) => {
  //at least one sprintId is required when we select challenge filter
  return queryParams.sprintIds.length
    ? [...queryParams.sprintIds]
    : [currentSprintId];
};
