import { VStack } from '@chakra-ui/react';
import { For } from 'react-loops';

import { GoalExtended } from 'features/goal/config/types';

import { GoalPrioritizeModalListItem } from './ListItem';

type GoalsListProps = {
  goals: GoalExtended[];
  onGoalToggle: (goalId: string) => void;
};

export const GoalPrioritizeModalList = ({
  goals,
  onGoalToggle,
}: GoalsListProps) => (
  <VStack alignItems="flex-start" gap="var(--spacing02)">
    <For of={goals}>
      {(goal) => (
        <GoalPrioritizeModalListItem
          goalId={goal.goalId}
          title={goal.title}
          imageUrl={goal.imageUrl}
          dimensions={goal.dimensions}
          isDisabled={goal.isDisabled}
          isChecked={goal.isChecked}
          onGoalToggle={onGoalToggle}
        />
      )}
    </For>
  </VStack>
);
