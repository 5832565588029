import {
  createContext,
  useContext,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';

import { useSidebarContext } from 'shared';

import {
  createJournalSprintFilterState,
  JOURNAL_CHALLENGE_FILTERS,
  useJournalFilterContext,
  useJournalMetadataQuery,
} from 'features/journal';
import { IFinalSprint } from 'features/sprint/config/types';

type JournalSprintFilterContextType = {
  currentSprint: IFinalSprint;
  pastSprints: IFinalSprint[];
  handleAllSprintsClick: () => void;
  handleCurrentSprintClick: () => void;
  handlePastSprintClick: (sprintId: string) => void;
  journalSprintFilterState: { [key: string]: boolean };
};

type JournalSprintFilterContextProviderProps = {
  children: ReactNode;
};

const JournalSprintFilterContext = createContext<
  JournalSprintFilterContextType | undefined
>(undefined);

export const JournalSprintFilterProvider = ({
  children,
}: JournalSprintFilterContextProviderProps) => {
  const { participantId } = useSidebarContext();

  const { data } = useJournalMetadataQuery({ participantId });
  const { queryParams, onQueryParamsChange } = useJournalFilterContext();

  const { sprints = [] } = data || {};
  const [currentSprint, ...pastSprints] = sprints;

  const handleAllSprintsClick = useCallback(
    () => onQueryParamsChange({ sprintIds: [JOURNAL_CHALLENGE_FILTERS.ALL] }),
    [onQueryParamsChange]
  );

  const handleCurrentSprintClick = useCallback(() => {
    onQueryParamsChange({ sprintIds: [currentSprint?.sprintId] });
  }, [currentSprint, onQueryParamsChange]);

  const handlePastSprintClick = useCallback(
    (sprintId: string) => {
      const { sprintIds } = queryParams;

      const isSelected = sprintIds.includes(sprintId);
      const filteredSprintIds = sprintIds.filter(
        (id) => id !== JOURNAL_CHALLENGE_FILTERS.ALL && id !== sprintId
      );

      const updatedSprintIds = isSelected
        ? filteredSprintIds
        : [...filteredSprintIds, sprintId];

      onQueryParamsChange({ sprintIds: updatedSprintIds });
    },
    [queryParams, onQueryParamsChange]
  );

  const journalSprintFilterState = useMemo(
    () =>
      createJournalSprintFilterState(
        queryParams.sprintIds,
        currentSprint,
        pastSprints
      ),
    [queryParams.sprintIds, currentSprint, pastSprints]
  );

  const providerValue = useMemo(
    () => ({
      currentSprint,
      pastSprints,
      handleAllSprintsClick,
      handleCurrentSprintClick,
      handlePastSprintClick,
      journalSprintFilterState,
    }),
    [
      currentSprint,
      pastSprints,
      handleAllSprintsClick,
      handleCurrentSprintClick,
      handlePastSprintClick,
      journalSprintFilterState,
    ]
  );

  return (
    <JournalSprintFilterContext.Provider value={providerValue}>
      {children}
    </JournalSprintFilterContext.Provider>
  );
};

export const useJournalSprintFilterContext = () => {
  const context = useContext(JournalSprintFilterContext);

  if (!context) {
    throw new Error(
      'useJournalSprintFilterContext must be used within useJournalSprintFilterContext.Provider'
    );
  }

  return context;
};
