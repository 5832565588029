import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import BaseInput from 'shared_DEPRECATED/components/Form/Input/BaseInput';

const NumberInputPropTypes = {
  /**
   * @type {string}
   * @memberof NumberInputPropTypes
   * @name defaultValue
   * @example <BaseInput defaultValue="John Doe" />
   */
  defaultValue: PropTypes.string,
  /**
   * @type {string}
   * @memberof NumberInputPropTypes
   * @name name
   * @example <BaseInput name="John Doe" />
   */
  name: PropTypes.string.isRequired,
  /**
   * @type {string}
   * @memberof NumberInputPropTypes
   * @name placeholder
   * @example <BaseInput placeholder="John Doe" />
   */
  placeholder: PropTypes.string,
  /**
   * @type {('sm'|'lg')}
   * @required
   * @memberof NumberInputPropTypes
   * @name size
   * @example <BaseInput size="sm" />
   */
  size: PropTypes.oneOf(['sm', 'lg']),
  /**
   * @type {boolean}
   * @required
   * @memberof NumberInputPropTypes
   * @name error
   * @example <BaseInput error />
   */
  error: PropTypes.bool,
};

const NumberInput = forwardRef(({ name, placeholder = '', ...props }, ref) => (
  <BaseInput
    name={name}
    placeholder={placeholder}
    ref={ref}
    type="number"
    {...props}
  />
));

NumberInput.propTypes = NumberInputPropTypes;

export default NumberInput;
