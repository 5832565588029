import { VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useJournalAnalytics } from 'app/analytics/useJournalAnalytics';
import { Flex } from 'shared';

import {
  JournalAddNoteFormChallengesSection,
  JournalAddNoteFormPostToFeedSection, // JournalAddNoteFormFailReasonsSection,
  JournalAddNoteFormAttachMediaSection,
  JournalAddNoteFormTextSection,
  JournalAddNoteDialogFooter,
  JournalAddNoteDialogHeader,
  JOURNAL_NOTE_DEFAULT_VALUE,
  JournalNotePOSTType,
} from 'features/journal';

import { ModalBody, ModalHeader, ModalFooter } from 'shared/components/Modal';

type TJournalNoteAddDialog = {
  onSubmit: (note: JournalNotePOSTType) => void;
  defaultValues?: JournalNotePOSTType;
};

export const JournalNoteAddDialog = ({
  onSubmit,
  defaultValues = JOURNAL_NOTE_DEFAULT_VALUE,
}: TJournalNoteAddDialog) => {
  const methods = useForm<JournalNotePOSTType>({
    defaultValues,
  });

  const { sendNoteAddedAnalyticsEvent } = useJournalAnalytics();

  const onSubmitButtonClick = methods.handleSubmit(
    (note: JournalNotePOSTType) => {
      onSubmit(note);

      sendNoteAddedAnalyticsEvent();
    }
  );

  useEffect(() => {
    return () => {
      methods.reset();
    };
  }, [methods]);

  return (
    <>
      <ModalHeader>
        <JournalAddNoteDialogHeader />
      </ModalHeader>
      <FormProvider {...methods}>
        <ModalBody>
          <VStack width="100%">
            <JournalAddNoteFormTextSection />
            <JournalAddNoteFormChallengesSection />
            {/* <JournalAddNoteFormFailReasonsSection /> */}
            <JournalAddNoteFormAttachMediaSection />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <JournalAddNoteFormPostToFeedSection />
            <JournalAddNoteDialogFooter onSubmit={onSubmitButtonClick} />
          </Flex>
        </ModalFooter>
      </FormProvider>
    </>
  );
};

export default JournalNoteAddDialog;
