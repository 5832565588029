import { Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type SprintBadgePropTypes = {
  children: string;
  dataTestid?: string | null;
};
export const SprintBadge = ({ children, dataTestid }: SprintBadgePropTypes) => (
  <Badge bgColor="gray" size="xsm">
    <Typography
      as="span"
      type="small"
      color="black"
      fontWeight="regular"
      data-testid={dataTestid}
      whiteSpace="nowrap"
    >
      {children}
    </Typography>
  </Badge>
);
