import { CoachSidebarClosed } from 'app/layout/components/Layout/CoachSidebar/CoachSidebarClosed';
import { CoachSidebarOpen } from 'app/layout/components/Layout/CoachSidebar/CoachSidebarOpen';
import { useSidebarCollapseContext } from 'app/layout/context/drawer';
import { AnimatePresence } from 'framer-motion';

const CoachSidebar = () => {
  const { isOpen } = useSidebarCollapseContext();

  return (
    <AnimatePresence mode="wait">
      {isOpen ? <CoachSidebarOpen /> : <CoachSidebarClosed />}
    </AnimatePresence>
  );
};

export default CoachSidebar;
