import { When } from 'react-if';

import {
  Badge,
  Flex,
  HStack,
  TruncateMarkup,
  Typography,
  VStack,
} from 'shared';
import {
  FloatingTooltip as Tooltip,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { capitalize } from 'shared_DEPRECATED/utils';

import { ChallengeGoalsType } from 'features/challenge/config/types';

import { partitionItems } from 'shared/utils/array';

type TChallengeInfoGoalsProps = {
  goals: ChallengeGoalsType;
};

export const ChallengeInfoGoals = ({ goals }: TChallengeInfoGoalsProps) => {
  if (!goals || !goals.length) {
    return null;
  }
  const { firstItems, secondItems, remainingCount } = partitionItems(goals);

  return (
    <>
      <Spacer size="sm zr zr" />
      <Flex gap="0.25rem" flexWrap="wrap">
        {firstItems.map(({ goalId, title, prioritized }) => (
          <Badge key={goalId} whiteSpace="wrap">
            <HStack>
              <When condition={prioritized}>
                <span>
                  <Icon
                    name={iconNames.flagNew}
                    fill="var(--fgInteractive)"
                    stroke="var(--fgInteractive)"
                    width={14}
                  />
                </span>
              </When>
              <TruncateMarkup ellipsis={<span>...</span>}>
                <span title={title}>{capitalize(title)}</span>
              </TruncateMarkup>
            </HStack>
          </Badge>
        ))}
        {remainingCount > 0 ? (
          <Tooltip
            anchor={<Badge>+{remainingCount} more</Badge>}
            offsetValue={8}
            placement={PLACEMENTS.BOTTOM_END}
          >
            <VStack alignItems="start">
              {secondItems.map(({ goalId, title, prioritized }) => (
                <Badge key={goalId} whiteSpace="wrap" bgColor="lightGray">
                  <Typography color="white" type="small" fontWeight="medium">
                    <HStack>
                      <When condition={prioritized}>
                        <span>
                          <Icon
                            name={iconNames.flagNew}
                            fill="var(--fgInteractive)"
                            stroke="var(--fgInteractive)"
                            width={14}
                          />
                        </span>
                      </When>
                      <TruncateMarkup ellipsis={<span>...</span>}>
                        <span title={title}>{capitalize(title)}</span>
                      </TruncateMarkup>
                    </HStack>
                  </Typography>
                </Badge>
              ))}
            </VStack>
          </Tooltip>
        ) : null}
      </Flex>
    </>
  );
};
