import { VARIANTS } from 'shared';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { sharedSprintLinks } from 'features/sprint/utils';

import ButtonLink from 'shared/components/Button/ButtonLink';
import { Typography } from 'shared/components/Typography';

export const ParticipantAccessDenied = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <FlexItem>
        <Typography as="div" type="largeHeading" textAlign="center">
          🙈
        </Typography>
        <Spacer size="sm zr" />
        <Typography
          as="h1"
          type="smallHeading"
          fontWeight="semiBold"
          textAlign="center"
        >
          You don't have access to this page.
        </Typography>
        <Spacer size="xsm zr" />
        <Typography color="gray" type="meta" textAlign="center">
          This user is not in your participants list.
        </Typography>
        <Spacer size="sm zr" />
        <ButtonLink
          to={sharedSprintLinks.participants}
          variant={VARIANTS.PRIMARY}
        >
          Go to Participants page
        </ButtonLink>
      </FlexItem>
    </Flex>
  );
};
