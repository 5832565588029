import { useCallback, useMemo } from 'react';

import { localStorage } from 'shared/services/localStorage';

import { EnrichedSubAccount } from '../types';
import { SUB_ACCOUNT_STORAGE_KEY } from '../utils';
import { useSubAccountsQuery } from './query/useSubAccountsQuery';

export const useSubAccounts = () => {
  const { data } = useSubAccountsQuery();

  const selectedAccount = localStorage.getItem<{
    userId: string;
    groupId: string;
  }>(SUB_ACCOUNT_STORAGE_KEY);

  const subAccounts = useMemo(() => {
    return data.map((account, index) => ({
      ...account,
      isSelected:
        (!selectedAccount && index === 0) ||
        account.userId === selectedAccount?.userId,
      isMain: account.accountType === 'main',
      pictureUrl: account.picture.url,
    }));
  }, [data, selectedAccount]);

  const handleSubAccountSelect = useCallback(
    ({ userId, groupId, isMain }: EnrichedSubAccount) => {
      const isSelected = selectedAccount?.userId === userId;
      if (isSelected) {
        return;
      }
      if (isMain) {
        localStorage.removeItem(SUB_ACCOUNT_STORAGE_KEY);
      } else {
        localStorage.setItem(SUB_ACCOUNT_STORAGE_KEY, {
          userId,
          groupId,
        });
      }
      window.location.replace('/sprints');
    },
    [selectedAccount]
  );

  return {
    subAccounts,
    handleSubAccountSelect,
  };
};
