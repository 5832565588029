import { Picture } from 'shared_DEPRECATED/types/Picture';
import { UserMode, USER_TYPES } from 'shared_DEPRECATED/types/User';

import { IUser } from './config/types';

export class User implements IUser {
  readonly userId: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  type: UserMode;
  picture: Picture;
  timezone: string;
  assessmentAvailable: boolean;
  assessmentRequired: boolean;
  timezoneChangeBehavior: 'automatic' | 'manual';
  accountabilityNotifications: 'enabled' | 'disabled';
  featureFlags: Record<string, boolean>;
  accentColorBehavior: 'dynamic' | 'user_set';
  accentColor:
    | 'default'
    | 'dimension_health'
    | 'dimension_spirituality'
    | 'dimension_impact'
    | 'dimension_enjoyment'
    | 'dimension_financial'
    | 'dimension_career'
    | 'dimension_relationships';
  roadmapAvailable: boolean;

  constructor(user: IUser) {
    this.userId = user.userId;
    this.email = user.email;
    this.name = user.name;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.type = user.type;
    this.picture = user.picture;
    this.timezone = user.timezone;
    this.assessmentAvailable = user.assessmentAvailable;
    this.assessmentRequired = user.assessmentRequired;
    this.timezoneChangeBehavior = user.timezoneChangeBehavior;
    this.accountabilityNotifications = user.accountabilityNotifications;
    this.featureFlags = user.featureFlags;
    this.accentColorBehavior = user.accentColorBehavior;
    this.accentColor = user.accentColor;
    this.roadmapAvailable = user.roadmapAvailable;
  }

  get isCoach(): boolean {
    return this.type === USER_TYPES.COACH || this.type === USER_TYPES.ADMIN;
  }
}
