import { When } from 'react-if';

import { USER_TYPES } from 'shared_DEPRECATED/types/User';

import { useUserContext } from 'features/user/context';
import { useUserMode } from 'features/user/hooks';

import { USER_MODES } from '../config';

type RenderIfParticipantProps = {
  children: React.ReactNode;
};

export const RenderIfParticipant = ({ children }: RenderIfParticipantProps) => {
  const { user } = useUserContext();
  const userMode = useUserMode();
  const isParticipant = user?.type === USER_TYPES.USER;
  const isParticipantMode = userMode === USER_MODES.dashboard;

  return <When condition={isParticipant || isParticipantMode}>{children}</When>;
};
