import { useFormContext } from 'react-hook-form';

import NumberInput from 'shared_DEPRECATED/components/Form/Input/NumberInput';

type ChallengeTargetAmountDialogValueFieldPropTypes = {
  min?: number;
  reactHookFormConfig?: Record<string, unknown>;
};

export const ChallengeTargetAmountDialogValueField = ({
  min = 0,
  reactHookFormConfig,
}: ChallengeTargetAmountDialogValueFieldPropTypes) => {
  const { register, setValue } = useFormContext();

  if (!reactHookFormConfig) {
    reactHookFormConfig = {
      onBlur: (e: React.FocusEvent<HTMLInputElement>) =>
        (!e.target.value || Number(e.target.value) < 0) && setValue('value', 0),
      setValueAs: (value: string) => parseInt(value, 10),
    };
  }

  return (
    <NumberInput
      min={min}
      // @ts-ignore
      placeholder="0"
      {...register('value', reactHookFormConfig)}
    />
  );
};
