import PropTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';

import { noop } from 'shared_DEPRECATED/utils/function';

import './SortableList.css';

const SortableListPropTypes = {
  /**
   * Array of items that will be sorted.
   * @type {array}
   * @memberof SortableListPropTypes
   * @required
   * @name list
   * @example <SortableList list={[{id: 1},{id: 2}]} />
   */
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      chosen: PropTypes.bool,
      filtered: PropTypes.bool,
    })
  ),
  /**
   * The function to call when order of items is set.
   * @type {function}
   * @memberof SortableListPropTypes
   * @name setList
   * @param {list[]} updatedList
   * @returns {void}
   * @example <SortableList setList={() => {}} />
   */
  setList: PropTypes.func,
  /**
   * The function to call after item is moved.
   * @type {function}
   * @memberof SortableListPropTypes
   * @name onMove
   * @param {{oldIndex: number ,newIndex: number}} value
   * @returns {void}
   * @example <SortableList onMove={({oldIndex,newIndex}) => {}} />
   */
  onMove: PropTypes.func,
  /**
   * Tag name of list wrapper.
   * @type {string}
   * @name tag
   * @default ul
   * @memberof SortableListPropTypes
   * @example <SortableList tag="div"></SortableList>
   */
  as: PropTypes.string,
  /**
   * Items to sort.
   * @type {React.ReactNode}
   * @required
   * @name children
   * @example <SortableList><SortableListItem /></SortableList>
   */
  children: PropTypes.node.isRequired,
};

export const SortableList = ({
  list,
  setList = noop,
  children,
  onMove = noop,
  as = 'ul',
}) => (
  <ReactSortable
    tag={as}
    handle=".drag-handler"
    list={list}
    setList={setList}
    onEnd={(evt) => {
      onMove({ oldIndex: evt.oldIndex, newIndex: evt.newIndex });
    }}
  >
    {children}
  </ReactSortable>
);

SortableList.propTypes = SortableListPropTypes;
