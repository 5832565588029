import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';

import {
  PlanningBacklog,
  usePlanningDraftSprintsQuery,
  PlanningBoardWrapper,
  BOARD_COLUMN_WIDTH,
  PlanningActiveFinishedSprints,
  usePlanningBacklogQuery,
} from 'features/planning';
import { PlanningDraftSprints } from 'features/planning/components/Board/DraftSprints';
import { useSprintsQuery } from 'features/sprint/hooks';

export const PlanningBoard = () => {
  const { isLoading: isDraftSprintsLoading } = usePlanningDraftSprintsQuery();
  const { isLoading: areSprintsLoading } = useSprintsQuery();
  const { isLoading: isBacklogLoading } = usePlanningBacklogQuery();

  if (isDraftSprintsLoading || areSprintsLoading || isBacklogLoading) {
    return <Loader />;
  }

  return (
    <PlanningBoardWrapper>
      <Flex height="100%" gap="0.5rem">
        <PlanningBacklog />
        <PlanningActiveFinishedSprints pinned />
        <PlanningDraftSprints pinned />
      </Flex>
      <Box
        style={{
          width: `calc(100% - ${BOARD_COLUMN_WIDTH})`,
          height: '100%',
          overflowX: 'auto',
        }}
      >
        <Flex height="100%" width="100%" gap="0.5rem">
          <PlanningActiveFinishedSprints pinned={false} />
          <PlanningDraftSprints pinned={false} />
        </Flex>
      </Box>
    </PlanningBoardWrapper>
  );
};
