import { Button, VARIANTS } from 'shared';

import { useFeedback } from 'features/feedback/hooks';

export const FeedbackButton = () => {
  const { openFeedbackDialog } = useFeedback();

  return openFeedbackDialog ? (
    <Button onClick={openFeedbackDialog} variant={VARIANTS.PRIMARY} size="xs">
      Feedback
    </Button>
  ) : (
    <></>
  );
};
