import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { toastConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import { EditableUserInfo } from 'features/user/config/types';
import { useOpenEditUserInfoModal } from 'features/user/hooks/useOpenEditInfoModal';

type TUseEditUserInfo = {
  userEmail: string;
  prevUserInfo: EditableUserInfo;
  mutateFunc: UseMutateAsyncFunction<
    void,
    unknown,
    { updatedUserInfo: EditableUserInfo }
  >;
};

export const useEditUserInfo = ({
  userEmail,
  prevUserInfo,
  mutateFunc,
}: TUseEditUserInfo) => {
  const onSubmit = async (updatedUserInfo: EditableUserInfo) => {
    let toastId = toast.loading('Updating...', toastConfig);

    try {
      await mutateFunc({ updatedUserInfo });

      toast.update(toastId, {
        render: 'Profile was updated successfully!',
        type: 'success',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
    } catch (err) {
      toast.update(toastId, {
        render:
          "There's an error with profile updating. Please try again later.",
        type: 'error',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
    }
  };

  const openEditUserInfoModal = useOpenEditUserInfoModal({
    userEmail,
    prevUserInfo,
  });

  return {
    onEditUserInfoClick: () => openEditUserInfoModal({ onSubmit }),
  };
};
