import { Flex } from '@chakra-ui/react';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalBreakdownEmptyState,
  GoalBreakdownBoardChallenges,
  GoalBreakdownBoardFocusAreas
} from 'features/goal';
import { useGoalBreakdownDataContext } from 'features/goal/context/goalBreakdown';

import { BoardColumn } from 'shared/components/Board';

export const GoalBreakdownContent = () => {
  const { goalBreakdownData } = useGoalBreakdownDataContext();
  const isGoalBreakdownFilled =
    goalBreakdownData?.challenges.length ||
    goalBreakdownData?.focusAreas.length;

  return (
    <BoardColumn>
      {!isGoalBreakdownFilled ? (
        <GoalBreakdownEmptyState />
      ) : (
        <>
          <Box style={{ width: '100%', height: '100%' }}>
            <Spacer size="sm" height="100%">
              <Flex flexDirection="column" height="100%">
                <GoalBreakdownBoardFocusAreas />
                <Flex grow={1} shrink={0} flexDirection="column">
                  <GoalBreakdownBoardChallenges />
                </Flex>
              </Flex>
            </Spacer>
          </Box>
        </>
      )}
    </BoardColumn>
  );
};
