import { Nullable } from 'shared_DEPRECATED/types';
import { Picture } from 'shared_DEPRECATED/types/Picture';

import {
  ChallengeGoalsType,
  IBaseChallenge,
  TChallengeLocation,
} from 'features/challenge/config/types';

export class BaseChallenge implements IBaseChallenge {
  description: string;
  dimensions: string[];
  emoji: Nullable<string>;
  endDate?: string;
  goals: ChallengeGoalsType;
  passes?: number;
  private: boolean;
  proofRequired: boolean;
  picture: Nullable<Picture>;
  readonly source: {
    curatedChallengeId: Nullable<string>;
    customChallengeId: Nullable<string>;
  };
  readonly challengeId: string;
  readonly customChallengeId?: string;
  readonly sprintChallengeId: string;
  startDate?: string;
  readonly tags: string[];
  title: string;
  isLatestChallengeVersion: boolean;
  reminder: Nullable<string>;
  sprintId?: string;
  location?: TChallengeLocation;

  constructor(challenge: IBaseChallenge) {
    this.description = challenge.description;
    this.dimensions = challenge.dimensions;
    this.emoji = challenge.emoji;
    this.endDate = challenge.endDate;
    this.goals = challenge.goals;
    this.passes = challenge.passes || 0;
    this.picture = challenge.picture;
    this.source = challenge.source;
    this.sprintChallengeId = challenge.sprintChallengeId;
    this.startDate = challenge.startDate;
    this.tags = challenge.tags;
    this.title = challenge.title;
    this.isLatestChallengeVersion = challenge.isLatestChallengeVersion;
    this.private = challenge.private;
    this.proofRequired = challenge.proofRequired;
    this.reminder = challenge.reminder;
    this.challengeId = challenge.challengeId;
    this.customChallengeId = challenge.customChallengeId;
    this.sprintId = challenge.sprintId;
    this.location = challenge.location;
  }
}
