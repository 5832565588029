import { For } from 'react-loops';

import { Flex } from 'shared';

import {
  JournalFilterButton,
  JournalFilterSectionLabel,
  JournalFilterLabel,
  useJournalSprintFilterContext,
  JOURNAL_SPRINT_FILTERS,
} from 'features/journal';

export const JournalFilterBySprint = () => {
  const {
    pastSprints,
    handleAllSprintsClick,
    handleCurrentSprintClick,
    handlePastSprintClick,
    journalSprintFilterState,
  } = useJournalSprintFilterContext();

  return (
    <>
      <JournalFilterLabel>By sprint</JournalFilterLabel>
      <Flex gap="8px">
        <JournalFilterButton
          isActive={!!journalSprintFilterState[JOURNAL_SPRINT_FILTERS.ALL]}
          onClick={handleAllSprintsClick}
        >
          All sprints
        </JournalFilterButton>
        <JournalFilterButton
          isActive={!!journalSprintFilterState[JOURNAL_SPRINT_FILTERS.CURRENT]}
          onClick={handleCurrentSprintClick}
        >
          Current sprint
        </JournalFilterButton>
      </Flex>
      <JournalFilterSectionLabel>Past sprints</JournalFilterSectionLabel>
      <Flex gap="8px" flexWrap="wrap">
        <For of={pastSprints}>
          {({ title, sprintId }) => (
            <JournalFilterButton
              isActive={
                !!journalSprintFilterState[
                  `${JOURNAL_SPRINT_FILTERS.PAST}-${sprintId}`
                ]
              }
              onClick={() => handlePastSprintClick(sprintId)}
            >
              {title}
            </JournalFilterButton>
          )}
        </For>
      </Flex>
    </>
  );
};
