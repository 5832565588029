import { When } from 'react-if';

import {
  BoardColumnPinButton,
  Button,
  ButtonGroup,
  VARIANTS,
  Flex,
  HStack,
  TYPOGRAPHY_COLORS,
} from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Nullable } from 'shared_DEPRECATED/types';

import {
  PLANNING_SPRINT_STATE_ICON_MAP,
  SPRINT_STATE_LABELS_WITH_SUB_STATE,
  SPRINT_STATE_UNFINISHED,
} from 'features/planning/config';
import { SPRINT_STATES } from 'features/sprint/config';
import { SprintStateValues } from 'features/sprint/config/types';

import { Typography } from 'shared/components/Typography';

type TPlanningSprintHeader = {
  title: string;
  sprintState: typeof SPRINT_STATES[keyof typeof SPRINT_STATES];
  sprintFormattedDate: string;
  sprintId: string;
  durationInWeeks: Nullable<number>;
  sprintFinishedEarlier?: boolean;
  onViewButtonClick: () => void;
  children?: React.ReactNode;
};

export const PlanningSprintHeader = ({
  title,
  sprintState,
  sprintFormattedDate,
  sprintId,
  durationInWeeks,
  onViewButtonClick,
  sprintFinishedEarlier,
  children,
}: TPlanningSprintHeader) => {
  const sprintStateExtended =
    sprintState === SPRINT_STATES.FINISHED && sprintFinishedEarlier
      ? SPRINT_STATE_UNFINISHED.KEY
      : sprintState;

  const { bg, color, iconColor } =
    PLANNING_SPRINT_HEADER_STYLES_MAP[sprintStateExtended];

  return (
    <Flex
      data-testid="planning-sprint-board-header"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <Flex
        justifyContent="space-between"
        width="100%"
        p="8px 16px"
        alignItems="center"
      >
        <Flex alignItems="flex-start" flexDirection="column">
          <Typography color="black" fontWeight="medium" type="meta">
            {title}
          </Typography>
        </Flex>
        <ButtonGroup spacing="0.5rem" size="sm">
          <BoardColumnPinButton columnId={sprintId} />
          <Button onClick={onViewButtonClick} variant={VARIANTS.TERTIARY}>
            <Typography
              color="black"
              type="meta"
              fontWeight="medium"
              textDecoration="underline"
              sx={{
                textUnderlineOffset: '4px',
              }}
            >
              View
            </Typography>
          </Button>
        </ButtonGroup>
      </Flex>
      <Flex alignItems="center" width="100%" bg={bg} h={9} px={3} gap={3}>
        <HStack gap={1}>
          <Icon
            name={PLANNING_SPRINT_STATE_ICON_MAP[sprintStateExtended]}
            fill={iconColor}
          />
          <Typography
            color={color as TYPOGRAPHY_COLORS}
            fontWeight="regular"
            type="small"
          >
            {SPRINT_STATE_LABELS_WITH_SUB_STATE[sprintStateExtended]}
          </Typography>
        </HStack>
        <HStack gap={1}>
          <Icon name={iconNames.calendarMonth} fill={iconColor} />
          <Typography
            color={color as TYPOGRAPHY_COLORS}
            fontWeight="regular"
            type="small"
          >
            {sprintFormattedDate}
          </Typography>
        </HStack>
        <HStack gap={1}>
          <Icon name={iconNames.hourGlassTop} fill={iconColor} />
          <Typography
            color={color as TYPOGRAPHY_COLORS}
            fontWeight="regular"
            type="small"
          >
            {durationInWeeks} weeks
          </Typography>
        </HStack>
      </Flex>
      <When condition={!!children}>
        <Flex alignItems="center" width="100%" h="10" px={4} gap={1}>
          {children}
        </Flex>
      </When>
    </Flex>
  );
};

const PLANNING_SPRINT_HEADER_STYLES_MAP: Record<
  SprintStateValues & typeof SPRINT_STATE_UNFINISHED.KEY,
  { bg: string; color: TYPOGRAPHY_COLORS | string; iconColor: string }
> = {
  [SPRINT_STATES.ACTIVE]: {
    color: 'whiteTotal',
    iconColor: 'var(--white)',
    bg: 'var(--bgCompSuccess)',
  },
  [SPRINT_STATES.FINISHED]: {
    color: 'whiteTotal',
    iconColor: 'var(--white)',
    bg: 'var(--bgCompAccent)',
  },
  [SPRINT_STATE_UNFINISHED.KEY]: {
    color: 'var(--fgPrimary)',
    iconColor: 'var(--fgPrimary)',
    bg: 'var(--bgCompSecondaryMid)',
  },
};
