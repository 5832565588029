import { ButtonIcon, Box, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  ParticipantsTableActionWrapper,
  ParticipantsTableCounter,
} from 'features/participants/components/Table/Actions/';

type TParticipantTableNotesButton = {
  onClick: () => void;
  unreadCount: number;
};

export const ParticipantTableNotesButton = ({
  onClick,
  unreadCount,
}: TParticipantTableNotesButton) => (
  <ButtonIcon
    ariaLabel="open notes"
    icon={
      <ParticipantsTableActionWrapper>
        <Box position="relative">
          <Icon name={iconNames.message} width={18} height={18} />
          {unreadCount > 0 && <ParticipantsTableCounter value={unreadCount} />}
        </Box>
      </ParticipantsTableActionWrapper>
    }
    onClick={onClick}
    size="sm"
    variant={VARIANTS.TERTIARY}
    dataTestid="participant-notes-btn"
  />
);
