import PropTypes from 'prop-types';

import { LayoutPageSidebarWrapper } from 'app/layout/components/page/Sidebar/Wrapper';
import { SidebarCollapseProvider } from 'app/layout/context/drawer';

export const LayoutPageSidebar = ({ children }) => {
  return (
    <SidebarCollapseProvider>
      <LayoutPageSidebarWrapper>{children}</LayoutPageSidebarWrapper>
    </SidebarCollapseProvider>
  );
};

LayoutPageSidebar.propTypes = { children: PropTypes.node.isRequired };
