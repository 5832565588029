import {
  FloatingTooltip as Tooltip,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';

import { Typography } from 'shared/components/Typography';

type ChallengeTargetAmountAutomationTooltipPropTypes = {
  anchor: React.ReactNode;
};

export const ChallengeTargetAmountAutomationTooltip = ({
  anchor,
}: ChallengeTargetAmountAutomationTooltipPropTypes) => (
  <Tooltip anchor={anchor} offsetValue={8} placement={PLACEMENTS.TOP}>
    <Typography color="white" fontWeight="medium" type="small">
      Challenge progress is tracked automatically
    </Typography>
  </Tooltip>
);
