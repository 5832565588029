import { useJournalAnalytics } from 'app/analytics/useJournalAnalytics';

import {
  JournalCardReactions,
  useCreateJournalNoteReaction,
  useDeleteJournalNoteReaction,
  JournalReaction,
  useJournalMarkNoteAsReadMutation,
} from 'features/journal';

type TJournalNoteCardReactions = {
  noteId: string;
  reactions: JournalReaction[];
  isNoteRead: boolean;
};

export const JournalNoteCardReactions = ({
  noteId,
  reactions,
  isNoteRead,
}: TJournalNoteCardReactions) => {
  const { mutateAsync: addReaction } = useCreateJournalNoteReaction();
  const { mutateAsync: removeReaction } = useDeleteJournalNoteReaction();
  const { sendNoteReactionAddedAnalyticsEvent } = useJournalAnalytics();

  const { mutateAsync: markNoteAsRead } = useJournalMarkNoteAsReadMutation({
    noteId,
  });

  const handleAddReaction = async (reaction: string) => {
    await addReaction({ noteId, reaction });

    sendNoteReactionAddedAnalyticsEvent();

    !isNoteRead && (await markNoteAsRead());
  };

  const handleRemoveReaction = async (reactionId: number) => {
    const isReactionTemporary = reactions.some(
      (reaction) => reaction.temporaryReactionId === reactionId
    );

    !isReactionTemporary && (await removeReaction({ noteId, reactionId }));
  };

  return (
    <JournalCardReactions
      reactions={reactions}
      onAddReaction={(reaction: string) => handleAddReaction(reaction)}
      onRemoveReaction={(reactionId: number) =>
        handleRemoveReaction(reactionId)
      }
    />
  );
};
