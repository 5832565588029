import { TabList } from '@chakra-ui/react';

import { Flex } from 'shared';

import { PlanningBacklogHeaderTab } from 'features/planning';

export const PlanningBacklogHeader = () => (
  <Flex justifyContent="space-between" width="100%" height="100%" px="8px">
    <TabList>
      <PlanningBacklogHeaderTab>Challenges</PlanningBacklogHeaderTab>
      <PlanningBacklogHeaderTab>Coaching tools</PlanningBacklogHeaderTab>
    </TabList>
  </Flex>
);
