import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

const FormSection = ({ children }) => (
  <Spacer size="zr zr lg">
    <Spacer size="zr zr md">
      <Box style={{ borderBottom: 'var(--border-secondary)' }}>
        <Spacer size="zr zr lg">
          <Spacer size="zr zr md">{children}</Spacer>
        </Spacer>
      </Box>
    </Spacer>
  </Spacer>
);

FormSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormSection;
