import { useNavigate } from 'react-router-dom';

import { JOURNAL_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import { openNotesSidebar } from 'features/challengeNotes/utils';
import { openJournalSidebar } from 'features/journal';
import { ParticipantsTableActionDisabled } from 'features/participants/components/Table/Actions/';
import { ParticipantTableNotesButton } from 'features/participants/components/Table/Actions/Notes/Button';
import {
  ParticipantsTableLatestSprint,
  ParticipantsTableUserInfo,
} from 'features/participants/config/types';
import { useUserContext } from 'features/user/context';

type ParticipantsTableNotesPropTypes = {
  unreadNotes?: number;
  unreadNotesRepliesNumber: number;
  latestSprint: ParticipantsTableLatestSprint;
  userInfo: ParticipantsTableUserInfo;
};

export const ParticipantsTableNotes = ({
  unreadNotes,
  unreadNotesRepliesNumber,
  latestSprint,
  userInfo,
}: ParticipantsTableNotesPropTypes) => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const isJournalEnabled = useFeatureFlag(JOURNAL_FEATURE_FLAG);

  const handleNotesButtonClick = () => {
    if (latestSprint?.sprintId) {
      navigate(
        `/coach/participants/${userInfo.email}/${latestSprint.sprintId}`
      );
    }

    if (isJournalEnabled) {
      openJournalSidebar({
        user,
        participantId: userInfo.userId,
        participantEmail: userInfo.email,
      });

      return;
    }

    openNotesSidebar({
      sprint: latestSprint,
      user,
    });
  };

  if (isJournalEnabled) {
    return (
      <ParticipantTableNotesButton
        onClick={handleNotesButtonClick}
        unreadCount={unreadNotesRepliesNumber}
      />
    );
  }

  return typeof unreadNotes === 'number' ? (
    <ParticipantTableNotesButton
      onClick={handleNotesButtonClick}
      unreadCount={unreadNotes}
    />
  ) : (
    <ParticipantsTableActionDisabled
      label="User doesn't have an active or past sprints"
      icon={iconNames.message}
    />
  );
};
