import { FormControl, Switch, Flex, Typography } from 'shared';

import { useAddNotePostToFeedSectionConfig } from 'features/journal';

export const JournalAddNoteFormPostToFeedSection = () => {
  const { checked, onChange } = useAddNotePostToFeedSectionConfig();

  return (
    <FormControl>
      <Flex alignItems="center" gap="0.5rem">
        <Typography as="label" color="black" fontWeight="medium" type="meta">
          Post to feed
        </Typography>
        <Switch isChecked={checked} onChange={onChange} size="lg" />
      </Flex>
    </FormControl>
  );
};
