import { localStorage } from 'shared/services/localStorage';

export const SUB_ACCOUNT_STORAGE_KEY = 'sub-account';

export const getSubAccountFromLocalStorage = () =>
  localStorage.getItem<{ userId: string; groupId: string }>(
    SUB_ACCOUNT_STORAGE_KEY
  );

export const clearSubAccountFromLocalStorage = () =>
  localStorage.removeItem(SUB_ACCOUNT_STORAGE_KEY);
