import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import { JournalNote, useJournalQueryActions } from 'features/journal';

import { showErrorToast } from 'shared/components/Toast';

export const useDeleteJournalNoteReaction = () => {
  const { request } = useMutationHTTPRequest();

  const {
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
    updateJournalRelevantQueries,
  } = useJournalQueryActions();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ reactionId, noteId }: MutationFnPropType) =>
      await request({
        url: `/api/journal/notes/${noteId}/reactions/${reactionId}/delete`,
      }),
    {
      onMutate: ({ reactionId, noteId }: MutationFnPropType) => {
        const relevantQueries = getJournalRelevantQueries();
        cancelJournalRelevantQueries();

        const previousJournalNotesData = relevantQueries.map(([key, data]) => ({
          key,
          data,
        }));

        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: page.notes.map((note: JournalNote) => {
              if (note.noteId === noteId) {
                note.reactions.filter(
                  (reaction) => reaction.reactionId !== reactionId
                );
              }
              return note;
            }),
          }));
        });

        cancelJournalNoteQueries(noteId);

        const previousJournalNoteData = getPreviousJournalNoteValue(noteId);

        updateJournalNoteQueryData(noteId, (old: { note: JournalNote }) => {
          old.note.reactions = old.note.reactions.filter(
            (reaction) => reaction.reactionId !== reactionId
          );
        });

        return { previousJournalNotesData, previousJournalNoteData };
      },
      onSuccess: (newNote: JournalNote, { noteId }) => {
        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map(
            (page: { notes: JournalNote[] }) => ({
              ...page,
              notes: page.notes.map((note) => {
                if (note.noteId === noteId) {
                  return newNote;
                }

                return note;
              }),
            })
          );
        });

        updateJournalNoteQueryData(noteId, () => newNote);
      },
      onError: (err, { noteId }, context) => {
        showErrorToast(
          "There's an error with deleting reaction. Please try again later."
        );

        context?.previousJournalNotesData.forEach(({ key, data }: any) => {
          queryClient.setQueryData(key, data);
        });

        updateJournalNoteQueryData(
          noteId,
          () => context?.previousJournalNoteData
        );

        logError(err);
      },
    }
  );
};

type MutationFnPropType = {
  reactionId: number;
  noteId: string;
};
