import { Flex, Typography } from 'shared';

type TJournalNotesBodyHeader = {
  title: string;
  date: string;
};

export const JournalNotesBodyHeader = ({
  title,
  date,
}: TJournalNotesBodyHeader) => (
  <Flex flexDirection="column" alignItems="center" gap="4px">
    <Typography color="black" fontWeight="semiBold">
      {title}
    </Typography>
    <Typography color="gray" type="small">
      {date}
    </Typography>
  </Flex>
);
