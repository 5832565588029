import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import RoleProtectedRoute from 'app/RoleProtectedRoute';
import { ParticipantRouterErrorBoundary } from 'app/error';
import CoachSidebar from 'app/layout/components/Layout/CoachSidebar';
import {
  LayoutPageContent,
  LayoutPageSidebar,
} from 'app/layout/components/page';
import { SeoTitle } from 'app/seo/components/Title';
import { PlanningWithFeatureFlag } from 'featureFlags/components/PlanningFeatureFlag';
import Box from 'shared_DEPRECATED/components/Box';
import Loader from 'shared_DEPRECATED/components/Loader';
import { USER_TYPES } from 'shared_DEPRECATED/types/User';
import { routeNames } from 'shared_DEPRECATED/utils/config';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import SprintForm from 'features/sprint/components/Form/SprintForm';
import { CurrentSprintProvider } from 'features/sprint/context/currentSprint';

const Goals = lazy(() =>
  lazyRetry(() => import('app/pages/Goals/Goals'), 'Goals')
);

const GoalPage = lazy(() =>
  lazyRetry(() => import('app/pages/Goal/Goal'), 'Goal')
);

const DashboardPage = lazy(() =>
  lazyRetry(() => import('app/pages/Assessments'), 'Assessments')
);

const ParticipantsPage = lazy(() =>
  lazyRetry(() => import('app/pages/coach/Participants'), 'Participants')
);

const ParticipantSprintPage = lazy(() =>
  lazyRetry(
    () => import('app/pages/coach/ParticipantSprint'),
    'ParticipantSprint'
  )
);

const CoachSettingsPage = lazy(() =>
  lazyRetry(() => import('app/pages/coach/Settings/Settings'), 'CoachSettings')
);

const CoachClientDashboardPage = lazy(() =>
  lazyRetry(
    () => import('app/pages/coach/ParticipantDashboard'),
    'CoachClientDashboard'
  )
);

const CoachGroupsPage = lazy(() =>
  lazyRetry(() => import('app/pages/coach/Groups'), 'CoachGroups')
);

const CoachSessionsPage = lazy(() =>
  lazyRetry(() => import('app/pages/coach/Sessions/Sessions'), 'CoachSessions')
);
const CoachSessionPage = lazy(() =>
  lazyRetry(() => import('app/pages/coach/Sessions/Session'), 'CoachSession')
);

export const coachRoutes = [
  {
    path: routeNames.COACH,
    element: <RoleProtectedRoute role={[USER_TYPES.COACH, USER_TYPES.ADMIN]} />,
    children: [
      {
        path: 'settings',
        element: (
          <>
            <SeoTitle title="Coach Settings" />
            <Suspense fallback={<Loader />}>
              <CoachSettingsPage />
            </Suspense>
          </>
        ),
      },
      {
        index: true,
        element: <Navigate to="participants" />,
      },
      {
        path: 'participants',
        element: (
          <>
            <SeoTitle title="Coach Participants" />
            <Suspense fallback={<Loader />}>
              <ParticipantsPage />
            </Suspense>
          </>
        ),
      },
      {
        path: 'participants/:email',
        element: (
          <Suspense fallback={<Loader />}>
            <CurrentSprintProvider>
              <CoachClientDashboardPage />
            </CurrentSprintProvider>
          </Suspense>
        ),
        errorElement: <ParticipantRouterErrorBoundary />, // Participant-specific error boundary
        children: [
          {
            path: 'dashboard',
            element: (
              <>
                <SeoTitle title="Participant Dashboard" />
                <LayoutPageSidebar>
                  <Suspense fallback={<Loader />}>
                    <CoachSidebar />
                  </Suspense>
                </LayoutPageSidebar>
                <LayoutPageContent>
                  <Suspense fallback={<Loader />}>
                    <DashboardPage />
                  </Suspense>
                </LayoutPageContent>
              </>
            ),
          },
          {
            path: 'sessions',
            element: (
              <>
                <SeoTitle title="Participant Sessions" />
                <Suspense fallback={<Loader />}>
                  <CoachSessionsPage />
                </Suspense>
              </>
            ),
          },
          {
            path: 'sessions/:sessionId',
            element: (
              <>
                <SeoTitle title="Participant Session Details" />
                <Suspense fallback={<Loader />}>
                  <CoachSessionPage />
                </Suspense>
              </>
            ),
          },
          {
            path: routeNames.PLANNING,
            element: (
              <>
                <SeoTitle title="Participant Planning" />
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '100%',
                    width: '100%',
                    maxWidth: '100vw',
                  }}
                >
                  <LayoutPageSidebar>
                    <Suspense fallback={<Loader />}>
                      <CoachSidebar />
                    </Suspense>
                  </LayoutPageSidebar>
                  <LayoutPageContent>
                    <Suspense fallback={<Loader />}>
                      <CurrentSprintProvider>
                        <PlanningWithFeatureFlag />
                      </CurrentSprintProvider>
                    </Suspense>
                  </LayoutPageContent>
                </Box>
              </>
            ),
          },
          {
            path: routeNames.GOALS,
            element: (
              <>
                <SeoTitle title="Participant Goals" />
                <LayoutPageSidebar>
                  <Suspense fallback={<Loader />}>
                    <CoachSidebar />
                  </Suspense>
                </LayoutPageSidebar>
                <LayoutPageContent>
                  <Suspense fallback={<Loader />}>
                    <Goals />
                  </Suspense>
                </LayoutPageContent>
              </>
            ),
          },
          {
            path: `${routeNames.GOALS}/:goalId`,
            element: (
              <>
                <SeoTitle title="Participant Goal Details" />
                <Suspense fallback={<Loader />}>
                  <GoalPage />
                </Suspense>
              </>
            ),
          },
          {
            path: routeNames.GROUPS,
            element: (
              <>
                <SeoTitle title="Participant Groups" />
                <LayoutPageSidebar>
                  <Suspense fallback={<Loader />}>
                    <CoachSidebar />
                  </Suspense>
                </LayoutPageSidebar>
                <LayoutPageContent>
                  <Suspense fallback={<Loader />}>
                    <CoachGroupsPage />
                  </Suspense>
                </LayoutPageContent>
              </>
            ),
          },
          {
            path: ':sprintId/edit',
            element: (
              <>
                <SeoTitle title="Participant Sprint Edit" />
                <Suspense fallback={<Loader />}>
                  <CurrentSprintProvider>
                    <SprintForm />
                  </CurrentSprintProvider>
                </Suspense>
              </>
            ),
          },
          {
            path: ':sprintId',
            element: (
              <>
                <SeoTitle title="Participant Sprint Details" />
                <Suspense fallback={<Loader />}>
                  <ParticipantSprintPage />
                </Suspense>
              </>
            ),
          },
        ],
      },
    ],
  },
];
