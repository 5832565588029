import { $generateNodesFromDOM } from '@lexical/html';
import { LexicalEditor, LexicalNode } from 'lexical';

const ELEMENT_TYPE = 1;

export const convertHtmlToLexicalNodes = (
  editor: LexicalEditor,
  htmlString: string = ''
): LexicalNode[] => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(htmlString, 'text/html');
  return $generateNodesFromDOM(editor, dom);
};

export const isHtml = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return Array.from(doc.body.childNodes).some(
    (node) => node.nodeType === ELEMENT_TYPE
  );
};
