import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { SPRINT_WEEK_NUMBER } from 'features/participants/config';

import { Sprint } from './Sprint';
import { SPRINT_STATES, SPRINT_STATUS_LABELS } from './config';
import { IParticipantSprint } from './config/types';
import { getWeekLabel } from './utils';

export class ParticipantSprint extends Sprint implements IParticipantSprint {
  readonly successRate?: Nullable<number>;

  constructor(sprint: IParticipantSprint) {
    super(sprint);

    this.successRate = sprint.successRate || 0;
  }

  get duration() {
    return dateUtils.periodDate(
      dateUtils.timestampFromFormattedDate(this.startDate),
      dateUtils.timestampFromFormattedDate(this.endDate)
    );
  }

  get weekLabelForSprintDate() {
    if (dateUtils.isCurrentDateBeforeDate(this.startDate)) {
      return SPRINT_STATUS_LABELS[SPRINT_STATES.DRAFT];
    }

    if (this.state === SPRINT_STATES.FINISHED) {
      return SPRINT_STATUS_LABELS[SPRINT_STATES.FINISHED];
    }

    return getWeekLabel(this.currentWeekNumber - 1);
  }

  get weekNumberLabel() {
    if (dateUtils.isCurrentDateBeforeDate(this.startDate)) {
      return SPRINT_WEEK_NUMBER.FUTURE_SPRINT;
    }

    if (this.state === SPRINT_STATES.FINISHED) {
      return SPRINT_WEEK_NUMBER.PAST_SPRINT;
    }

    return this.currentWeekNumber;
  }
}
