import { FormProvider, useForm } from 'react-hook-form';

import { ChallengeTargetAmountDialogBody } from 'features/challenge/components/TargetAmount/Dialog/Body';
import { ChallengeTargetAmountDialogButtons } from 'features/challenge/components/TargetAmount/Dialog/Buttons';
import { ChallengeTargetAmountDialogHeader } from 'features/challenge/components/TargetAmount/Dialog/Header';
import { TARGET_PERIOD } from 'features/challenge/config/types';
import { useChallengeAnalytics } from 'features/sprint/hooks/useChallengeAnalytics';

import { ModalBody, ModalFooter, ModalHeader } from 'shared/components/Modal';

type ChallengeTargetAmountDialogContentPropTypes = {
  initialTargetAmountValue: number;
  initialTargetAmountUnit: string;
  initialTargetPeriod: TARGET_PERIOD;
  onSave: () => void;
};

export const ChallengeTargetAmountDialogContent = ({
  initialTargetAmountValue,
  initialTargetAmountUnit,
  initialTargetPeriod,
  onSave,
}: ChallengeTargetAmountDialogContentPropTypes) => {
  const challengeTargetAmountFormMethods = useForm({
    defaultValues: {
      value: initialTargetAmountValue || 1,
      unit: initialTargetAmountUnit,
      period: initialTargetPeriod || TARGET_PERIOD.PER_DAY,
    },
  });

  const { sendTargetSelectedEvent } = useChallengeAnalytics();

  const handleSaveBtnClick = () => {
    sendTargetSelectedEvent();
    challengeTargetAmountFormMethods.handleSubmit(onSave)();
  };

  return (
    <FormProvider {...challengeTargetAmountFormMethods}>
      <ModalHeader>
        <ChallengeTargetAmountDialogHeader />
      </ModalHeader>
      <ModalBody>
        <ChallengeTargetAmountDialogBody />
      </ModalBody>
      <ModalFooter>
        <ChallengeTargetAmountDialogButtons onSave={handleSaveBtnClick} />
      </ModalFooter>
    </FormProvider>
  );
};
