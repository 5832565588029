import PropTypes from 'prop-types';

import { Typography } from 'shared/components/Typography';

export const SprintInfoTitle = ({ children, dataTestid }) => {
  return (
    <Typography
      color="black"
      type="small"
      fontWeight="medium"
      cropped={true}
      dataTestid={dataTestid}
    >
      {children}
    </Typography>
  );
};

SprintInfoTitle.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestid: PropTypes.string,
};
