import { useCallback } from 'react';

import {
  sendHistorySidebarOpenedAnalyticsEvent,
  sendSprintFinishedAnalyticsEvent,
  sendSprintReflectionAnswerEditedAnalyticsEvent,
  sendSprintRestartedAnalyticsEvent,
  sendSprintStartedAnalyticsEvent,
  sendSprintWithCoachChangedAnalyticsEvent,
  sendSprintsSidebarOpenedAnalyticsEvent,
} from 'app/analytics';
import { UserMode } from 'shared_DEPRECATED/types/User';

import { useUserMode } from 'features/user/hooks';

import { SPRINT_TYPES } from '../config';
import { useSprintType } from './useSprintType';

export const useSprintAnalytics = () => {
  const sprintType = useSprintType();
  const isCoachedSprint = sprintType === SPRINT_TYPES.COACHED;
  const userMode = useUserMode() as UserMode;

  const sendCoachedSprintEditedEvent = useCallback(() => {
    if (isCoachedSprint) {
      sendSprintWithCoachChangedAnalyticsEvent({
        userMode,
      });
    }
  }, [isCoachedSprint, userMode]);

  const sendSprintStartedEvent = useCallback(() => {
    sendSprintStartedAnalyticsEvent({
      userMode,
    });
  }, [userMode]);

  const sendSprintRestartedEvent = useCallback(() => {
    sendSprintRestartedAnalyticsEvent({
      userMode,
    });
  }, [userMode]);

  const sendSprintFinishedEvent = useCallback(() => {
    sendSprintFinishedAnalyticsEvent({
      userMode,
    });
  }, [userMode]);

  const sendSprintReflectionAnswerEditedEvent = useCallback(() => {
    sendSprintReflectionAnswerEditedAnalyticsEvent({
      userMode,
    });
  }, [userMode]);

  const sendHistorySidebarOpenedEvent = useCallback(() => {
    sendHistorySidebarOpenedAnalyticsEvent({
      userMode,
    });
  }, [userMode]);

  const sendSprintListSidebarOpenedEvent = useCallback(() => {
    sendSprintsSidebarOpenedAnalyticsEvent({
      userMode,
    });
  }, [userMode]);

  return {
    sendCoachedSprintEditedEvent,
    sendSprintStartedEvent,
    sendSprintRestartedEvent,
    sendSprintFinishedEvent,
    sendSprintReflectionAnswerEditedEvent,
    sendHistorySidebarOpenedEvent,
    sendSprintListSidebarOpenedEvent,
  };
};
