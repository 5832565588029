import { TabPanels, Tabs } from '@chakra-ui/react';
import { memo } from 'react';

import {
  PlanningBacklogHeader,
  PlanningBoardColumn,
  PlanningBacklogCoachingToolsTab,
  PlanningBacklogChallengesTab,
} from 'features/planning';

import { BoardColumnHeader, BoardColumnWrapper } from 'shared/components/Board';

export const PlanningBacklog = memo(() => (
  <PlanningBoardColumn>
    <BoardColumnWrapper fraction={2}>
      <Tabs variant="unstyled" height="100%" isLazy lazyBehavior="keepMounted">
        <BoardColumnHeader minHeight={columnHeaderMinHeight}>
          <PlanningBacklogHeader />
        </BoardColumnHeader>
        <TabPanels height={`calc(100% - ${columnHeaderMinHeight})`}>
          <PlanningBacklogChallengesTab />
          {<PlanningBacklogCoachingToolsTab />}
        </TabPanels>
      </Tabs>
    </BoardColumnWrapper>
  </PlanningBoardColumn>
));

const columnHeaderMinHeight = '3rem';
