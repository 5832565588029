import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { menuAnatomy } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys
);

export const menuTheme = defineMultiStyleConfig({
  baseStyle: {
    list: {
      bg: 'bgPrimary',
      fontSize: 'small',
      minWidth: 'auto',
      maxWidth: '252px',
      pt: 0,
      overflowX: 'hidden',
    },
    item: {
      bg: 'bgPrimary',
      _hover: { bg: 'bgSecondary' },
      _focusVisible: { bg: 'bgSecondary' },
      h: '32px',
      whiteSpace: 'nowrap',
    },
    button: {
      borderRadius: 'borderRadius02',
      _active: { bg: 'bgSecondary' },
      _focusVisible: {
        outline: 'none',
      },
    },
    groupTitle: {
      fontWeight: '600',
      fontSize: '12px',
      color: 'fgPrimary',
    },
  },
});
