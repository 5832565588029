import { ReactNode } from 'react';

import { ButtonIcon, Flex } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

type TJournalFormHeader = {
  onCancel: () => void;
  children: ReactNode;
};

export const JournalFormHeader = ({
  onCancel,
  children,
}: TJournalFormHeader) => (
  <Flex justifyContent="space-between" alignItems="center" mb={2}>
    {children}
    <ButtonIcon
      ariaLabel="close modal"
      onClick={onCancel}
      icon={
        <Icon
          name={iconNames.cross}
          width={12}
          height={12}
          stroke="var(--fgPrimary)"
        />
      }
    ></ButtonIcon>
  </Flex>
);
