import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import { JournalNote, useJournalQueryActions } from 'features/journal';

import { showErrorToast } from 'shared/components/Toast';

type TUseJournalMarkNoteAsRead = {
  noteId: string;
};

export const useJournalMarkNoteAsReadMutation = ({
  noteId,
}: TUseJournalMarkNoteAsRead) => {
  const { email } = useParams();
  const { request } = useMutationHTTPRequest();

  const queryClient = useQueryClient();

  const {
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
    cancelJournalNoteQueries,
    getPreviousJournalNoteValue,
    updateJournalNoteQueryData,
    updateJournalRelevantQueries,
  } = useJournalQueryActions();

  return useMutation(
    async () =>
      await request({
        url: `/api/journal/notes/${noteId}/read`,
      }),
    {
      onMutate: () => {
        const relevantQueries = getJournalRelevantQueries();

        cancelJournalRelevantQueries();

        const previousJournalNotesData = relevantQueries.map(([key, data]) => ({
          key,
          data,
        }));

        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: page.notes.map((note: JournalNote) => {
              if (note.noteId === noteId) {
                note.isRead = true;
              }

              return note;
            }),
          }));
        });

        cancelJournalNoteQueries(noteId);

        const previousJournalNoteData = getPreviousJournalNoteValue(noteId);

        updateJournalNoteQueryData(noteId, (old) => {
          old.note.isRead = true;
        });

        return { previousJournalNotesData, previousJournalNoteData };
      },
      onError: (err, _, context) => {
        showErrorToast(
          "There's an error with marking note as unread. Please try again later."
        );

        context?.previousJournalNotesData.forEach(({ key, data }: any) => {
          queryClient.setQueryData(key, data);
        });

        updateJournalNoteQueryData(
          noteId,
          () => context?.previousJournalNoteData
        );

        logError(err);
      },

      onSuccess: (newNote: JournalNote) => {
        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map(
            (page: { notes: JournalNote[] }) => ({
              ...page,
              notes: page.notes.map((note) => {
                if (note.noteId === noteId) {
                  return newNote;
                }

                return note;
              }),
            })
          );
        });

        updateJournalNoteQueryData(noteId, () => newNote);

        queryClient.setQueryData(
          [queryKeys.participant, email],
          (old?: { unreadNotesRepliesNumber: number }) => {
            return (
              old && {
                ...old,
                unreadNotesRepliesNumber: old.unreadNotesRepliesNumber - 1,
              }
            );
          }
        );
      },
    }
  );
};
