import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { Spacers } from 'shared_DEPRECATED/components/Spacer/Spacers';

import {
  SessionsTableHeaderCell,
  SessionsTableSectionTitle,
} from 'features/sessions/components/Table';
import { SessionListItemType } from 'features/sessions/config/types';
import { useCoachSessionsTableData } from 'features/sessions/hooks';

import styles from './Table.module.css';

type CoachSessionsTablePropsType = {
  sessions: SessionListItemType[];
};

export const CoachSessionsTable = ({
  sessions,
}: CoachSessionsTablePropsType) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useCoachSessionsTableData(sessions);
  const navigate = useNavigate();
  const handleRowClick = (coachingSessionId: string) => {
    navigate(`${coachingSessionId}`);
  };

  return (
    <Spacers sizes={['zr lg', 'zr md']}>
      <Box
        style={{
          borderRadius: '0.5rem',
          overflow: 'hidden',
          border: 'var(--border-secondary)',
        }}
      >
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
          }}
          {...getTableProps()}
        >
          <thead
            style={{
              backgroundColor: 'var(--bgCompSecondaryBig)',
              borderBottom: 'var(--border-secondary)',
            }}
          >
            {headerGroups.map((headerGroup) => {
              const { key, ...rest } = headerGroup.getHeaderGroupProps();

              return (
                <tr key={key} {...rest}>
                  {headerGroup.headers.map((column) => {
                    const { key, ...rest } = column.getHeaderProps(
                      column.getSortByToggleProps()
                    );

                    return (
                      <th key={key} {...rest}>
                        <SessionsTableHeaderCell data={column} />
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index, rows) => {
              prepareRow(row);

              const { key, ...rest } = row.getRowProps();

              return (
                <React.Fragment key={row.original.coachingSessionId}>
                  <SessionsTableSectionTitle
                    currentSession={row.original}
                    prevSession={rows[index - 1]?.original}
                  />
                  <tr
                    key={key}
                    {...rest}
                    data-testid="sessions-table-row"
                    style={{
                      borderBottom: 'var(--border-secondary)',
                    }}
                    className={styles.row}
                    onClick={() =>
                      handleRowClick(row.original.coachingSessionId)
                    }
                  >
                    {row.cells.map((cell) => {
                      const { key, ...rest } = cell.getCellProps();

                      return (
                        <td key={key} {...rest}>
                          <Spacer size="lg">{cell.render('Cell')}</Spacer>
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Spacers>
  );
};
