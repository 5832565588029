import { useCallback } from 'react';

import { ANALYTICS_EVENTS } from './config';
import { useSendAnalytics } from './useSendAnalytics';

export const useJournalAnalytics = () => {
  const sendAnalyticsEvent = useSendAnalytics();

  const sendNoteAddedAnalyticsEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.NOTE_ADDED,
    });
  }, [sendAnalyticsEvent]);

  const sendNoteReplyAddedAnalyticsEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.NOTES_REPLY_ADDED,
    });
  }, [sendAnalyticsEvent]);

  const sendNoteEditedAnalyticsEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.NOTE_EDITED,
    });
  }, [sendAnalyticsEvent]);

  const sendNoteReplyEditedAnalyticsEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.NOTES_REPLY_EDITED,
    });
  }, [sendAnalyticsEvent]);

  const sendNoteReactionAddedAnalyticsEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.NOTES_REACTION_ADDED,
    });
  }, [sendAnalyticsEvent]);

  const sendNoteReplyReactionAddedAnalyticsEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.NOTES_REPLY_REACTION_ADDED,
    });
  }, [sendAnalyticsEvent]);

  return {
    sendNoteAddedAnalyticsEvent,
    sendNoteEditedAnalyticsEvent,
    sendNoteReplyAddedAnalyticsEvent,
    sendNoteReplyEditedAnalyticsEvent,
    sendNoteReplyReactionAddedAnalyticsEvent,
    sendNoteReactionAddedAnalyticsEvent,
  };
};
