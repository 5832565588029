import { COACHING_MODE_LABELS } from 'features/participants/config';
import { CoachingMode } from 'features/participants/config/types';

import { Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type ParticipantCoachingModeBadgeProps = {
  mode: CoachingMode;
};

export const ParticipantCoachingModeBadge = ({
  mode,
}: ParticipantCoachingModeBadgeProps) => (
  <Badge bgColor="lightGray" dataTestid="coach-mode-badge">
    <Typography color="black" textTransform="uppercase" type="xsm">
      {COACHING_MODE_LABELS[mode]}
    </Typography>
  </Badge>
);
