import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { MomentumFeedItemMinimumTrend } from 'features/momentum/components/Feed/Item/';

import { Typography } from 'shared/components/Typography';
import { partitionItems } from 'shared/utils/array';

type MomentumFeedItemMinimumGroupProps = {
  challenges: {
    title: string;
    frequency: string;
    targetAmount?: string;
  }[];
  trend: number;
  trendSign: '-' | '+';
};

export const MomentumFeedItemMinimumGroup = ({
  challenges,
  trend,
  trendSign,
}: MomentumFeedItemMinimumGroupProps) => {
  const { firstItems, remainingCount } = partitionItems(challenges);

  return (
    <Flex>
      <Spacers sizes={['zr zr zr lg', 'zr zr zr sm']}>
        <Typography type="small">Challenges slowing momentum</Typography>
        <Typography type="small" color="gray">
          {firstItems[0].title}
          {remainingCount > 0 ? `, +${remainingCount} more` : null}
        </Typography>
      </Spacers>
      <Flex gap="var(--spacing02)">
        <MomentumFeedItemMinimumTrend
          challenges={challenges}
          trend={trend}
          trendSign={trendSign}
        />
      </Flex>
    </Flex>
  );
};
