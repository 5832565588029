import Linkify from 'linkify-react';
import { ReactNode } from 'react';

import { Avatar, Flex, SIZES } from 'shared';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';

import { JournalCardFooter, JournalRepliesCardHeader } from 'features/journal';

import { Typography } from 'shared/components/Typography';

type TJournalReply = {
  pictureUrl: string;
  userName: string;
  date: string;
  text: string;
  setEditedReply: () => void;
  replyId: string;
  relatedNoteId: string;
  children: ReactNode;
  isAuthor: boolean;
};

export const JournalReply = ({
  pictureUrl,
  userName,
  date,
  text,
  replyId,
  relatedNoteId,
  isAuthor,
  children,
  setEditedReply,
}: TJournalReply) => (
  <Flex width="100%" p="0.5rem" gap="0.75rem">
    <Avatar name={userName} size={SIZES.SM} src={pictureUrl} />
    <Flex flexDirection="column" gap="0.25rem" width="100%">
      <JournalRepliesCardHeader
        name={userName}
        date={date}
        isAuthor={isAuthor}
        setEditedReply={setEditedReply}
        replyId={replyId}
        relatedNoteId={relatedNoteId}
      />
      <HotjarSuppression>
        <Typography type="small" color="black">
          <Linkify options={{ target: '_blank' }}>{text}</Linkify>
        </Typography>
      </HotjarSuppression>
      <JournalCardFooter>{children}</JournalCardFooter>
    </Flex>
  </Flex>
);
