import { Fragment } from 'react';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalChallengeCard,
  useMoveGoalBreakdownChallenges,
  GoalFocusAreaChallengeCardMenu,
} from 'features/goal';
import { GoalFocusArea } from 'features/goal/components/FocuseArea';
import { useGoalBreakdownDataContext } from 'features/goal/context/goalBreakdown';
import { useMoveGoalBreakdownChallengesMutation__NEW } from 'features/goal/hooks/mutation/useMoveGoalBreakdownChallenge__NEW';

import { Droppable } from 'shared/components/DragNDrop/Droppable';
import { Sortable } from 'shared/components/DragNDrop/Sortable';

export const GoalBreakdownBoardFocusAreas = () => {
  const { goalBreakdownData } = useGoalBreakdownDataContext();
  const { mutate: mutateChallenges } =
    useMoveGoalBreakdownChallengesMutation__NEW();
  const { moveToFocusArea, moveToChallenges } =
    useMoveGoalBreakdownChallenges();

  return (
    <>
      {goalBreakdownData?.focusAreas.map((focusArea, focusAreaIndex) => (
        <Fragment key={focusArea.id}>
          <Droppable
            key={`${focusArea.id}-${focusAreaIndex}`}
            acceptType={['challenge']}
            onDrop={({ id, index }) => {
              mutateChallenges({
                customChallengeId: id,
                index,
                focusAreaId: focusArea.id,
              });
            }}
            onHover={(draggedItem: {
              id: string;
              focusAreaIndex: number;
              index: number;
            }) => moveToFocusArea(focusAreaIndex)(draggedItem)}
            height="auto"
          >
            {/* eslint-disable-next-line */}
            <GoalFocusArea
              title={focusArea.title}
              focusAreaId={focusArea.id}
            >
              {focusArea.challenges.map((challenge, challengeIndex) => (
                <Fragment
                  key={`${focusArea.id}-${challenge.customChallengeId}`}
                >
                  <Spacer size="xsm" />
                  <Sortable
                    key={`${focusArea.id}-${focusAreaIndex}-${challenge.customChallengeId}`}
                    item={{
                      type: 'challenge',
                      id: challenge.customChallengeId,
                      index: challengeIndex,
                      focusAreaIndex,
                    }}
                    onHover={moveToChallenges(focusAreaIndex)}
                    onDrop={({ id, index }) => {
                      mutateChallenges({
                        customChallengeId: id,
                        index,
                        focusAreaId: focusArea.id,
                      });
                    }}
                  >
                    <GoalChallengeCard
                      title={challenge.title}
                      picture={challenge.picture}
                      emoji={challenge.emoji}
                      dimensions={challenge.dimensions}
                      goals={challenge.goals}
                    >
                      <GoalFocusAreaChallengeCardMenu
                        focusAreaId={focusArea.id}
                        challenge={challenge}
                      />
                    </GoalChallengeCard>
                  </Sortable>
                </Fragment>
              ))}
            </GoalFocusArea>
          </Droppable>
          <Spacer size="xsm" />
        </Fragment>
      ))}
    </>
  );
};
