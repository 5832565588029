import { Typography } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

export const PlanningSprintRestartedHeader = ({
  restartedAt,
}: {
  restartedAt: string;
}) => {
  return (
    <>
      <Icon name={iconNames.restart} />
      <Typography
        type="small"
        color="gray"
      >{`Restarted on ${restartedAt}`}</Typography>
    </>
  );
};
