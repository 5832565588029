import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';

import { JournalNote, useJournalQueryActions } from 'features/journal';
import { useIsCoachMode } from 'features/user/hooks/useIsCoachMode';

import { showErrorToast } from 'shared/components/Toast';

type TUseJournalMarkNotesAsReadMutation = {
  participantId: string;
};

export const useJournalMarkNotesAsReadMutation = ({
  participantId,
}: TUseJournalMarkNotesAsReadMutation) => {
  const { request } = useMutationHTTPRequest();

  const isCoachMode = useIsCoachMode();

  const coachPrefix = isCoachMode ? `/coach/${participantId}` : '';

  const queryClient = useQueryClient();

  const {
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
    updateJournalRelevantQueries,
  } = useJournalQueryActions();

  return useMutation(
    async () =>
      await request({
        url: `/api${coachPrefix}/journal/notes/read`,
      }),
    {
      onMutate: () => {
        const relevantQueries = getJournalRelevantQueries();

        cancelJournalRelevantQueries();

        const previousJournalNotesData = relevantQueries.map(([key, data]) => ({
          key,
          data,
        }));

        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: page.notes.map((note: JournalNote) => {
              note.isRead = true;

              return note;
            }),
          }));
        });

        return { previousJournalNotesData };
      },
      onError: (err, _, context) => {
        showErrorToast(
          "There's an error with marking notes as unread. Please try again later."
        );

        context?.previousJournalNotesData.forEach(({ key, data }: any) => {
          queryClient.setQueryData(key, data);
        });

        logError(err);
      },
    }
  );
};
