import { lazy, Suspense } from 'react';

import RoleProtectedRoute from 'app/RoleProtectedRoute';
import { SeoTitle } from 'app/seo/components/Title';
import Loader from 'shared_DEPRECATED/components/Loader';
import { USER_TYPES } from 'shared_DEPRECATED/types/User';
import { routeNames } from 'shared_DEPRECATED/utils/config';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

const UsersPage = lazy(() =>
  lazyRetry(() => import('app/pages/admin/Users'), 'Users')
);

export const adminRoutes = [
  {
    path: routeNames.ADMIN,
    element: <RoleProtectedRoute role={USER_TYPES.ADMIN} />, // Admin protected route
    children: [
      {
        index: true, // Default admin route
        element: (
          <>
            <SeoTitle title="hOS Users" />
            <Suspense fallback={<Loader />}>
              <UsersPage />
            </Suspense>
          </>
        ),
      },
    ],
  },
];
