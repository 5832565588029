import { ReactNode, createContext, useContext, useMemo } from 'react';

import useToggle from 'shared_DEPRECATED/hooks/useToggle';

type SidebarCollapseContextType = {
  isOpen: boolean;
  setIsOpen: () => void;
};

const defaultSidebarCollapseContext: SidebarCollapseContextType = {
  isOpen: true,
  setIsOpen: () => {},
};

const SidebarCollapseContext = createContext(defaultSidebarCollapseContext);

export const SidebarCollapseProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useToggle();

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen,
    }),
    [isOpen, setIsOpen]
  );

  return (
    <SidebarCollapseContext.Provider value={value}>
      {children}
    </SidebarCollapseContext.Provider>
  );
};

export const useSidebarCollapseContext = () => {
  const context = useContext(SidebarCollapseContext);

  if (!context) {
    throw new Error(
      'useSidebarCollapseContext must be used within a SidebarCollapseContext.Provider'
    );
  }

  return context;
};
