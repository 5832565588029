import { useMutation } from '@tanstack/react-query';

import useUploadFileMutation from 'api/hooks/useUploadFileMutation';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { AttachmentWithFile } from '../types';

export const useJournalAttachmentsUpload = () => {
  const { request } = useMutationHTTPRequest();
  const { mutateAsync: uploadFile } = useUploadFileMutation();

  const { mutateAsync: getUploadUrl } = useMutation(
    async ({ contentType }: { contentType: string }) =>
      await request({
        url: '/api/media/upload-url/create',
        headers: {
          'object-content-type': contentType,
        },
      })
  );

  const uploadAttachments = async (attachments: AttachmentWithFile[]) =>
    await Promise.all(
      attachments.map(async (attachment) => {
        const { mediaId, uploadUrl } = await getUploadUrl({
          contentType: attachment.file.type,
        });

        await uploadFile({
          file: attachment.file,
          presignedUrl: uploadUrl,
        });

        return mediaId;
      })
    );

  return { uploadAttachments };
};
