import { useCallback, useRef } from 'react';
import ReactPlayer from 'react-player';

import { Box, MediaGallery } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Video } from 'shared_DEPRECATED/components/Video/Video';
import { MediaType } from 'types/common';

import { JournalCardVideoModal } from 'features/journal';

import { SIZES, modal } from 'shared/components/Modal';

type TJournalCardVideoAttachment = {
  media: MediaType[];
  previewSrc: string;
  src: string;
};

export const JournalCardVideoAttachment = ({
  media,
  src,
  previewSrc,
}: TJournalCardVideoAttachment) => {
  const videoRef = useRef<ReactPlayer>(null);

  const handleVideoClick = useCallback(
    () =>
      modal.open({
        content:
          media.length > 1 ? (
            <MediaGallery media={media} />
          ) : (
            <JournalCardVideoModal src={media[0].url} />
          ),
        props: {
          title: '',
          size: SIZES.SM,
        },
      }),
    [media]
  );

  if (!media.length) {
    return null;
  }

  return (
    <Box
      width="3rem"
      height="3rem"
      overflow="hidden"
      borderRadius="0.5rem"
      boxShadow="var(--button-box-shadow)"
    >
      <Video
        ref={videoRef}
        light={previewSrc}
        onClickPreview={() => {
          videoRef.current?.showPreview();
          handleVideoClick();
        }}
        playIcon={<Icon name={iconNames.play} fill="white" />}
        height="100%"
        width="100%"
        objectFit="fill"
        src={src}
        data-testId="challenge-notes-card-video"
      />
    </Box>
  );
};
