import { useFormContext, useWatch } from 'react-hook-form';

export const useAddNotePostToFeedSectionConfig = () => {
  const { setValue } = useFormContext();

  const postToFeed = useWatch({ name: 'postToFeed' });

  const handleProofRequiredChange = () => {
    setValue('postToFeed', !postToFeed);
  };

  return {
    onChange: handleProofRequiredChange,
    checked: postToFeed,
  };
};
