export const findIndexByProp = (
  arr: any[],
  prop: string,
  value: any
): number => {
  return arr.findIndex((item) => item[prop] === value);
};

export const deleteItemMutative = (arr: any[], index: number) => {
  const [item] = arr.splice(index, 1);

  return item;
};

export const insertItemMutative = (
  arr: any[],
  index: number,
  item: any
): void => {
  arr.splice(index, 0, item);
};

export const partitionItems = <T>(
  items: T[],
  visibleItemCount: number = 1
): { firstItems: T[]; secondItems: T[]; remainingCount: number } => {
  const firstItems = items.slice(0, visibleItemCount);
  const secondItems = items.slice(visibleItemCount);
  const remainingCount = Math.max(0, items.length - visibleItemCount);

  return { firstItems, secondItems, remainingCount };
};
