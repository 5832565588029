import { FormControl, FormLabel, Switch } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { useRepeatFieldConfig } from 'features/challenge/hooks/useRepeatFieldConfig';

import { Typography } from 'shared/components/Typography';

export const RepeatField = () => {
  const { disabled, checked, validationMessage, onChange } =
    useRepeatFieldConfig();

  return (
    <FormControl>
      <Flex>
        <FormLabel>Repeat</FormLabel>
        <Switch
          isDisabled={disabled}
          isChecked={checked}
          onChange={onChange}
          size="sm"
          data-testid={`challenge-dialog-repeat-field-button${
            checked ? '-checked' : ''
          }`}
        />
      </Flex>
      {disabled && (
        <Typography
          type="small"
          textAlign="right"
          color="lightGray"
          dataTestid="challenge-dialog-repeat-field-validation"
        >
          {validationMessage}
        </Typography>
      )}
    </FormControl>
  );
};
