import { Typography, Flex, Box, useSidebarContext } from 'shared';
import Button from 'shared_DEPRECATED/components/Button';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  JournalCardUnreadIndicator,
  openJournalRepliesSidebar,
  useJournalFilterContext,
  useJournalMarkNoteAsReadMutation,
} from 'features/journal';
import { useUserContext } from 'features/user/context';

type TJournalCardReplies = {
  repliesCount: number;
  hasUnreadReplies: boolean;
  noteId: string;
  isAuthor: boolean;
  isNoteRead: boolean;
};

export const JournalCardReplies = ({
  repliesCount,
  hasUnreadReplies,
  noteId,
  isAuthor,
  isNoteRead,
}: TJournalCardReplies) => {
  const { queryParams } = useJournalFilterContext();
  const { user } = useUserContext();
  const { mutateAsync: markNoteAsRead } = useJournalMarkNoteAsReadMutation({
    noteId,
  });
  const { participantId, participantEmail } = useSidebarContext();

  const handleNoteClick = () => {
    !isAuthor && !isNoteRead && markNoteAsRead();

    openJournalRepliesSidebar({
      noteId,
      user,
      participantEmail,
      participantId,
      filters: queryParams,
    });
  };

  return (
    <Button onClick={handleNoteClick}>
      <Box>
        <Flex alignItems="flex-start" justifyContent="flex-start" gap="4px">
          <Box position="relative">
            {hasUnreadReplies && (
              <JournalCardUnreadIndicator
                options={{
                  right: '-0.125rem',
                  top: '-0.125rem',
                }}
              />
            )}
            <Icon
              name={iconNames.message}
              stroke="var(--fgSecondary)"
              hoverStroke="var(--fgPrimary)"
            />
          </Box>
          <Typography
            color="gray"
            //@ts-ignore
            hoverColor="black"
            type="small"
            dataTestid="user-challenge-note-replies-label"
          >
            {`${repliesCount} ${repliesCount === 1 ? 'reply' : 'replies'}`}
          </Typography>
        </Flex>
      </Box>
    </Button>
  );
};
