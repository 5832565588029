import { useFormContext, useWatch } from 'react-hook-form';

import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import {
  DatePicker,
  DatePickerWithYearMonthSelectorHeader,
} from 'shared_DEPRECATED/components/Form/DatePicker';
import { SIZES } from 'shared_DEPRECATED/components/Form/DatePicker/config';
import OptionalText from 'shared_DEPRECATED/components/Form/OptionalText';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  GOAL_DEADLINE_DATE_PLACEHOLDER,
  GOAL_DEADLINE_DATE_FORMAT,
  GoalAddFormSection,
} from 'features/goal';

import { Button, VARIANTS } from 'shared/components/Button';

export const GoalAddFormDeadlineSection = () => {
  const { setValue } = useFormContext();
  const deadline = useWatch({ name: 'deadline' });

  const currentDeadline = deadline ? dateUtils(deadline).toDate() : null;

  return (
    <GoalAddFormSection>
      <Flex justifyContent="space-between" alignItems="center">
        <FlexItem>
          <Flex justifyContent="flex-start" alignItems="center">
            {/*@ts-ignore*/}
            <DatePicker
              //@ts-ignore
              selected={currentDeadline}
              onChange={(currentDate: Date) =>
                setValue('deadline', dateUtils(currentDate).format())
              }
              //@ts-ignore
              size={SIZES.SM}
              format={GOAL_DEADLINE_DATE_FORMAT}
              placeholderText={GOAL_DEADLINE_DATE_PLACEHOLDER}
              //@ts-ignore
              customHeader={DatePickerWithYearMonthSelectorHeader}
              //@ts-ignore
              minDate={dateUtils().toDate()}
            />
            <Spacer size="sm" />
            {deadline && (
              <Button
                onClick={() => setValue('deadline', null)}
                variant={VARIANTS.TERTIARY}
                size="sm"
              >
                Clear date
              </Button>
            )}
          </Flex>
        </FlexItem>
        <FlexItem>
          <OptionalText />
        </FlexItem>
      </Flex>
    </GoalAddFormSection>
  );
};
