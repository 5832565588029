import {
  GoalBreadcrumb,
  SessionBreadcrumb,
} from 'app/layout/components/Header/Breadcrumbs';
import { routeNames } from 'shared_DEPRECATED/utils/config';

export const routeBreadcrumbs = [
  { path: `/${routeNames.DASHBOARD}`, breadcrumb: 'Home' },
  {
    path: `/groups`,
    breadcrumb: 'Groups',
  },
  {
    path: `/${routeNames.DASHBOARD}/:sprintId`,
    breadcrumb: null,
  },
  {
    path: `/${routeNames.DASHBOARD}/:sprintId/edit`,
    breadcrumb: 'Edit',
  },
  { path: `/${routeNames.CONNECTIONS}`, breadcrumb: 'Connections' },
  {
    path: `/${routeNames.CONNECTIONS}/:email`,
    breadcrumb: ({ match }) => <span>{match.params.email}</span>,
  },
  {
    path: `/${routeNames.CONNECTIONS}/:email/:sprintId`,
    breadcrumb: null,
  },
  { path: `/${routeNames.ADMIN}`, breadcrumb: 'Users' },
  { path: `/${routeNames.COACH}/settings`, breadcrumb: 'Settings' },

  {
    path: `/${routeNames.COACH}/participants/:email/search`,
    breadcrumb: 'Search',
  },
  {
    path: `/${routeNames.COACH}/participants/:email/groups`,
    breadcrumb: 'Groups',
  },
  {
    path: `/${routeNames.COACH}/participants/:email/dashboard`,
    breadcrumb: 'Dashboard',
  },
  {
    path: `/${routeNames.COACH}/participants/:email/sessions`,
    breadcrumb: 'Sessions',
  },
  {
    path: `/${routeNames.COACH}/participants/:email/${routeNames.PLANNING}`,
    breadcrumb: 'Sprint planning',
  },
  {
    path: `/${routeNames.COACH}/participants/:email/${routeNames.GOALS}`,
    breadcrumb: 'Goals',
  },
  {
    path: `/${routeNames.COACH}/participants/:email/${routeNames.GOALS}/:goalId`,
    breadcrumb: GoalBreadcrumb,
  },
  {
    path: `/${routeNames.GOALS}/:goalId`,
    breadcrumb: GoalBreadcrumb,
  },
  {
    path: `/${routeNames.COACH}/participants/:email/sessions/:sessionId`,
    breadcrumb: SessionBreadcrumb,
  },
  {
    path: `/${routeNames.COACH}/participants/:email/:sprintId`,
    breadcrumb: null,
  },
];
