import { ButtonIcon } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { FloatingPopover } from 'shared_DEPRECATED/components/Floating';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import useToggle from 'shared_DEPRECATED/hooks/useToggle';

import {
  GoalFocusAreaMenuAddChallengeButton,
  GoalFocusAreaMenuAddButton,
} from 'features/goal';

import { VARIANTS } from 'shared/components/Button';

export const GoalFocusAreaHeaderMenu = () => {
  const [open, toggleOpen] = useToggle();

  return (
    <FloatingPopover
      offsetTop={8}
      anchor={
        <ButtonIcon
          ariaLabel="add challenge"
          icon={
            <Icon
              name={iconNames.plus}
              stroke="var(--fgPrimaryInverse)"
              strokeWidth={2}
            />
          }
          onClick={toggleOpen}
          size="sm"
          variant={VARIANTS.PRIMARY}
        />
      }
      isOpen={open}
      onClose={toggleOpen}
    >
      <Flex flexDirection="column" justifyContent="start">
        <GoalFocusAreaMenuAddButton />
        <GoalFocusAreaMenuAddChallengeButton />
      </Flex>
    </FloatingPopover>
  );
};
