import { Checkbox, Flex, Typography } from 'shared';

import { GoalTransformed } from 'features/goal/config/types';

import { GoalImage } from '../Image';

type GoalPrioritizedModalListItemProps = {
  goalId: GoalTransformed['goalId'];
  title: GoalTransformed['title'];
  imageUrl: GoalTransformed['imageUrl'];
  dimensions: GoalTransformed['dimensions'];
  isDisabled: boolean;
  isChecked: boolean;
  onGoalToggle: (goalId: string) => void;
};

export const GoalPrioritizeModalListItem = ({
  goalId,
  title,
  imageUrl,
  dimensions,
  isDisabled,
  isChecked,
  onGoalToggle,
}: GoalPrioritizedModalListItemProps) => {
  const handleChange = () => {
    onGoalToggle(goalId);
  };

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      w="100%"
      data-testid="prioritized-goal-card-modal"
    >
      <Flex gap="var(--spacing04)" opacity={isDisabled ? 0.5 : 1}>
        <GoalImage imageUrl={imageUrl} />
        <Flex
          direction="column"
          gap="var(--spacing00)"
          justifyContent="space-evenly"
        >
          <Typography
            type="meta"
            fontWeight="medium"
            data-testid="prioritized-goal-title-modal"
          >
            {title}
          </Typography>
          <Typography
            type="small"
            color="gray"
            data-testid="prioritized-goal-dimension-modal"
            css={{ textTransform: 'capitalize' }}
          >
            {dimensions.join(' • ')}
          </Typography>
        </Flex>
      </Flex>
      <Checkbox
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={handleChange}
      />
    </Flex>
  );
};
