import {
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  VStack,
} from '@chakra-ui/react';

import { Flex } from 'shared';

import {
  JournalFilterAnchor,
  JournalFilterBySprint,
  JournalFilterHeader,
  JournalSprintFilterProvider,
  JournalChallengeFilterProvider,
  JournalFilterByChallenge,
  JournalFilterReplies,
  JournalFilterUnread,
  useJournalContext,
  useJournalFilterContext,
} from 'features/journal';
import { useIsCoachMode } from 'features/user/hooks/useIsCoachMode';

export const JournalFilter = () => {
  const isUserCoach = useIsCoachMode();
  const { noNotes } = useJournalContext();

  const { isFilterApplied } = useJournalFilterContext();

  const shouldRenderFilter = !noNotes || isFilterApplied;

  return shouldRenderFilter ? (
    <Popover placement="bottom-start">
      <JournalFilterAnchor />
      <PopoverContent
        minWidth="22rem"
        maxWidth="22rem"
        overflow="scroll"
        maxH="636px"
      >
        <PopoverHeader p={0}>
          <JournalFilterHeader />
        </PopoverHeader>
        <PopoverBody>
          <VStack alignItems="flex-start" gap="8px">
            <Flex gap="8px">
              {isUserCoach && (
                <>
                  <JournalFilterReplies />
                  <JournalFilterUnread />
                </>
              )}
            </Flex>
            <JournalSprintFilterProvider>
              <JournalFilterBySprint />
            </JournalSprintFilterProvider>
            <JournalChallengeFilterProvider>
              <JournalFilterByChallenge />
            </JournalChallengeFilterProvider>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : null;
};
