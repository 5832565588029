import {
  ButtonIcon,
  ButtonIconPropTypes,
  VARIANTS,
} from 'shared/components/Button';

type ToolbarButtonIconProps = ButtonIconPropTypes;

export const ToolbarButtonIcon = ({
  disabled,
  ...props
}: ToolbarButtonIconProps) => {
  return (
    <ButtonIcon variant={VARIANTS.TERTIARY} disabled={disabled} {...props} />
  );
};
