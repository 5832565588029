import Box from 'shared_DEPRECATED/components/Box';
import Button, { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { FloatingPopover } from 'shared_DEPRECATED/components/Floating';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';
import useToggle from 'shared_DEPRECATED/hooks/useToggle';

import { Typography } from 'shared/components/Typography';

export const ChallengeFormReminderSelector = ({
  handleSelect,
  selectedValue,
  selectOptions,
}: {
  handleSelect: (value: string) => void;
  selectedValue?: string;
  selectOptions: string[];
}) => {
  const [open, toggleOpen] = useToggle();

  return (
    <FloatingPopover
      anchor={
        <Button onClick={toggleOpen}>
          <Box
            style={{
              backgroundColor: 'transparent',
              borderRadius: 'var(--border-radius)',
              width: '2.5rem',
              height: '2.2rem',
              border: 'var(--border-secondary',
            }}
          >
            <Flex
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Spacers sizes={['sm']}>
                <Typography color="black">{selectedValue}</Typography>
              </Spacers>
            </Flex>
          </Box>
        </Button>
      }
      isOpen={open}
      onClose={toggleOpen}
      offsetTop={1}
      isAutoUpdate={true}
    >
      <Box
        style={{
          width: '2.5rem',
          maxHeight: '10rem',
          overflowY: 'auto',
          scrollbarWidth: 'none',
        }}
      >
        <Flex flexDirection="column" justifyContent="center">
          {selectOptions?.map((value) => (
            <Button
              variant={VARIANTS.TERTIARY}
              onClick={() => {
                handleSelect(value);
                toggleOpen();
              }}
              key={value}
            >
              <Box style={{ width: '2.5rem', height: '2.5rem' }}>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100%"
                >
                  <Typography fontWeight="regular" color="black">
                    {value}
                  </Typography>
                </Flex>
              </Box>
            </Button>
          ))}
        </Flex>
      </Box>
    </FloatingPopover>
  );
};
