import { useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useState } from 'react';

import classNames from 'classnames/bind';
import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import EmojiPicker from 'shared_DEPRECATED/components/Form/EmojiPicker';
import ImageUpload from 'shared_DEPRECATED/components/Form/ImageUpload';
import Image from 'shared_DEPRECATED/components/Image';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import useToggle from 'shared_DEPRECATED/hooks/useToggle';

import { Typography } from 'shared/components/Typography';

import styles from 'features/challenge/components/Form/ChallengeForm.module.css';

const cx = classNames.bind(styles);

const ImageFieldPropTypes = {
  emoji: PropTypes.string,
  onEmojiSelect: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  pictureUrl: PropTypes.string,
  pictureFileUrl: PropTypes.string,
};

const imageFieldComponents = {
  emoji: ({ onEmojiSelect, theme }) => (
    <EmojiPicker
      onEmojiSelect={onEmojiSelect}
      options={{
        perLine: 7,
        navPosition: 'none',
        skinTonePosition: 'none',
        theme,
      }}
    />
  ),
  picture: ({ onImageUpload }) => <ImageUpload onImageUpload={onImageUpload} />,
};

const ImageField = ({
  emoji = '',
  onEmojiSelect,
  onImageUpload,
  pictureUrl,
  pictureFileUrl = '',
}) => {
  const [imageType, setImageType] = useState('emoji');
  const [isOpen, setIsOpen] = useToggle();
  const { colorMode: theme } = useColorMode();
  const ImageFieldComponent = imageFieldComponents[imageType];

  const getBtnClassName = (active) =>
    cx({
      'btn--tab': true,
      'btn--tab-active': active,
    });

  return (
    <section className={styles.upload}>
      <Spacer size="zr zr lg">
        <Spacer size="zr zr md">
          <Box
            style={{
              position: 'relative',
            }}
          >
            <button
              data-testid="image-field-button"
              className={styles['upload__btn']}
              type="button"
              onClick={setIsOpen}
            >
              <Flex justifyContent="center">
                {pictureFileUrl || pictureUrl ? (
                  <Image
                    src={pictureFileUrl || pictureUrl}
                    width={56}
                    height={56}
                  />
                ) : (
                  emoji
                )}
              </Flex>
            </button>
            {isOpen && (
              <Box
                as="section"
                title="Challenge image"
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  boxSizing: 'border-box',
                  border: 'var(--border-secondary)',
                  borderRadius: 'var(--border-radius)',
                  height: 'var(--tab-height)',
                  width: '17.5rem',
                  boxShadow: 'var(--popper-box-shadow)',
                  backgroundColor: 'var(--bgSecondary)',
                }}
              >
                <Box
                  style={{
                    boxSizing: 'border-box',
                    borderBottom: 'var(--border-secondary)',
                    height: 'var(--tab-header-height)',
                  }}
                >
                  <Spacer size="zr md">
                    <Spacer size="zr sm">
                      <Flex justifyContent="flex-start">
                        <Spacer size="zr md zr zr">
                          <Spacer size="zr xsm zr zr">
                            <Button
                              className={getBtnClassName(imageType === 'emoji')}
                              onClick={() => setImageType('emoji')}
                            >
                              <Spacer size="md zr">
                                <Spacer size="sm zr">
                                  <Typography
                                    color="black"
                                    fontWeight="medium"
                                    type="meta"
                                  >
                                    Emoji
                                  </Typography>
                                </Spacer>
                              </Spacer>
                            </Button>
                          </Spacer>
                        </Spacer>
                        <Spacer size="zr md zr zr">
                          <Spacer size="zr xsm zr zr">
                            <Button
                              className={getBtnClassName(
                                imageType === 'picture'
                              )}
                              onClick={() => setImageType('picture')}
                            >
                              <Spacer size="md zr">
                                <Spacer size="sm zr">
                                  <Typography
                                    color="black"
                                    fontWeight="medium"
                                    type="meta"
                                  >
                                    Upload image
                                  </Typography>
                                </Spacer>
                              </Spacer>
                            </Button>
                          </Spacer>
                        </Spacer>
                      </Flex>
                    </Spacer>
                  </Spacer>
                </Box>
                <Box
                  style={{
                    height: 'var(--tab-content-height)',
                  }}
                >
                  <Flex
                    flexDirection="column"
                    alignItems="stretch"
                    height="100%"
                  >
                    <ImageFieldComponent
                      onEmojiSelect={(emoji) => {
                        setIsOpen();
                        onEmojiSelect(emoji);
                      }}
                      theme={theme}
                      onImageUpload={(pictureFile) => {
                        setIsOpen();
                        onImageUpload(pictureFile);
                      }}
                    />
                  </Flex>
                </Box>
              </Box>
            )}
          </Box>
        </Spacer>
      </Spacer>
    </section>
  );
};

ImageField.propTypes = ImageFieldPropTypes;

export default ImageField;
