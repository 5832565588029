import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  SortableList,
  SortableListItem,
} from 'shared_DEPRECATED/components/SortableList';

import { SprintFormChallengeItem } from 'features/sprint/components/Form/SprintFormChallengeItem';
import { SprintChallengesSection } from 'features/sprint/components/SprintChallengesSection';
import { useChallengeAnalytics } from 'features/sprint/hooks/useChallengeAnalytics';

export const SprintFormChallengeList = ({ challengesField }) => {
  const formMethods = useFormContext();
  const { fields, remove, move, update } = formMethods[challengesField];

  const { sendChallengeRemovedEvent } = useChallengeAnalytics();

  const sortableList = useMemo(
    () =>
      fields.map((challenge) => ({
        id: challenge.sprintChallengeId || challenge.curatedChallengeId,
      })),
    [fields]
  );

  const handleRemoveChallenge = (index) => {
    remove(index);
    sendChallengeRemovedEvent();
  };

  return (
    fields.length > 0 && (
      <SprintChallengesSection challengeField={challengesField}>
        <SortableList
          list={sortableList}
          onMove={({ oldIndex, newIndex }) => {
            move(oldIndex, newIndex);
          }}
        >
          {fields.map((challenge, challengeIndex) => (
            <SortableListItem
              key={challenge.id}
              dataTestid="sprint-edit-view-challenge-sortable-item"
            >
              <SprintFormChallengeItem
                challenge={challenge}
                onChallengeUpdate={(updatedChallenge) =>
                  update(challengeIndex, updatedChallenge)
                }
                onChallengeRemove={() => handleRemoveChallenge(challengeIndex)}
              />
            </SortableListItem>
          ))}
        </SortableList>
      </SprintChallengesSection>
    )
  );
};

SprintFormChallengeList.propTypes = {
  challengesField: PropTypes.string.isRequired,
};
