const KEY_PREFIX = 'hos';

export const localStorage = {
  setItem: <T>(key: string, value: T) => {
    try {
      window.localStorage.setItem(composeKey(key), JSON.stringify(value));
    } catch (error) {
      return null;
    }
  },

  getItem: <T>(key: string): T | null => {
    try {
      const value = window.localStorage.getItem(composeKey(key));
      return value !== null ? JSON.parse(value) : null;
    } catch (error) {
      return null;
    }
  },

  removeItem: (key: string) => {
    try {
      window.localStorage.removeItem(composeKey(key));
    } catch (error) {}
  },
};

const composeKey = (key: string) => [KEY_PREFIX, key].join('-');
