import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';

import { MomentumChart, MomentumTrend } from 'features/momentum/components';
import { MomentumScore } from 'features/momentum/components/Score';
import { MomentumType } from 'features/momentum/config';

type CurrentStateWithChartPropsType = {
  data: MomentumType;
};

export const MomentumCurrentStateWithChart = ({
  data,
}: CurrentStateWithChartPropsType) => {
  const lastMomentumScore = data.at(-1)?.score;
  const secondLastMomentumScore = data.at(-2)?.score;

  return (
    <Flex width="100%" justifyContent="space-between" gap="0.75rem">
      <FlexItem flexGrow={1}>
        <Flex justifyContent="flex-start" gap="var(--spacing01)">
          <MomentumScore score={lastMomentumScore!} size="md" />
          <MomentumTrend
            previousScore={secondLastMomentumScore}
            currentScore={lastMomentumScore!}
          />
        </Flex>
      </FlexItem>
      <MomentumChart data={data} />
    </Flex>
  );
};
