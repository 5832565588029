import { TextEditor, TextEditorProps } from './TextEditor';
import { MarkdownOnChangePlugin } from './plugins/MarkdownOnChangePlugin';
import { prepareMarkdownEditorState } from './utils';

export const MarkdownEditor = (
  props: Omit<TextEditorProps, 'editorStatePreparer'>
) => {
  return (
    <TextEditor
      format={props.format}
      editorStatePreparer={prepareMarkdownEditorState}
      onChangePlugin={
        <MarkdownOnChangePlugin
          onChange={props.onChange}
          format={props.format!}
        />
      }
      {...props}
    />
  );
};
