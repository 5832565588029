import { Box } from '@chakra-ui/react';

import { BoardColumn, ButtonGroup, Flex } from 'shared';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import { PlanningBacklogHeaderMenu } from 'features/planning/components';
import { PlanningBoardColumnItemsCount } from 'features/planning/components/Board/Column/ItemsCount';
import { DRAGGABLE_ITEM_TYPES } from 'features/planning/config';
import { usePlanningBacklogFilterContext } from 'features/planning/context';
import { useMovePlanningChallengeMutation__NEW } from 'features/planning/hooks/mutation/useMovePlanningChallenge__NEW';
import { PlanningDraggableItem } from 'features/planning/types';
import {
  getNextLeftChallengeId,
  isDndItemCopied,
} from 'features/planning/utils';

import { Droppable } from 'shared/components/DragNDrop/Droppable';
import { zIndexes } from 'shared/config/zIndexes';

import { PlanningBoardGoalsFilter as PlanningFilter } from '../GoalsFilter';
import { PlanningBoardItems } from '../Items';

export const PlanningBacklogChallenges = () => {
  const { filteredChallenges: challenges } = usePlanningBacklogFilterContext();
  const { mutate } = useMovePlanningChallengeMutation__NEW();

  return (
    <Droppable
      acceptType={[DRAGGABLE_ITEM_TYPES.CHALLENGE]}
      onDrop={(item: PlanningDraggableItem) => {
        const { id, parentId, location } = item;

        mutate({
          id,
          fromBacklog: parentId === CHALLENGE_LOCATIONS.BACKLOG,
          fromSprintId:
            parentId === CHALLENGE_LOCATIONS.BACKLOG ? undefined : parentId,
          toBacklog: true,
          underChallengeId: getNextLeftChallengeId({
            challenges,
            id,
            newIndex: challenges.length,
            isNew: true,
          }),
          prevLocation: location,
          newLocation: CHALLENGE_LOCATIONS.BACKLOG,
        });

        if (!isDndItemCopied(location)) {
          item.parentId = CHALLENGE_LOCATIONS.BACKLOG;
          item.location = CHALLENGE_LOCATIONS.BACKLOG;
        }
      }}
      onHover={(draggedItem: PlanningDraggableItem) => {
        draggedItem.index = challenges.length;
      }}
    >
      <Box
        style={{
          position: 'sticky',
          top: '0',
          zIndex: zIndexes.planningColumnHeader,
          backgroundColor: 'var(--bgPrimary)',
        }}
      >
        <Flex
          width="100%"
          height="2.25rem"
          alignItems="center"
          justifyContent="space-between"
          bg="var(--bgCompSecondaryBig)"
          p="8px"
        >
          <PlanningBoardColumnItemsCount
            name="challenge"
            count={challenges.length}
          />
          <ButtonGroup spacing="0.5rem" size="sm" ml="auto">
            <PlanningFilter />
            <PlanningBacklogHeaderMenu />
          </ButtonGroup>
        </Flex>
      </Box>
      <BoardColumn>
        {/* eslint-disable-next-line */}
        <PlanningBoardItems
          challenges={challenges}
          location={CHALLENGE_LOCATIONS.BACKLOG}
        />
      </BoardColumn>
    </Droppable>
  );
};
