import { ReactNode } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader/Loader';

import {
  JournalNotesEmptyState,
  useJournalContext,
  useJournalFilterContext,
} from 'features/journal';

type TJournalNotesContainer = {
  children: ReactNode;
};

export const JournalNotesContainer = ({ children }: TJournalNotesContainer) => {
  const { isLoading, notes } = useJournalContext();
  const { isFilterApplied } = useJournalFilterContext();

  if (isLoading || !notes) {
    return <Loader />;
  }

  if (notes.length === 0) {
    const text = isFilterApplied ? 'No matches found' : 'No notes yet';

    return <JournalNotesEmptyState text={text} />;
  }

  return <>{children}</>;
};
