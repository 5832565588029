import { Typography } from 'shared';

type TJournalFormHeaderTitle = {
  children: string;
};

export const JournalFormHeaderTitle = ({
  children,
}: TJournalFormHeaderTitle) => (
  <Typography type="meta" color="blue">
    {children}
  </Typography>
);
