import { Avatar, MenuButton, SIZES, Typography } from 'shared';
import {
  FloatingTooltip as Tooltip,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';

type MenuTriggerButtonProps = {
  name: string;
  pictureUrl: string;
  dataTestId?: string;
};

export const MenuTriggerButton = ({
  name,
  pictureUrl,
  dataTestId,
}: MenuTriggerButtonProps) => {
  return (
    <Tooltip
      anchor={
        <MenuButton
          data-testid={dataTestId}
          _active={{
            bg: 'transparent',
          }}
        >
          <Avatar
            name={name}
            size={SIZES.SM}
            src={pictureUrl}
            dataTestid={`${name} avatar`}
          />
        </MenuButton>
      }
      offsetValue={8}
      placement={PLACEMENTS.BOTTOM_END}
    >
      <Typography color="white" fontWeight="medium" type="small">
        Menu
      </Typography>
    </Tooltip>
  );
};
