import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getJournalQueryKey } from 'api/utils';
import { useSidebarContext } from 'shared';
import { getQueryString } from 'shared_DEPRECATED/utils';

import { JournalQueryParamsType, JournalNote } from 'features/journal';
import { useIsCoachMode } from 'features/user/hooks/useIsCoachMode';

export const useJournalNotesQuery = (
  filter: Partial<JournalQueryParamsType> = {}
) => {
  const { request } = useQueryHTTPRequest();
  const { participantId } = useSidebarContext();
  const queryParams = useMemo(() => getQueryString(filter), [filter]);

  const isCoachMode = useIsCoachMode();

  const queryKey = getJournalQueryKey({
    queryParams,
    participantId,
    isCoachMode,
  });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery<JournalNotesResponse>(
    queryKey(),
    async ({ pageParam = 0 }) => {
      const coachPrefix = isCoachMode ? `/coach/${participantId}` : '';

      return await request({
        url: `/api${coachPrefix}/journal/notes?limit=20&offset=${pageParam}&${queryParams}`,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalFetched = allPages.flatMap((page) => page.notes).length;

        return lastPage.notes.length === 20 ? totalFetched : undefined;
      },
      select: (data) => ({
        pages: data?.pages,
        pageParams: data.pageParams,
      }),
      staleTime: 1000 * 20,
    }
  );

  const notes = useMemo(
    () => data?.pages.flatMap((page) => page.notes) || [],
    [data]
  );

  return {
    notes,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  };
};

type JournalNotesResponse = {
  notes: JournalNote[];
};
