import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getParticipantsSprintsQueryKey } from 'api/utils';
import { logError } from 'lib/sentry/logError';

import { QUERY_KEYS, SPRINT_TYPES, URLS } from 'features/sprint/config';
import { useSprintType } from 'features/sprint/hooks/useSprintType';

import { useSprintAnalytics } from '../useSprintAnalytics';

export const useEditSprintMutation = ({ sprintId }) => {
  const sprintType = useSprintType();
  const { email } = useParams();
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const { sendCoachedSprintEditedEvent } = useSprintAnalytics();

  const participantSprintQueryKey =
    sprintType === SPRINT_TYPES.COACHED
      ? getParticipantsSprintsQueryKey(email)
      : queryKeys.mySprints;

  const sprintByIdQueryKey = QUERY_KEYS.SPRINT(sprintId);

  return useMutation(
    async (sprintData) =>
      request({
        url: URLS.EDIT_SPRINT(sprintId),
        body: sprintData,
      }),
    {
      onMutate: async (sprintData) => {
        const prevSprint = queryClient.getQueryData(sprintByIdQueryKey);
        queryClient.cancelQueries(participantSprintQueryKey);
        queryClient.cancelQueries(sprintByIdQueryKey);
        queryClient.setQueriesData(sprintByIdQueryKey, sprintData);
        return { prevSprint };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(participantSprintQueryKey);
        queryClient.invalidateQueries(sprintByIdQueryKey);
        sendCoachedSprintEditedEvent();
      },
      onError: (error, _, context) => {
        queryClient.setQueriesData(sprintByIdQueryKey, context.prevSprint);
        logError(error);
      },
    }
  );
};
