import { ReactNode, createContext, useContext, useState, useMemo } from 'react';

type PinnedColumnsContextType = {
  pinnedColumns: Set<string>;
  setPinnedColumns: (value: string) => void;
};

const PinnedColumnsContext = createContext<
  PinnedColumnsContextType | undefined
>(undefined);

export const PinnedColumnsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [pinnedColumns, setPinnedColumns] = useState<Set<string>>(new Set());

  const value = useMemo(
    () => ({
      pinnedColumns,
      setPinnedColumns: (value: string) => {
        const newPinnedColumns = new Set<string>();

        if (!pinnedColumns.has(value)) {
          newPinnedColumns.add(value);
        }

        setPinnedColumns(newPinnedColumns);
      },
    }),
    [pinnedColumns, setPinnedColumns]
  );

  return (
    <PinnedColumnsContext.Provider value={value}>
      {children}
    </PinnedColumnsContext.Provider>
  );
};

export const usePinnedColumnsContext = () => {
  const context = useContext(PinnedColumnsContext);

  if (!context) {
    throw new Error(
      'usePinnedColumnsContext must be used within a PinnedColumnsContext.Provider'
    );
  }

  return context;
};
